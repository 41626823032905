let ColorUtils = {
    limit: (value, min, max) => {
        value = +value;
        return isNaN(value) ? min : (value < min ? min : (value > max ? max : value));
    },

    hsl_to_rgb: (h, s, l) => {
      s /= 100;
      l /= 100;
      const k = n => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = n =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
      return [Math.round(255 * f(0)), Math.round(255 * f(8)), Math.round(255 * f(4))];
    },

    hue_to_rgb: (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;

        return p;
    },

    hex_to_rgb: (hex) => {
        if (hex) {
            hex = hex.trim().toLowerCase();
            const [, , , r, g, b, , rr, gg, bb] = /^\s*#?((([0-9A-F])([0-9A-F])([0-9A-F]))|(([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})))\s*$/i.exec(hex) || [];
            if (r !== undefined) {
                return [
                    parseInt(r + r, 16),
                    parseInt(g + g, 16),
                    parseInt(b + b, 16)
                ];
            } else if (rr !== undefined) {
                return [
                    parseInt(rr, 16),
                    parseInt(gg, 16),
                    parseInt(bb, 16)
                ];
            }
        }

        return null;
    },

    rgb_to_hsl: (r, g, b) => {
        [r, g, b] = [ColorUtils.limit(r, 0, 255) / 255, ColorUtils.limit(g, 0, 255) / 255, ColorUtils.limit(b, 0, 255) / 255];

        const max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r:
                    h = ((g - b) / d) + (g < b ? 6 : 0);
                    break;
                case g:
                    h = ((b - r) / d) + 2;
                    break;
                case b:
                    h = ((r - g) / d) + 4;
                    break;
            }

            h /= 6;
        }

        return [h * 360, s * 100, l * 100].map(Math.round);
    },

    rgb_to_hex: (r, g, b) => {
        [r, g, b] = [ColorUtils.limit(r, 0, 255), ColorUtils.limit(g, 0, 255), ColorUtils.limit(b, 0, 255)];
        return "#" + ("000000" + ((r << 16) | (g << 8) | b).toString(16)).slice(-6);
    },

    lighten: (hex, value = 10) => {
        let r,g,b;
        let h,s,l;

        [r,g,b] = ColorUtils.hex_to_rgb(hex);
        [h,s,l] = ColorUtils.rgb_to_hsl(r, g, b);

        l = l + value;

        if (l > 100) {
            l = 100;
        }

        [r, g, b] = ColorUtils.hsl_to_rgb(h, s, l);

        return ColorUtils.rgb_to_hex(r, g, b);
    },

    darken: (hex, value = 10) => {
        let r,g,b;
        let h,s,l;

        [r,g,b] = ColorUtils.hex_to_rgb(hex);
        [h,s,l] = ColorUtils.rgb_to_hsl(r, g, b);

        l = l - value;

        if (l < 0) {
            l = 0;
        }

        [r, g, b] = ColorUtils.hsl_to_rgb(h, s, l);

        return ColorUtils.rgb_to_hex(r, g, b);
    } 
}

export default ColorUtils;