function create_contact_toolbar() {
    let hide_toolbar = false;

    Graphite.config.hide_toolbar_on_pages.every(cls => {
        if (document.body.classList.contains(cls)) {
            hide_toolbar = true;
            return false;
        }

        return true;
    })

    if (hide_toolbar) return;

    let toolbar = document.querySelector('body > .contact-toolbar');

    if (!toolbar) {
        $.get_json(Graphite.config.base_url + 'process/get-contact-icons.php').then(items => {
            let toolbar = document.createElement('div');
            let sb = [];

            items.forEach(item => {
                switch (item.name) {
                    case 'shopping-cart': 
                        if (Graphite.check_permission('#shopping-cart#') && (Graphite.config.shopping_method == 'shopping-cart')) {
                            sb.push('<a draggable="false" aria-label="' + item.title + '" href="' + item.url + '" class="ux-' + item.name + '" data-cmd="view-cart" data-counter="0" title="' + item.title + '">' + item.svg + '</a>');
                        }
                        
                        break;

                    case 'personal-message':
                        sb.push('<a draggable="false" aria-label="' + item.title + '" href="' + item.url + '" class="ux-' + item.name + '" data-cmd="personal-message" data-counter="0" title="' + item.title + '">' + item.svg + '</a>');
                        break;

                    default:
                        sb.push('<a draggable="false" aria-label="' + item.title + '" href="' + item.url + '" class="ux-' + item.name + '" title="' + item.title + '">' + item.svg + '</a>');    
                }
            })

            toolbar.classList.add('contact-toolbar');
            toolbar.innerHTML = sb.join('');

            document.body.append(toolbar);

            if (Graphite.ShoppingCart) {
                setTimeout(() => {
                    let ux_shopping_cart = toolbar.querySelector('a.ux-shopping-cart');

                    let on_shopping_cart_updated = (evt) => {
                        let total_items = Graphite.ShoppingCart.get_total_items();
                        let el = evt.target;

                        el.setAttribute('data-counter', total_items);
                    }

                    if (ux_shopping_cart) { 
                        ux_shopping_cart.addEventListener('shopping-cart.updated', on_shopping_cart_updated);
                        $(ux_shopping_cart).trigger($.create_custom_event('shopping-cart.updated'));
                    }                    
                }, 800)
            }
        })
    }
}

export default create_contact_toolbar;