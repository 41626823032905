let preload_images = (el) => new Promise((resolve, reject) => {
    let array_of_image = Array.from(el.querySelectorAll('img'));
    let count = array_of_image.length;

    function _preload(url) {
        let img = document.createElement('img');

        let on_load = () => {
            img.removeEventListener('load', on_load);
            img.removeEventListener('error', on_error);
            img = null;
            count = count - 1;

            if (count == 0) {
                resolve();
            }
        }

        let on_error = () => {
            resolve(false);
            img.removeEventListener('load', on_load);
            img.removeEventListener('error', on_error);
            img = null;
            count = count - 1;

            if (count == 0) {
                resolve();
            }
        }

        img.addEventListener('load', on_load);
        img.addEventListener('error', on_error);
        img.src = url;
    }


    array_of_image.forEach(img => {
        let src = img.getAttribute('src').trim();

        if (src == '') {
            count = count - 1;

            if (count == 0) {
                resolve();
            }
        } else { 
            _preload(img.getAttribute('src'));
        }
    })
})

export default preload_images 