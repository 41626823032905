import ModalDialog from "../../modal-dialog/index.js";

function page_dialog(id, slug = '', hash = '') { 
    const dialog = new ModalDialog({
        id: 'page-dialog',
        page_id: id,
        slug: slug,
        hash: hash,
        width: 980,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0,
        always_on_top: true
    })

    let cache = 'true';

    if ((Graphite.config.environment === 'development') || (Graphite.is_login)) {
        cache = 'false';
    }

    $.get_html(Graphite.config.base_url + 'process/get-widget.php?id=' + id + '&widget=page&cache=' + cache).then(html => {
        let footer = '<footer class="flex-row center gap-10px"><a draggable="false" href="#" role="button" data-cmd="close"><i class="icon-close"></i> CLOSE</a></footer>';
        dialog.set(html + footer);
        dialog.open();
    })            
}

export default page_dialog;