class Loading {
    constructor() {
        this.el = document.createElement('div');
        this.el.setAttribute('data-id', 'loading-screen');        
    }

    show = () => new Promise(resolve => {
        document.body.append(this.el);
        return this._fadeIn().then(() => {
            this.el.classList.add('showing');
            resolve();
        })        
    })

    hide = () => new Promise(resolve => {
        this._fadeOut().then(() => {
            this.el.remove();
            this.el = null;
            resolve();
        })        
    })

    _fadeIn = (duration = 600) => new Promise(resolve => {
        let keyframes = [
            {"opacity": 0},
            {"opacity": 1}
        ]

        let settings = {
            duration: duration,
            iterations: 1,
            fill: 'both'
        }

        let animation = this.el.animate(keyframes, settings);  

        animation.addEventListener('finish', evt => {
            resolve();
        })
    })
    
    _fadeOut = (duration = 600) => new Promise(resolve => {
        let keyframes = [
            {"opacity": 1},
            {"opacity": 0}
        ]

        let settings = {
            duration: duration,
            iterations: 1,
            fill: 'both'
        }

        let animation = this.el.animate(keyframes, settings);  

        animation.addEventListener('finish', evt => {
            resolve();
        })
    })
}

export default Loading;