import ModalDialog from "../../modal-dialog/index.js";

function show_message(image, text, options = {}) {            
    const dialog = new ModalDialog({
        id: 'message-box',
        width: 440,
        padding: '1.25em',
        transition_duration: 200,
        delay_before_show: 0
    })

    const sb = [];

    sb.push('<div class="flex-row gap-16px mt-20px ai-start">');
    sb.push('<div class="ux-image-container" data-role="placeholder"><img src="' + image + '" class="mw-80px rounded-4px"/></div>');
    sb.push('<p class="flex-1">' + text + '</p>');
    sb.push('</div>');
    sb.push('<footer class="flex-row center mt-30px">');
    sb.push('<a draggable="false" href="#" role="button" data-cmd="close"><i class="icon-close"></i> Close</a>');
    sb.push('</footer>');

    dialog.set(sb.join(''));
    return dialog.open();
}

function confirm(image, text, options = {}) {            
    const dialog = new ModalDialog({
        id: 'confirm',
        width: 440,
        padding: '1.25em',
        transition_duration: 200,
        delay_before_show: 0
    })

    const sb = [];

    sb.push('<div class="flex-row gap-16px mt-20px ai-start">');
    sb.push('<div class="ux-image-container" data-role="placeholder"><img src="' + image + '" class="mw-80px rounded-4px"/></div>');
    sb.push('<p class="flex-1">' + text + '</p>');
    sb.push('</div>');
    sb.push('<footer class="flex-row center mt-30px gap-10px">');
    sb.push('<a draggable="false" href="#" role="button" data-cmd="ok"><i class="icon-checked"></i> OK</a>');
    sb.push('<a draggable="false" href="#" role="button" data-cmd="close"><i class="icon-close"></i> Cancel</a>');
    sb.push('</footer>');

    dialog.set(sb.join(''));
    return dialog.open();
}

export { show_message, confirm };