function create_user_toolbar() {
    let hide_toolbar = false;

    Graphite.config.hide_toolbar_on_pages.every(cls => {
        if (document.body.classList.contains(cls)) {
            hide_toolbar = true;
            return false;
        }

        return true;
    })

    if (hide_toolbar) return;

    let toolbar = document.createElement('div');
    let sb = [];

    sb.push('<a draggable="false" aria-label="Home" href="home" title="Home"><i class="icon-home"></i></a>');
    sb.push('<a draggable="false" aria-label="Light Mode" href="#" data-cmd="light-mode" title="Light Mode"><i class="icon-sun"></i></a>');
    sb.push('<a draggable="false" aria-label="Dark Mode" href="#" data-cmd="dark-mode" title="Dark Mode"><i class="icon-moon"></i></a>');

    toolbar.classList.add('user-toolbar');
    toolbar.innerHTML = sb.join('')
    document.body.appendChild(toolbar); 
}

export default create_user_toolbar;