import $ from '../doriyaki/index.js';
import Graphite from '../graphite/index.js';

import create_admin_toolbar from './admin-toolbar/index.js';
import { HTMLEditor, _ } from './html-editor/index.js';
import WidgetDialog from './widget-dialog/index.js';
import SimpleContextMenu from './simple-context-menu/index.js';
import DraggableList from './draggable-list/index.js';
import { edit, edit_item } from './widget-editor/index.js';
import ColorDialog from './color-dialog/index.js';
import ColorSchemeDialog from './color-scheme-dialog/index.js';
import URLDialog from './url-dialog/index.js';
import PageTypeDialog from './page-type-dialog/index.js';
import create_toggle_switch from './toggle-switch/index.js';
import select_light_dark from './light-dark-dialog/index.js';
import MenuItemSettingsDialog from './menu-item-settings-dialog/index.js';

let Admin = {
    edit: edit,
    edit_item: edit_item,
    HTMLEditor: HTMLEditor,
    DraggableList: DraggableList,
    create_admin_toolbar: create_admin_toolbar,
    SimpleContextMenu: SimpleContextMenu,
    WidgetDialog: WidgetDialog,
    ColorDialog: ColorDialog,
    ColorSchemeDialog: ColorSchemeDialog,
    URLDialog: URLDialog,
    PageTypeDialog: PageTypeDialog,
    create_toggle_switch: create_toggle_switch,
    select_light_dark: select_light_dark,
    MenuItemSettingsDialog: MenuItemSettingsDialog,

    upload: (file, data) => new Promise(async (resolve, reject) => {
        let url = './process/upload.php';wid

        let response = {
            code: 200,
            message: 'ok'
        }
        
        let formData = new FormData();

        formData.append('file', file);
        
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        })

        try {
            let r = await fetch(url, {method: 'POST', body: formData})

            if (r.ok) {
                const obj = await r.json();
                response = obj;     
            } else {
                response = {
                    status: 502,
                    description: r.statusText
                }
            }

            resolve(response);
        } catch (error) {
            response = {
                status: 501,
                description: 'something wrong'
            }

            reject(response);       
        }        
    }),

    reload_widget: (id, widget, iid = '') => new Promise((resolve, reject) => {
        let v_widget;

        if (iid) {
            v_widget = $('.widget[data-id="' + id + '"][data-widget="' + widget + '"][iid="' + iid +'"]');
        } else {
            v_widget = $('.widget[data-id="' + id + '"][data-widget="' + widget + '"]');
        }

        if ((v_widget.items.length > 0) && (v_widget.get(0))) {            
            v_widget.fadeOut(60, () => {
                let data_widget = v_widget.data('widget');
                let ts = (new Date()).getTime();
                
                $.get_html('process/get-widget.php?widget=' + data_widget + '&id=' + id + (iid?('&iid=' + iid):'') + '&ts=' + ts).then(html => {
                    if (iid) {
                        v_widget = $('.widget[data-id="' + id + '"][data-widget="' + widget + '"][iid="' + iid +'"]');
                    } else {
                        v_widget = $('.widget[data-id="' + id + '"][data-widget="' + widget + '"]');
                    }

                    let new_node = v_widget.get(0).cloneNode();

                    v_widget.get(0).parentNode.replaceChild(new_node, v_widget.get(0));
                    v_widget = $(new_node);
                    v_widget.html_unsafe($(html).get(0).innerHTML);

                    v_widget.fadeIn(400, () => {
                        resolve({
                            status: 400,
                            description: 'ok'
                        })                        
                    })
                }).catch(error => {
                    reject(error);
                })
            })
        } else {
            reject({
                status: 404,
                description: 'widget not found!'
            })
        }
    }),

    get_new_widget: (widget, file, preset = '', options = '') => new Promise((resolve, reject) => {
        $.get_html('process/get-new-widget.php?widget=' + widget + '&file=' + file + '&preset=' + encodeURIComponent(preset) + '&options=' + encodeURIComponent(options)).then(html => {
            resolve(html);
        }).catch(error => {
            reject(error);
        })
    }),

    disable_context_menu: (el) => {
        el.addEventListener('contextmenu', evt => {
            evt.preventDefault();
        })                        
    },

    setup_context_menu: (el, ctx_menu) => {
        if (el) {
            el.addEventListener('click', evt => {
                evt.preventDefault();
                ctx_menu.show({x: evt.pageX, y: evt.pageY}, evt.target);
            })            

            el.addEventListener('contextmenu', evt => {
                evt.preventDefault();
                ctx_menu.show({x:evt.pageX, y:evt.pageY}, evt.target);
            })       
        }     
    },

    update_widget_by_id: (id, node, headers = {}) => new Promise((resolve, reject) => {
        $.post_json(Graphite.config.base_url + 'process/update-widget.php', node, headers).then(res => {
            if (res.status === 200) {
                let content_type = $.default(res.node.content_type, '');

                if (['page','article','news','product'].includes(content_type)) {
                    $(document).trigger($.create_custom_event('metadata.updated', {id: res.node.id, metadata: res.node}, false));

                    if (res.x_nodes) {
                        res.x_nodes.forEach(id => {
                            $.get_json(Graphite.config.base_url + 'data/' + id + '.json').then(node => {
                                $(document).trigger($.create_custom_event('metadata.updated', {id: id, metadata: node}, false));
                            })                                        
                        })
                    }
                }

                resolve(true);
            }
        }).catch(error => {
            reject(error);
        })
    }),

    update_widget: (widget, node, headers = {}, options = '') => new Promise((resolve, reject) => {
        if (widget != 'none') {
            let parent_cached_name = Admin.get_parent_cached_name(widget.get(0));

            if (parent_cached_name) {
                headers['x-parent-cached-name'] = parent_cached_name;
            }
        }

        $.post_json(Graphite.config.base_url + 'process/update-widget.php' + (options!=''?('?' + options):''), node, headers).then(res => {
            if (res.status === 200) {
                if (widget != 'none') {
                    Graphite.Admin.reload_widget(widget.data('id'), widget.data('widget'));
                }

                let content_type = $.default(res.node.content_type, '');

                if (['page','article','news','product'].includes(content_type)) {
                    $(document).trigger($.create_custom_event('metadata.updated', {id: res.node.id, metadata: res.node}, false));

                    if (res.x_nodes) {
                        res.x_nodes.forEach(id => {
                            $.get_json(Graphite.config.base_url + 'data/' + id + '.json').then(node => {
                                $(document).trigger($.create_custom_event('metadata.updated', {id: id, metadata: node}, false));
                            })                                        
                        })
                    }
                }

                resolve(true);
            }
        }).catch(error => {
            reject(error);
        })  
    }),

    save_widget: (widget, node, headers = {}) => new Promise((resolve, reject) => {
        if (widget != 'none') {
            let parent_cached_name = Admin.get_parent_cached_name(widget.get(0));

            if (parent_cached_name) {
                headers['x-parent-cached-name'] = parent_cached_name;
            }
        }

        $.post_json(Graphite.config.base_url + 'process/save-widget.php', node, headers).then(res => {
            if (res.status === 200) {
                let content_type = $.default(res.node.content_type, '');

                if (['page','article','news','product'].includes(content_type)) {
                    $(document).trigger($.create_custom_event('metadata.updated', {id: res.node.id, metadata: res.node}, false));

                    if (res.x_nodes) {
                        res.x_nodes.forEach(id => {
                            $.get_json(Graphite.config.base_url + 'data/' + id + '.json').then(node => {
                                $(document).trigger($.create_custom_event('metadata.updated', {id: id, metadata: node}, false));
                            })                                        
                        })
                    }
                }
                
                resolve(true);
            }
        }).catch(error => {
            reject(error);
        })          
    }),

    get_parent_cached_name: (el) => {
        let cached_widget = el.closest(':is(.wx-hero,.wx-page,.wx-footer)');

        if (cached_widget) {
            return cached_widget.getAttribute('data-widget') + '-' + cached_widget.getAttribute('data-id');
        }

        return '';
    },

    create_new_page: (page_type = '1', content_type = 'page') => new Promise((resolve, reject) => {
        let data = new FormData();

        data.append('type', page_type);
        data.append('content_type', content_type);

        let url = Graphite.config.base_url + 'process/create-new-page.php';
        
        $.post(url, data).then(res => {
            if (res.status === 200) {
                resolve(res.id);
            }
        })        
    }),

    remove_page: (id) => new Promise((resolve, reject) => {
        let data = new FormData();

        data.append('id', id);

        let url = Graphite.config.base_url + 'process/remove-page.php';
        
        $.post(url, data).then(res => {
            if (res.status === 200) {
                $(document).trigger($.create_custom_event('metadata.removed', {id: id}, false)); 
                resolve(true);
            }
        })        
    }),

    make_phone_input: (el) => {
        el.addEventListener('keydown', evt => {
            if (!['0','1','2','3','4','5','6','7','8','9','Backspace','Tab','ArrowLeft','ArrowRight','-','+','Control','Shift','Delete','x','v','c'].includes(evt.key)) {
                evt.preventDefault();
                return;
            }

            if (['x','c','v'].includes(evt.key) && (!evt.ctrlKey)) {
                evt.preventDefault();
                return;
            }
        })
    },

    make_decimal_input: (el) => {
        el.addEventListener('keydown', evt => {
            if (!['0','1','2','3','4','5','6','7','8','9','Backspace','Tab','ArrowLeft','ArrowRight','.','Control','Shift','Delete','x','v','c'].includes(evt.key)) {
                evt.preventDefault();
                return;
            }

            if (['x','c','v'].includes(evt.key) && (!evt.ctrlKey)) {
                evt.preventDefault();
                return;
            }
        })
    },

    make_numeric_input: (el) => {
        let numeric_input = $.el('<div class="numeric-input" />');

        $.wrap(el, numeric_input);

        el.addEventListener('keydown', evt => {
            if (!['0','1','2','3','4','5','6','7','8','9','Backspace','Tab','ArrowUp','ArrowDown','ArrowLeft','ArrowRight','Control','Shift','Delete','x','v','c'].includes(evt.key)) {
                evt.preventDefault();
                return;
            }

            if (['x','c','v'].includes(evt.key) && (!evt.ctrlKey)) {
                evt.preventDefault();
                return;
            }

            let el = evt.target;
            let val;

            if (evt.key == 'ArrowUp') {
                el.value = parseInt($.default(el.value, '0')) + 1;
            } else if (evt.key == 'ArrowDown') {
                val = parseInt($.default(el.value, '0')) - 1;                
                
                if (val < 0) {
                    val = 0;
                }

                el.value = val;
            }
        })

        numeric_input.addEventListener('click', evt => {
            let el = evt.target;

            if (el.matches('svg')) {
                let input = numeric_input.querySelector('input');
                
                if (el.classList.contains('up')) {
                    input.value = parseInt($.default(input.value, '0')) + 1;
                } else if (el.classList.contains('down')) {
                    let val = parseInt($.default(input.value, '0')) - 1;                
                    
                    if (val < 0) {
                        val = 0;
                    }

                    input.value = val;
                }
            }
        })

        numeric_input.append($.el('<svg class="up" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8 14 4-4 4 4" stroke="#aaa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'));
        numeric_input.append($.el('<svg class="down" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m16 10-4 4-4-4" stroke="#aaa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'));
    },

    subscribe_for_mode_change: (el) => {
        Graphite.mode_change_subscribers.push(el);
    },

    subscribe_for_theme_change: (el) => {
        Graphite.theme_change_subscribers.push(el);
    },

    save_partial_as_default: (node, name) => new Promise(resolve => {
        $.post_json(Graphite.config.base_url + 'process/save-partial-as-default.php?name=' + name, node).then(res => {
            if (res.status == 200) {                    
                Graphite.iconify('ok');
                resolve(true);
            }
        })
    }),

    reset_from_partial: (widget, name) => new Promise(resolve => {
        $.get_json(Graphite.config.base_url + 'system/default/data/' + widget.attr('data-id') + '-' + name + '.json').then(v_node => {
            resolve(Graphite.Admin.update_widget(widget, v_node, {}, 'mode=merged'));
        })
    }),

    save_as_default: (id, mode = 'full') => new Promise(resolve => {
        $.get_json(Graphite.config.base_url + 'data/' + id + '.json?ts=' + Graphite.get_time()).then(node => {
            $.post_json(Graphite.config.base_url + 'process/save-as-default.php?mode=' + mode, node).then(res => {
                if (res.status == 200) {                    
                    Graphite.iconify('ok');
                    resolve(true);
                }
            })
        })        
    }),

    reset: (widget, mode = 'full') => new Promise(resolve => {
        let ts = Graphite.get_time();
        let url = Graphite.config.base_url + 'system/default/data/' + widget.data('id') + '.json?ts=' + ts;

        if (mode == 'light') {
            url = Graphite.config.base_url + 'system/default/data/' + widget.data('id') + '-light.json?ts=' + ts;
        } else if (mode == 'dark') {
            url = Graphite.config.base_url + 'system/default/data/' + widget.data('id') + '-dark.json?ts=' + ts;            
        }

        $.get_json(url).then(node => {
            let data = new FormData();

            data.append('id', widget.data('id'));

            let url = Graphite.config.base_url + 'process/remove-images-from-widget.php';
            
            $.post(url, data).then(res => {
                if (res.status === 200) {
                    let options = 'mode=override';

                    if (mode != 'full') {
                        options = 'mode=merged';
 
                        let arr = new Array();
                        let str = 'dark';

                        if (mode == 'dark') {
                            str = 'light';
                        }

                        Object.keys(node).forEach(key => {
                            if (key.startsWith(str) || key.includes('_' + str)) {
                                arr.push(key);
                            }    
                        })
                    
                        arr.forEach(key => {
                            delete node[key];
                        })
                    }

                    resolve(Graphite.Admin.update_widget(widget, node, {}, options));
                }
            })            
        })        
    }),

    clear_cache: (widget, id) => new Promise(resolve => {
        $.get_json(Graphite.config.base_url + 'process/clear-cache.php?widget=' + widget + '&id=' + id + '&ts=' + Graphite.get_time()).then(res => {
            if (res.status == 200) {
                resolve(true);
            }
        })
    }),

    icon_check: '<svg viewBox=\"0 0 32 32\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m5 16.577 2.194-2.195 5.486 5.484L24.804 7.743 27 9.937l-14.32 14.32z\"/></svg>'
}

window._ = _;

export default Admin;