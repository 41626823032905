let color_schemes = [
    {
        name: 'amazon',
        primary: '#42855B',
        secondary: '#90B77D',
        accent: '#BA9875'
    },

    {
        name: 'amber',
        primary: '#FFC107',
        secondary: '#FF8F07',
        accent: '#C4EC06'
    },

    {
        name: 'blue',
        primary: '#0081C9',
        secondary: '#3DB4F8',
        accent: '#FFB621'
    },

    {
        name: 'blue-sapphire',
        primary: '#1D5F82',
        secondary: '#339BD2',
        accent: '#9C7556'
    },

    {
        name: 'brown',
        primary: '#B07E6B',
        secondary: '#734C3E',
        accent: '#734C3E'
    },

    {
        name: 'brown-biege',
        primary: '#AD7B57',
        secondary: '#AD5A57',
        accent: '#4E869C'
    },

    {
        name: 'candy-pink',
        primary: '#E97777',
        secondary: '#D99162',
        accent: '#59A8C2'
    },

    {
        name: 'carnelian',
        primary: '#AD1D1D',
        secondary: '#D96464',
        accent: '#ADAD1D'
    },

    {
        name: 'cg-red',
        primary: '#D83F31',
        secondary: '#D87B31',
        accent: '#29B74C'
    },

    {
        name: 'cyan',
        primary: '#00BCD4',
        secondary: '#148999',
        accent: '#FFA82E'
    },

    {
        name: 'deep-carmine',
        primary: '#A91D3A',
        secondary: '#BA472D',
        accent: '#1A9A5E'
    },

    {
        name: 'deep-green-cyan',
        primary: '#197D5C',
        secondary: '#569E37',
        accent: '#B85E42'
    },

    {
        name: 'deep-orange',
        primary: '#FF5722',
        secondary: '#FF9522',
        accent: '#BCD60F'
    },

    {
        name: 'deep-puce',
        primary: '#AB6060',
        secondary: '#AB7E60',
        accent: '#92A35C'
    },

    {
        name: 'deep-purple',
        primary: '#673AB7',
        secondary: '#B63AB7',
        accent: '#77C03D'
    },

    {
        name: 'emily',
        primary: '#1D5F82',
        secondary: '#339BD2',
        accent: '#9C7556'
    },

    {
        name: 'fern-green',
        primary: '#557A46',
        secondary: '#7A9D54',
        accent: '#A77D59'
    },

    {
        name: 'french-blue',
        primary: '#0A6EBD',
        secondary: '#5A96E3',
        accent: '#FF6568'
    },

    {
        name: 'fuzzy-wuzzy',
        primary: '#BD6464',
        secondary: '#BD8864',
        accent: '#9FB45F'
    },

    {
        name: 'green',
        primary: '#4CAF50',
        secondary: '#9DBA51',
        accent: '#C59956'
    },

    {
        name: 'liberty',
        primary: '#645CAA',
        secondary: '#8C5CAA',
        accent: '#8C5CAA'
    },

    {
        name: 'light-blue',
        primary: '#03A9F4',
        secondary: '#027CB5',
        accent: '#E89802'
    },

    {
        name: 'light-cream-pink',
        primary: '#E26868',
        secondary: '#E29968',
        accent: '#D060A1'
    },

    {
        name: 'light-green',
        primary: '#8BC34A',
        secondary: '#C9D04F',
        accent: '#D48C50'
    },

    {
        name: 'light-taupe',
        primary: '#A88F72',
        secondary: '#A88172',
        accent: '#6B9D95'
    },

    {
        name: 'lime',
        primary: '#CDDC39',
        secondary: '#E4B03B',
        accent: '#E45A3B'
    },

    {
        name: 'magenta-haze',
        primary: '#9D3C72',
        secondary: '#C85C8E',
        accent: '#D4A553'
    },

    {
        name: 'old-gold',
        primary: '#C0B236',
        secondary: '#C07B36',
        accent: '#2EA472'
    },

    {
        name: 'old-moss-green',
        primary: '#8A8832',
        secondary: '#8A7632',
        accent: '#99373C'
    },

    {
        name: 'old-rose',
        primary: '#C46E6E',
        secondary: '#E1ACAC',
        accent: '#B0A08F'
    },

    {
        name: 'orange',
        primary: '#FF9800',
        secondary: '#FFCB00',
        accent: '#AEE600'
    },

    {
        name: 'pink',
        primary: '#E91E63',
        secondary: '#A80A3F',
        accent: '#78CC1F'
    },

    {
        name: 'purple',
        primary: '#9C27B0',
        secondary: '#4D27B0',
        accent: '#7DBD2A'
    },

    {
        name: 'rebecca-purple',
        primary: '#8345B5',
        secondary: '#94387B',
        accent: '#A68D3F'
    },

    {
        name: 'red',
        primary: '#F44336',
        secondary: '#A82118',
        accent: '#2ECE4E'
    },

    {
        name: 'rose-quartz-pink',
        primary: '#B0578D',
        secondary: '#D988B9',
        accent: '#BF985E'
    },

    {
        name: 'russian-green',
        primary: '#639468',
        secondary: '#899966',
        accent: '#9E806A'
    },

    {
        name: 'shadow',
        primary: '#8D7B68',
        secondary: '#8D7068',
        accent: '#6E8764'
    },

    {
        name: 'skobeloff',
        primary: '#006769',
        secondary: '#40A578',
        accent: '#827341'
    },

    {
        name: 'spanish-violet',
        primary: '#6E48C7',
        secondary: '#80489C',
        accent: '#C9B74F'
    },

    {
        name: 'teal',
        primary: '#009688',
        secondary: '#0FD4C0',
        accent: '#BBB200'
    },

    {
        name: 'violet',
        primary: '#9E378F',
        secondary: '#AB3B4B',
        accent: '#9FAD3C'
    },

    {
        name: 'golden-bee',
        primary: '#C7962C',
        secondary: '#B4C12B',
        accent: '#C7462C'
    }
]

export default color_schemes;