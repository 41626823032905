let default_colors = [
    '#F44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800','#9E9E9E','#607D8B','#000000',
    '#F55B4F','#EC3A77','#A842BA','#7A53C0','#5767BE','#3DA3F4','#23B4F5','#20C4D9','#20A397','#62B966','#99CA61','#D3E052','#FFED54','#FFC926','#FFA520','#AAAAAA','#748D99','#2A2A2A',
    '#F77268','#EF568A','#B55DC4','#8D6BC9','#6F7CC7','#58B0F6','#42BFF7','#40CDDF','#40B0A6','#79C37C','#A8D277','#DAE56B','#FFF06C','#FFD045','#FFB240','#B6B6B6','#889EA8','#555555',
    '#F88A81','#F1729E','#C178CE','#A084D2','#8792D1','#74BDF7','#62C9F8','#60D5E4','#60BDB5','#8FCD92','#B7D98E','#E0E983','#FFF284','#FFD864','#FFBF60','#C2C2C2','#9CAEB7','#808080',
    '#FAA19A','#F48FB1','#CE93D7','#B39DDB','#9FA8DA','#90CBF9','#81D4FA','#80DEEA','#80CBC3','#A5D7A7','#C5E1A4','#E6EE9C','#FFF59D','#FFE083','#FFCC80','#CFCFCF','#AFBEC5','#AAAAAA',
    '#FBB9B4','#F7ABC5','#DAAEE1','#C6B5E4','#B7BEE3','#ACD8FA','#A0DFFB','#9FE6EF','#9FD8D2','#BCE1BD','#D3E8BB','#ECF2B5','#FFF7B5','#FFE8A2','#FFD89F','#DBDBDB','#C3CED3','#D4D4D4',
    '#FCD0CD','#F9C7D8','#E6C9EB','#D9CEED','#CFD3ED','#C7E5FC','#C0E9FC','#BFEEF4','#BFE5E1','#D2EBD3','#E2F0D2','#F2F6CE','#FFFACE','#FFEFC1','#FFE5BF','#E7E7E7','#D7DFE2','#FFFFFF',
    '#FEE8E6','#FCE3EC','#F3E4F5','#ECE6F6','#E7E9F6','#E3F2FE','#DFF4FE','#DFF7FA','#DFF2F0','#E9F5E9','#F0F7E8','#F9FBE6','#FFFDE6','#FFF7E0','#FFF2DF','#F3F3F3','#EBEFF0','#XXXXXX'

]

export default default_colors;