import ModalDialog from "../../modal-dialog/index.js";

function email_dialog(email, title = '', reference_url = '') {
    return new Promise((resolve, reject) => {
        $.get_html(Graphite.config.base_url + 'dialogs/email-dialog.html').then(html => {
            const dialog = new ModalDialog({
                id: 'email-dialog',
                width: 780,
                padding: '3em',
                transition_duration: 200,
                delay_before_show: 0,
                always_on_top: true
            })

            let get_data = () => {
                let node = {};

                node.subject = form.querySelector('[name="subject"]').value;
                node.description = form.querySelector('[name="description"]').value;
                node.contact_name = form.querySelector('[name="contact_name"]').value;
                node.contact_no = form.querySelector('[name="contact_no"]').value;
                node.email = dialog.email;
                node.reference_url = $.default(form.getAttribute('data-reference-url'), '');

                return node;
            }

            let validate = (node) => {
                let subject = node.subject.trim();
                let description = node.description.trim();
                let contact_no = node.contact_no.trim();
                let contact_name = node.contact_name.trim();
                let text;

                if (subject.length <= 0) {
                    text = 'กรุณาระบุเรื่องที่ต้องการสอบถาม ด้วยนะ' + Graphite.get_ending() + '!';

                    if (Graphite.config.default_language != 'th') {
                        text = 'Please tell us the subject you want to ask';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, text).then(() => {
                        form.querySelector('[name="subject"]').focus();
                    })

                    return false;
                }

                if (description.length <= 0) {
                    text = 'กรุณาระบุรายการเอียดที่ต้องการสอบถาม ด้วยนะ' + Graphite.get_ending() + '!';

                    if (Graphite.config.default_language != 'th') {
                        text = 'Please tell us more detail about what you want to know';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, text).then(() => {
                        form.querySelector('[name="description"]').focus();
                    })

                    return false;
                }

                if (contact_name.length <= 0) {
                    text = 'กรุณาระบุชื่อ นามสกุลล ด้วยนะ' + Graphite.get_ending() + '!';

                    if (Graphite.config.default_language != 'th') {
                        text = 'Please tell us your name';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, text).then(() => {
                        form.querySelector('[name="contact_name"]').focus();
                    })
                    
                    return false;
                }

                if (contact_no.length <= 0) {
                    text = 'กรุณาระบุเบอร์โทรกลับ ด้วยนะ' + Graphite.get_ending() + '!';

                    if (Graphite.config.default_language != 'th') {
                        text = 'Please tell us your contact number';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, text).then(() => {
                        form.querySelector('[name="contact_no"]').focus();
                    })
                    
                    return false;
                }

                if (!contact_no.match(/[0-9\s+-]{9,}/)) {
                    text = 'เบอร์โทรศัพท์ไม่ถูกต้อง รบกวนตรวจสอบ ด้วยนะ' + Graphite.get_ending() + '!';

                    if (Graphite.config.default_language != 'th') {
                        text = 'Your contact number might be incorrect, please check again!';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, text).then(() => {
                        form.querySelector('[name="contact_no"]').focus();
                    })
                    
                    return false;
                }

                return true;
            }

            dialog.on('open', () => {
                setTimeout(() => {
                    if (!Graphite.is_mobile_device()) {
                        form.querySelector('[name="subject"]').focus();
                    }
                    
                    Graphite.make_phone_input(form.querySelector('[name="contact_no"]'));
                }, 10)
            })

            dialog.popup.addEventListener('click', evt => {
                if (evt.target.matches('[data-cmd="send"]')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let node = get_data();

                    if (validate(node)) {
                        let req = {};

                        req.recipient_email = node.email;
                        req.recipient_name = node.email;
                        req.sender_email = 'admin@8columns.com';
                        req.sender_name = node.contact_name;
                        req.subject = node.subject;
                        req.body = node.description;

                        if (node.reference_url) {
                            req.body = '<p>' + req.body + '<br /></p><p><a href="' + node.reference_url + '">' + node.reference_url + '</a>';;
                        }

                        let loading = new Graphite.Loading();

                        loading.show().then(() => {
                            $.post_json(Graphite.config.base_url + 'process/send-mail.php', req).then(res => {
                                if (res.status === 200) {
                                    dialog.set_cmd('send');
                                    dialog.set_value(true);
                                    dialog.close();                                                  
                                    loading.hide();
                                } else {
                                    loading.hide().then(() => {
                                        text = 'เกิดข้อผิดพลาดทางเทคนิค ไม่สามารถส่ง E-mail ได้ในขณะนี้ อีกซักครู่กรุณาลองอีกครั้ง หรือ ติดต่อ Admin เพื่อขอรับความช่วยเหลือ';

                                        if (Graphite.config.default_language != 'th') {
                                            text = 'There are some technical issues on our side, please contact Admin for support';
                                        }

                                        Graphite.show_message(Graphite.admin.profile_image, text);
                                    })
                                }
                            })                        
                        })
                    }
                }
            })

            dialog.set(html);
            dialog.email = email;

            let form = dialog.popup.querySelector('form');
            let v_title = title.trim();
            let v_reference_url = reference_url.trim();

            if (v_title) {
                dialog.popup.querySelector('input[name="subject"]').value = v_title;
            }

            if (reference_url) {
                dialog.popup.querySelector('form').setAttribute('data-reference-url', reference_url);                
            }

            resolve(dialog.open());
        })        
    })            
}

export default email_dialog;