import Spectrum, {rgb_to_hsv, hex_to_rgb, rgb_to_hsl, rgb_to_hex} from './spectrum.js';
const icon_none = '<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.5.877a6.623 6.623 0 0 0-5.023 10.94l-.83.83a.5.5 0 1 0 .707.707l.83-.83a6.623 6.623 0 0 0 9.34-9.34l.83-.83a.5.5 0 1 0-.708-.708l-.83.83A6.597 6.597 0 0 0 7.5.878Zm3.642 2.274a5.673 5.673 0 0 0-7.992 7.992l7.992-7.992Zm-7.284 8.698a5.673 5.673 0 0 0 7.992-7.992L3.857 11.85Z" /></svg>';

class ColorPicker {
	constructor(el, _options = {}) {
		let _default = {
			color: '#9bcf53',
			colors: [],
			on_select_color: null,
			quick_selection: true	
		}

		this.options = Object.assign(_default, _options);
		this.el = el;
		this.colors = this.options.colors;
		this.color = this.options.color;
		// this.el.innerHTML = '<div class="ux-spectrum"><canvas width="448" height="280" id="canvas"></canvas><span class="ux-marker"></span></div><div class="ux-colors"></div><div class="bottom"><div class="input-wrapper"><span class="ux-preview">' + icon_none + '</span><form><input type="text" /></form></div><a href="#" role="button"><i class="icon-ok"></i> OK</a></div>';
		this.el.innerHTML = '<div class="ux-spectrum"><canvas width="482" height="306.45" id="canvas"></canvas><span class="ux-marker"></span></div><div class="ux-colors"></div><div class="bottom"><div class="input-wrapper"><span class="ux-preview">' + icon_none + '</span><form><input type="text" /></form></div><a href="#" role="button"><i class="icon-ok"></i> OK</a></div>';
		this.marker = this.find('.ux-marker');
		this.canvas = this.find('canvas');
		this.preview = this.find('.ux-preview');
		this.input_text = this.find('input[type="text"]');		
		this.ux_colors = this.find('.ux-colors');
		this.ok_button = this.find('a[role="button"]');

		this.ux_colors.addEventListener('click', evt => {
			if (evt.target.nodeName === 'SPAN') {
				if (evt.target.classList.contains('ux-none')) {
					this.set_color('none');
				} else {
					let el = evt.target;
					let color = el.dataset['color'];
					let rgb = hex_to_rgb(color);
					let hex = rgb_to_hex(rgb[0], rgb[1], rgb[2]);

					this.set_color(hex);
				}
			}
		})

		this.ux_colors.addEventListener('dblclick', evt => {
			if (evt.target.nodeName === 'SPAN') {
				if (evt.target.classList.contains('ux-none')) {
					this.set_color('none');
				} else {
					let el = evt.target;
					let color = el.dataset['color'];
					let rgb = hex_to_rgb(color);
					let hex = rgb_to_hex(rgb[0], rgb[1], rgb[2]);

					this.set_color(hex);
				}
			}

			this.options.on_select_color(this.color);		
		})

		this.input_text.addEventListener('input', evt => {
			let str = this.input_text.value;

			if (str.match(/^#[0-9A-Fa-f]{6,}/)) {
				this.set_color(str);
			}
		})

		this.ok_button.addEventListener('click', evt => {
			evt.preventDefault();

			if (typeof this.options.on_select_color === 'function') {
				let color = this.input_text.value;

				if (this.preview.classList.contains('ux-none')) {
					color = 'none';
				}

				this.options.on_select_color(color);
			}
		})

		this.spectrum = Spectrum(this.canvas);

		this.setup_marker(this.marker, (x, y) => {
			let rgba = this.spectrum.grab_color(x, y);
			let color = rgb_to_hex(rgba[0], rgba[1], rgba[2]);

			this.set_color(color, false);
		})

		this.canvas.addEventListener('click', evt => {
			let x = evt.layerX;
			let y = evt.layerY;		
			let rgba = this.spectrum.grab_color(x, y);
			let hex = rgb_to_hex(rgba[0], rgba[1], rgba[2]);


			this.set_marker_position(x, y);
			this.update_color(hex);
			this.color = hex;
		})

		this.canvas.addEventListener('dblclick', evt => {
			this.options.on_select_color(this.color);
		})

		this.colors.forEach(color => {
			let span = document.createElement('span');

			if (color === '#XXXXXX') {
				span.style.setProperty('--color', 'transparent');
				span.setAttribute('data-color', 'none');
				span.classList.add('ux-none');
				span.innerHTML = icon_none;
			} else {
				span.style.setProperty('--color', color);
				span.setAttribute('data-color', color.toUpperCase());
			}

			this.ux_colors.append(span);
		})

		this.set_color(this.color, true, true);
	}

	setup_marker(el, cb) {
		let pos = [0,0,0,0];

	   	el.addEventListener('mousedown', evt => {
		    evt.preventDefault();
		    pos[2] = evt.clientX;
		    pos[3] = evt.clientY;

		    document.onmouseup = evt => {
			    let x =  el.style.left = (el.offsetLeft - pos[0]);
			    let y = el.style.top = (el.offsetTop - pos[1]);

			    document.onmouseup = null;
			    document.onmousemove = null;    

			    cb(x, y);	
		    }

		    document.onmousemove = evt => {
		    	evt.preventDefault();
			    pos[0] = pos[2] - evt.clientX;
			    pos[1] = pos[3] - evt.clientY;
			    pos[2] = evt.clientX;
			    pos[3] = evt.clientY;

			    let x =  (el.offsetLeft - pos[0]);
			    let y = (el.offsetTop - pos[1]);

			    el.style.left = x + 'px';    	
			    el.style.top = y + 'px';

		    	cb(x, y);
		    }
	   	})
	}

	update_color(hex) {
		if (hex === 'none') {
			this.input_text.value = 'none';
			this.preview.style.setProperty('--color', 'transparent');
			this.preview.classList.add('ux-none');
		} else {
			this.input_text.value = hex;
			this.preview.style.setProperty('--color', hex);
			this.preview.classList.remove('ux-none');
		}
	}

	set_marker_position(x, y) {
		let w = this.marker.getBoundingClientRect().width;
		let h = this.marker.getBoundingClientRect().height;

		x = x - (w / 2);
		y = y - (h / 2);

		this.marker.style.left = x + 'px';
		this.marker.style.top = y + 'px';
	}

	set_color(color, update_spectrum = true, first_time = false) {
		if (['none',''].includes(color)) {
			this.input_text.value = 'none';
			this.update_color('none');
			this.color = color;
		} else {
			let matches = color.match(/^rgb\((\d)+,(\d)+,(\d)+\)$/);

			if (matches) {
				this.color = rgb_to_hex(matches[1], matches[2], matches[3]);
			} else {
				matches = color.match(/^#([0-9A-Fa-f]{6,6})/);

				if (matches) {
					this.color = '#' + matches[1];
				}
			}

			if (update_spectrum) {
				let rgb = hex_to_rgb(this.color);
				let hsl = rgb_to_hsl(rgb[0],rgb[1],rgb[2]);
				let pos = this.spectrum.find_color(rgb[0], rgb[1], rgb[2]);

				this.spectrum.set_hue(hsl[0]);
				this.set_marker_position(pos[0], pos[1]);
			}

			let el;

			el = this.ux_colors.querySelector('.selected');

			if (el) {
				el.classList.remove('selected');
			} 

			el = this.ux_colors.querySelector('[data-color="' + this.color.trim().toUpperCase() +'"]');

			if (el) {
				el.classList.add('selected');
			}

			this.update_color(this.color);
		}
	}

	find(selector) {
		return this.el.querySelector(selector);
	}
}

export default function(el, options) {
	return new ColorPicker(el, options);
}