import ModalDialog from "../../modal-dialog/index.js";

class LightDarkDialog {
    constructor(url = '') {
        this.dialog = new ModalDialog({
            id: 'light-dark-dialog',
            width: 580,
            padding: '2em'
        })
    
        let sb = [];
        
        sb.push('<div class="flex-row center gap-14px">');
        sb.push('<div data-mode="light"><div class="ux-image-container" data-role="placeholder"><img src="" /></div><p>Light</p></div>');
        sb.push('<div data-mode="dark"><div class="ux-image-container" data-role="placeholder"><img src="" /></div><p>Dark</p></div>');
        sb.push('</div>');

        this.dialog.set(sb.join('') + '<footer class="flex-row center"><a href="#" role="button" data-cmd="close"><i class="icon-close"></i> CLOSE</a></footer>');
 
        this.dialog.on('open', () => {
            this.dialog.popup.addEventListener('click', evt => {
                let el = evt.target;

                if (el.matches('[data-mode]')) {
                    evt.preventDefault();

                    this.dialog.set_cmd('ok');
                    this.dialog.set_value(el.getAttribute('data-mode'));
                    this.dialog.close();
                }
            })
        })
    }

    select = () => {
        return this.dialog.open();
    }
}


let select_light_dark = () => {
    let dialog = new LightDarkDialog();

    return dialog.select();
}

export default select_light_dark;