import default_colors from './default-colors.js';
import ColorPicker from "./ColorPicker.js";
import ModalDialog from "../../modal-dialog/index.js";

class ColorDialog {
    constructor(options = {}) {
        const _default = {
            color: '#4f6f52',
            colors: default_colors
        }

        this.options = Object.assign(_default, options);

        this.dialog = new ModalDialog({
            id: 'color-dialog',
            width: 546,
            padding: '2em'
        })

        this.dialog.set('<div class="dialog"><div class="ux-color-picker"></div></div>');

        const ux_color_picker = this.dialog.popup.querySelector('.ux-color-picker');

        this.color_picker = ColorPicker(ux_color_picker, {
            color: this.options.color,
            colors: this.options.colors,
            on_select_color: color => {
                this.dialog.set_cmd('ok');
                this.dialog.set_value(color);
                this.dialog.close();
            }
        })
    }

    select_color = () => {
        return this.dialog.open();
    }
}

export default ColorDialog;