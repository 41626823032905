import _ from './helper.js';
import $ from '../../doriyaki/index.js';
import ColorDialog from '../color-dialog/index.js';
import IconsDialog from '../icons-dialog/index.js';
import URLDialog from '../url-dialog/index.js';
import icons from './icons.js';

const buttons = [
    {
        name: "bold",
        icon: icons.bold,
        tooltip: 'Bold',
        action: () => {
            let selection = _.get_selection();
            let node = selection.focusNode;

            if (node) {
                let parent_node = _.find_parent(node, 'b');

                if (parent_node) {
                    $(parent_node).unwrap();
                } else {
                    _.exec_command('bold');    
                }
            } 
        },
        get_state: () => new Promise(resolve => {
            _.query_command_state('bold').then(state => {
                resolve(state?'active':'');                
            })
        })
    },

    {
        name: "italic",
        icon: icons.italic,
        tooltip: 'Italic',
        action: () => {
            let selection = _.get_selection();
            let node = selection.focusNode;

            if (node) {
                let parent_node = _.find_parent(node, 'em');

                if (parent_node) {
                    $(parent_node).unwrap();
                } else {
                    _.exec_command('italic');    
                }
            } 
        },
        get_state: () => new Promise(resolve => {
            _.query_command_state('italic').then(state => {
                resolve(state?'active':'');
            })
        })
    },

    {
        name: "strikethrough",
        icon: icons.strike_through,
        tooltip: 'Strike Through',
        action: () => {
            let selection = _.get_selection();
            let node = selection.focusNode;

            if (node) {
                let parent_node = _.find_parent(node, 's');

                if (parent_node) {
                    $(parent_node).unwrap();
                } else {
                    _.exec_command('strikethrough');    
                }
            } 
        },
        get_state: () => new Promise(resolve => {
            _.query_command_state('strikethrough').then(state => {
                resolve(state?'active':'');                
            })
        }) 
    },

    {
        name: "unordered-list",
        icon: icons.unordered_list,
        tooltip: 'Unordered List',

        action: () => {
            let selection = _.get_selection();
            let node = selection.focusNode;

            if (node.nodeName === 'P') {
                let list = $('<ul><li><span id="target"></span></li></ul>').get(0);
                node.before(list);

                let span = list.querySelector('span#target');
                let new_node = $('&nbsp;').get(0);

                span.after(new_node);
                span.remove();
                _.create_selection(new_node);

            }
        },

        get_state: () => new Promise(resolve => {
            setTimeout(() => {
                let selection = _.get_selection();
                let node = selection.focusNode;

                if (node) {
                    if (node.nodeName === 'P') {
                        resolve('');
                    } else {
                        resolve('disabled');
                    }
                } else {
                    resolve('disabled');                            
                }                            
            }, 200)
        }) 
    },

    {
        name: "ordered-list",
        icon: icons.ordered_list,
        tooltip: 'Ordered List',
        
        action: () => {
            let selection = _.get_selection();
            let node = selection.focusNode;

            if (node.nodeName === 'P') {
                let list = $('<ol><li><span id="target"></span></li></ol>').get(0);
                node.before(list);

                let span = list.querySelector('span#target');
                let new_node = $('&nbsp;').get(0);

                span.after(new_node);
                span.remove();
                _.create_selection(new_node);
            }
        },

        get_state: () => new Promise(resolve => {
            setTimeout(() => {
                let selection = _.get_selection();
                let node = selection.focusNode;

                if (node) {
                    if (node.nodeName === 'P') {
                        resolve('');
                    } else {
                        resolve('disabled');
                    }
                } else {
                    resolve('disabled');                            
                }                            
            }, 200)
        }) 
    },

    {
        name: "divider",
        icon: icons.divider,
        tooltip: 'Divider',
        
        action: () => {
            let selection = _.get_selection();
            let node = selection.focusNode;

            if (node.nodeName === 'P') {
                let divider = $('<hr />').get(0);
                let p = $('<p><span id="target"></span><br /></p>');

                node.before(divider);
                $(divider).after(p);

                let span = p.find('span#target').get(0);
                let new_node = $('&nbsp;');
                
                $(span).after(new_node);
                $(span).remove();
                _.create_selection(new_node.get(0));
            }
        },

        get_state: () => new Promise(resolve => {
            setTimeout(() => {
                let selection = _.get_selection();
                let node = selection.focusNode;

                if (node) {
                    if (node.nodeName === 'P') {
                        resolve('');
                    } else {
                        resolve('disabled');
                    }
                } else {
                    resolve('disabled');                            
                }                            
            }, 200)
        }) 
    },

    {
        name: "color",
        icon: icons.color,
        tooltip: 'Text Color',
        
        action: (_this) => {
            let color = '#D3181D';
            let r = _.get_range();

            if (!r) return;

            let el = r.startContainer;

            if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                let p = _.find_parent(el, 'span[style^="color"]');

                if (p) {
                    let style = p.getAttribute('style');
                    let matches = style.match(/color:(.+)/);
                    
                    if (matches) {
                        color = matches[1].trim();
                    }
                }
            }

            const color_dialog = new ColorDialog({color: color});
            color_dialog.select_color().then(data => {
                if (data.cmd === 'ok') {
                    let s = _.get_selection();

                    s.removeAllRanges();
                    s.addRange(r);
                    _.exec_command('color', data.value);
                }
            }).finally(() => {
                _this.update_state();
            })
        },
        
        get_state: () => new Promise(resolve => {
            _.query_command_state('color').then(state => {
                resolve(state?'active':'');                
            })
        })
    },

    {
        name: "hilight",
        icon: icons.hilight,
        tooltip: 'Hi-Light',

        action: (_this) => {
            let color = '#D3181D';
            let r = _.get_range();

            if (!r) return;

            let el = r.startContainer;

            if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                let p = _.find_parent(el, 'span.hilight');

                if (p) {
                    let style = p.getAttribute('style');
                    let matches = style.match(/--bg-color:(.+)/);
                    
                    if (matches) {
                        color = matches[1].trim();
                    }
                }
            }

            const color_dialog = new ColorDialog();
            color_dialog.select_color().then(data => {
                if (data.cmd === 'ok') {
                    let s = _.get_selection();

                    s.removeAllRanges();
                    s.addRange(r);
                    _.exec_command('hilight', data.value);
                    _this.update_state();
                }
            }).finally(() => {
                _this.update_state();
            })

        },
                
        get_state: () => new Promise(resolve => {
            _.query_command_state('hilight').then(state => {
                resolve(state?'active':'');                
            })
        })
    },

    {
        name: "link",
        icon: icons.link,
        tooltip: 'Link',
        
        action: (_this) => {
            let r = _.get_range();

            if (!r) return;

            let el = r.startContainer;
            let a = _.find_parent(el, 'a');
            let url = '';

            if (a) {
                url = a.getAttribute('href');
            }

            const url_dialog = new URLDialog(url);
            
            url_dialog.get_url().then(data => {
                if (data.cmd === 'ok') {
                    let s = _.get_selection();

                    s.removeAllRanges();
                    s.addRange(r);

                    _.exec_command('link', data.value);
                }
            }).finally(() => {
                _this.update_state();
            })
        },
        
        get_state: () => new Promise(resolve => {
            _.query_command_state('link').then(state => {
                resolve(state?'active':'');                
            })
        })
    },

    {
        name: "icon",
        icon: icons.icon,
        tooltip: 'Icon',

        action: (_this) => {
            let r = _.get_range();

            if (!r) return;

            const icons_dialog = new IconsDialog({});

            icons_dialog.select_icon().then(data => {
                if (data.cmd === 'ok') {

                    let s = _.get_selection();

                    s.removeAllRanges();
                    s.addRange(r);
                    _.exec_command('icon', data.value);
                    _this.update_state();
                }
            }).finally(() => {
                _this.update_state();
            })
        },

        get_state: () => new Promise(resolve => {
            _.query_command_state('icon').then(state => {
                resolve(state?'active':'');                
            })
        })
    },

    {
        name: "remove-styles",
        icon: icons.eraser,
        tooltip: 'Remove Styles',
        
        action: (_this) => {
            let selection = _.get_selection();
            let node = selection.focusNode;

            if (node) {
                let top_node = _.find_top_node(node);

                if (top_node.matches && top_node.matches(':is(span,b,i,s)')) {
                    top_node.replaceWith(document.createTextNode(top_node.innerText));                    
                    _.select_none();
                    _this.update_state();
                }
            } 
        },
        
        get_state: () => new Promise(resolve => {
            setTimeout(() => {
                let selection = _.get_selection();
                let node = selection.focusNode;

                if (node) {
                    let top_node = _.find_top_node(node);

                    if (top_node.matches && top_node.matches(':is(span,b,i,s)')) {
                        resolve('')
                    }
                } 

                resolve('disabled');                            
            }, 30)
        }) 
    }    
]

export default buttons;