import $ from '../../doriyaki/index.js';
import ModalDialog from "../../modal-dialog/index.js";
import ImageUpload from "../image-upload/index.js";
import IconsDialog from "../icons-dialog/index.js";
import DraggableList from "../draggable-list/index.js";

function get_editor(item) {
    let sb = [];

    if (item.type === 'hidden') {
        item.editor = 'hidden';
    }

    if ($.default(item.disabled,'') === 'true') {
        return '';
    }

    if ($.default(item.type, 'text') === 'list') {
        sb.push('<div class="field" data-name="' + item.name + '" data-editor="list">');    

        if (item.value.length > 0) {
            let clone = JSON.parse(JSON.stringify(item.value[0]));            

            sb.push('<template>');

            clone.forEach(x_item => {
                x_item.value = null;
                sb.push(get_editor(x_item));
            })

            sb.push('</template>');            
        }

        if (item.display !== '-') {
            sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');    
        }

        if (item.description) {
            sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
        }

        sb.push('<fieldset>');    

        let page = 1;
        
        item.value.forEach(v_item => {
            sb.push('<div role="page" data-page="' + page + '" class="' + (page == 1?'active':'') + '">');    

            v_item.forEach(x_item => {
                sb.push(get_editor(x_item));                                
            })

            sb.push('</div>');
            page++;
        })

        sb.push('<a href="#" data-cmd="remove-item" role="button" class="small"><i class="icon-bin"></i> REMOVE ITEM</a>');
        sb.push('<ul class="ux-pages flex-row center gap-5px mt-40px" style="font-size:0.80em"></ul>');
        
        sb.push('<a href="#" data-cmd="new-item" role="button" class="small"><i class="icon-plus"></i> NEW ITEM</a>');

        sb.push('</div>');
        sb.push('</fieldset>');    
        sb.push('</div>');    
    } else {
        let style;

        switch ($.default(item.editor, 'textbox')) {
            case 'textbox': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'">');  
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div class="flex-row">');
                    sb.push('<input type="text" value="' + $.attr_escape(item.value) + '" data-type="' + $.default(item.type, 'text') + '" />');
                    sb.push('</div>');
                    sb.push('</div>');    
                }

                break;

            case 'text': {
                    if (item.value == null) {
                        item.value = {
                            icon: '',
                            text: '',
                            url: ''
                        }
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    let enable_image = $.default(item.enable_image, 'false');
                    let enable_icon = $.default(item.enable_icon, 'false');
                    let enable_link = $.default(item.enable_link, 'false');
                    let enable_setting = $.default(item.enable_setting, 'false');

                    sb.push('<div class="flex-row">');

                    if (enable_image == 'true') {
                        let node = {"id":"100000"};
                        let filename;
                        let max_width, max_height;
                        
                        node.image = $.default(item.value.image, {});
                        filename = $.default(node.image.filename, '');
                        max_width = $.default(item.max_width, '');
                        max_height = $.default(item.max_height, '');

                        sb.push('<span class="ux-image" title="Image" data-filename="' + filename + '" data-max-width="' + max_width + '" data-max-height="' + max_height +'" data-image=\'' + JSON.stringify(node) + '\'><i class="icon-image"></i></span>');
                    }

                    if (enable_icon == 'true') {
                        sb.push('<span class="ux-icon" title="Icon">' + $.default(item.value.icon, '') + '</span>');
                    }

                    sb.push('<input type="text" value="' + $.attr_escape($.default(item.value.text, '')) + '" data-type="' + $.default(item.type, 'text') + '" />');
                    
                    if (enable_link == 'true') {
                        sb.push('<span class="ux-link" data-url="' + $.default(item.value.url, '') + '" title="Link"><i class="icon-link"></i></span>');
                    }

                    if (enable_setting == 'true') {
                        sb.push('<span class="ux-setting" data-setting-widget="' + item.setting_widget + '" data-settings=\'' + JSON.stringify($.default(item.value.settings, {})) + '\' title="กำหนดค่าเพิ่มเติม"><i class="icon-cog"></i></span>');
                    }

                    sb.push('</div>');
                    sb.push('</div>');    
                }

                break;

            case 'color': {
                if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'">');  
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');
                    sb.push('<div class="color" data-color="' + item.value + '" style="--bg-color:' + item.value + ';"></div>');
                    sb.push('</div>');
                    sb.push('</div>');    
                }

                break;

            case 'pattern': {
                if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'">');  
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.value) {
                        sb.push('<div class="pattern" data-pattern="' + item.value + '" style="--bg-image: url(../../../patterns/' + item.value + ');"></div>');
                    } else {
                        sb.push('<div class="pattern" data-pattern="' + item.value + '" style="--bg-image: none;"></div>');
                    }

                    sb.push('</div>');
                    sb.push('</div>');    
                }

                break;

            case 'table': {
                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    if (!Array.isArray(item.value)) {
                        item.value = [
                            {"key":"", "value":""}
                        ]
                    }

                    if (Array.isArray(item.value)) {
                        let enable_icon = $.default(item.enable_icon, 'false');

                        sb.push('<div class="ux-draggable-list">');

                        item.value.forEach(item => {
                            sb.push('<div class="flex-row">');
                            sb.push((enable_icon==='true'?'<span class="ux-icon">' + $.default(item.icon, '') + '</span>':'') + '<input type="text" name="key" value="' + $.attr_escape($.default(item.key, '')) + '" /><input type="text" name="value" value="' + $.attr_escape($.default(item.value, '')) + '" /><i class="icon-minus-in-circle" data-cmd="remove-item"></i><i class="icon-plus-in-circle" data-cmd="new-item"></i><i class="icon-dragger-square" draggable="true"></i>')
                            sb.push('</div>');
                        })

                        sb.push('</div>');

                        let display_for_key = $.default(item.display_for_key, '');
                        let display_for_value = $.default(item.display_for_value, '');

                        if (display_for_key !== '') {
                            sb.push('<div class="flex-row">');
                            sb.push('<p class="ux-caption">' + display_for_key + '</p>');
                            sb.push('<p class="ux-caption">' + display_for_value + '</p>');
                            sb.push('</div>');
                        }
                    }
                    
                    sb.push('</div>');  
                }  

                break;

            case 'time-table': {
                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    if (!item.value) {
                        item.value = [
                            {"key":"", "value":""}
                        ]
                    }

                    if (Array.isArray(item.value)) {
                        sb.push('<div class="ux-draggable-list">');

                        item.value.forEach(item => {
                            sb.push('<div class="flex-row">');
                            sb.push('<input type="text" name="key" value="' + $.attr_escape($.default(item.key, '')) + '" /><input type="text" name="value" value="' + $.attr_escape($.default(item.value, '')) + '" /><i class="icon-minus-in-circle" data-cmd="remove-item"></i><i class="icon-plus-in-circle" data-cmd="new-item"></i><i class="icon-dragger-square" draggable="true"></i>')
                            sb.push('</div>');
                        })

                        sb.push('</div>');

                        let display_for_key = $.default(item.display_for_key, '');
                        let display_for_value = $.default(item.display_for_value, '');

                        if (display_for_key !== '') {
                            sb.push('<div class="flex-row">');
                            sb.push('<p class="ux-caption">' + display_for_key + '</p>');
                            sb.push('<p class="ux-caption">' + display_for_value + '</p>');
                            sb.push('</div>');
                        }
                    }
                    
                    sb.push('</div>');    
                }

                break;    

            case 'list-of-tuple': {
                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'" style="--value-width:' + $.default(item.value_width, '344.6px') + '">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    if (!item.value) {
                        item.value = [
                            {   
                                "id": Graphite.short_uid(),
                                "key":"", 
                                "value":""
                            }
                        ]
                    }

                    let enable_image = $.default(item.enable_image, 'false');

                    if (Array.isArray(item.value)) {
                        if (item.value.length == 0) {
                            item.value = [{"key":"", "value":""}];
                        }

                        sb.push('<div class="ux-draggable-list">');

                        let enable_setting = $.default(item.enable_setting, 'false');
                        let enable_link = $.default(item.enable_link, 'false');
                        let setting_widget = $.default(item.setting_widget, '');
                        let o_item = item;

                        item.value.forEach(item => {
                            sb.push('<div class="flex-row" data-id="' + item.id + '">');

                            if (enable_image == 'true') {
                                let node = {"id":"100000"};
                                let filename;
                                let max_width, max_height;
                                
                                node.image = $.default(item.image, {});
                                filename = $.default(node.image.filename, '');
                                max_width = $.default(o_item.max_width, '');
                                max_height = $.default(o_item.max_height, '');

                                sb.push('<span class="ux-image" title="Image" data-filename="' + filename + '" data-max-width="' + max_width + '" data-max-height="' + max_height +'" data-image=\'' + JSON.stringify(node) + '\'><i class="icon-image"></i></span>');
                            }

                            sb.push('<div class="for-key" data-caption="' + $.default(o_item.display_for_key, '') + '"><input type="text" name="key" value="' + $.attr_escape($.default(item.key, '')) + '" data-type="' + $.default(o_item.type_of_key, 'text') + '" /></div>');
                            sb.push('<div class="for-value" data-caption="' + $.default(o_item.display_for_value, '') + '"><input type="text" name="value" value="' + $.attr_escape($.default(item.value, '')) + '" data-type="' + $.default(o_item.type_of_value, 'text') + '" /></div>');

                            if (enable_link == 'true') {
                                sb.push('<span class="ux-link" title="Link" data-url="' + $.default(item.url, '') + '"><i class="icon-link"></i></span>');
                            }

                            if (enable_setting == 'true') {
                                sb.push('<span class="ux-setting" data-setting-widget="' + setting_widget + '" data-settings=\'' + JSON.stringify($.default(item.settings, {})) + '\' title="กำหนดค่าเพิ่มเติม"><i class="icon-cog"></i></span>');
                            }

                            sb.push('<i class="icon-minus-in-circle" data-cmd="remove-item"></i><i class="icon-plus-in-circle" data-cmd="new-item"></i><i class="icon-dragger-square" draggable="true"></i>')
                            sb.push('</div>');
                        })

                        sb.push('</div>');
                    }
                    
                    sb.push('</div>');    
                }

                break;

            case 'list-of-text': {
                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'">');    
                    
                    if (item.display !== '-') {
                        sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');
                    }

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    if (!item.value) {
                        item.value = [
                            {
                                id: Graphite.short_uid(),
                                text: ''
                            }
                        ]
                    }

                    let placeholder = $.default(item.placeholder, 'Text');

                    if (Array.isArray(item.value)) {
                        sb.push('<div class="ux-draggable-list">');

                        let icon = $.default(item.icon,'');
                        let enable_icon = $.default(item.enable_icon, 'false');
                        let enable_image = $.default(item.enable_image, 'false');
                        let enable_link = $.default(item.enable_link, 'false');
                        let enable_setting = $.default(item.enable_setting, 'false');
                        let setting_widget = $.default(item.setting_widget, '');
                        let o_item = item;

                        item.value.forEach(item => {
                            sb.push('<div class="flex-row" data-id="' + item.id + '">');
                            sb.push(enable_icon==='true'?('<span class="ux-icon" title="Icon">' + $.default(item.icon, '') + '</span>'):'');
                            sb.push(icon ===''?'':('<i class="icon-' + icon + '"></i>'));

                            if (enable_image == 'true') {
                                let node = {"id":"100000"};
                                let filename;
                                let max_width, max_height;
                                
                                node.image = $.default(item.image, {});
                                filename = $.default(node.image.filename, '');
                                max_width = $.default(o_item.max_width, '');
                                max_height = $.default(o_item.max_height, '');

                                sb.push('<span class="ux-image" title="Image" data-filename="' + filename + '" data-max-width="' + max_width + '" data-max-height="' + max_height +'" data-image=\'' + JSON.stringify(node) + '\'><i class="icon-image"></i></span>');
                            }

                            sb.push('<input type="text" data-type="' + $.default(o_item.type, 'text') + '" value="' + $.attr_escape($.default(item.text, '')) + '" placeholder="' + placeholder + '" />');

                            if (enable_link == 'true') {
                                sb.push('<span class="ux-link" title="Link" data-url="' + $.default(item.url, '') + '"><i class="icon-link"></i></span>');
                            }

                            if (enable_setting == 'true') {
                                sb.push('<span class="ux-setting" data-setting-widget="' + setting_widget + '" data-settings=\'' + JSON.stringify($.default(item.settings, {})) + '\' title="กำหนดค่าเพิ่มเติม"><i class="icon-cog"></i></span>');
                            }

                            sb.push('<i class="icon-minus-in-circle" data-cmd="remove-item"></i><i class="icon-plus-in-circle" data-cmd="new-item"></i><i class="icon-dragger-square" draggable="true"></i>');
                            sb.push('</div>');
                        })

                        sb.push('</div>');
                    }
                    
                    sb.push('</div>');    
                }

                break;

            case 'hidden': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + $.default(item.editor, 'textbox') +'" data-value="' + item.value + '"></div>');    
                }

                break;

            case 'image': {
                    if (!item.value) {
                        item.value = {
                            filename: '',
                            width: '',
                            height: '',
                            file_size: '',
                            url: ''
                        }
                    }

                    sb.push('<div class="field" data-editor="image" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');
                    
                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div class="ux-image-preview ' + (item.class?item.class:'') + '" data-max-width="' + $.default(item.max_width, Graphite.config.max_width) + '" data-max-height="' + $.default(item.max_height, '') + '" data-filename="' + $.default(item.value.filename, '') + '" data-width="' + $.default(item.value.width, '') + '" data-height="' + $.default(item.value.height, '') + '" data-file-size="' + $.default(item.value.file_size, '') + '" data-alt="' + Graphite.escape($.default(item.value.alt, '')) + '">');
                    sb.push('<img src="' + item.value.filename + '" />');

                    if ($.default(item.link, 'off') == 'on') {
                        sb.push('<i class="icon-link" data-url="' + $.default(item.value.url, '') + '"></i>');
                    }

                    sb.push('</div>');
                    sb.push('<p class="ux-caption">' + item.value.width + ' x ' + item.value.height + ' px (' + $.friendly_file_size(item.value.file_size) + ')' + '</p>');
                    sb.push('<a role="button" class="download-button small" href="' + Graphite.config.base_url + item.value.filename + '" download><i class="icon-download"></i> Download</a>')
                    sb.push('</div>');  

                } 

                break;

            case 'range': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<input type="range" min="' + $.default(item.min, '0') + '" max="' + $.default(item.max, '100') + '" step="' + $.default(item.step, '5') + '" value="' + $.default(item.value, '75') + '" data-unit="' + $.default(item.unit, '') + '" />');
                    sb.push('<p class="ux-caption"></p>');
                    sb.push('</div>');
                }

                break;

            case 'url': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div class="flex-row url-container">');
                    sb.push('   <input type="text" value="' + $.attr_escape(item.value) + '" />');
                    sb.push('   <a href="#" class="is-icon-button" role="button" data-cmd="new-page"><i class="icon-text-file"></i></a>');
                    sb.push('</div>');    
                    sb.push('</div>');    
                }

                break;

            case 'tuple': {
                    if (!item.value) {
                        item.value = [
                            {"name":"","value":""}
                        ]
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div class="flex-row tuple-container">');

                    item.value.forEach(item => {
                        sb.push('<div>');
                        sb.push('<input type="text" data-type="' + $.default(item.type, 'text') +'" name="' + $.attr_escape(item.name) + '" value="' + $.attr_escape(item.value) + '" />');
                        sb.push('<p class="ux-caption">' + $.default(item.display, item.name) + '</p>');
                        sb.push('</div>');                    
                    })
                    
                    sb.push('</div>');    
                    sb.push('</div>');    
                    sb.push('</div>');  
                }

                break;

            case 'textarea': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    style = $.default(item.style, '');

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<textarea spellcheck="false" style="' + (style!=''?style:'') + '">' + item.value + '</textarea>');
                    sb.push('</div>');    
                }

                break;

            case 'html': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div role="html-editor" data-buttons="bold,italic,strikethrough,unordered-list,ordered-list,divider,color,hilight,icon,link,remove-styles">' + item.value + '</div>');
                    sb.push('</div>');    
                }

                break;

            case 'html-lite': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div role="html-editor" ' + (item.height?('data-height="' + item.height + '"'):'') + ' data-buttons="bold,italic,strikethrough,color,hilight,icon,link,remove-styles">' + item.value + '</div>');
                    sb.push('</div>');
                }

                break;

            case 'dropdown': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<select>');

                    item.options.forEach(option => {
                        sb.push('<option value="' + $.attr_escape(option.value) + '" ' + ((option.value === item.value)?'selected':'') + '>' + option.text + '</options>');    
                    })

                    sb.push('</select>');    
                    sb.push('</div>');     
                }

                break;                

            case 'radio': {
                    if (item.value == null) {
                        item.value = '';
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div class="options">');

                    item.options.forEach(option => {
                        sb.push('<div class="option">');
                        sb.push(option.text + ' <input type="radio" name="' + $.attr_escape(item.name) + '" value="' + $.attr_escape(option.value) + '" ' + (option.value == item.value?'checked':'') + ' />');
                        sb.push('</div>');    
                    })

                    sb.push('</div>');    
                    sb.push('</div>');                        
                }

                break;                

            case 'checkbox': {
                    if (!item.value) {
                        item.value = [];
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div class="options">');

                    item.options.forEach(option => {                    
                        sb.push('<div class="option">');
                        sb.push(option.text + ' <input type="checkbox" value="' + $.attr_escape(option.value) + '" ' + (item.value.includes(option.value)?'checked':'') + ' />');
                        sb.push('</div>');    
                    })

                    sb.push('</div>');    
                    sb.push('</div>');  
                }

                break;                

            case 'switch': {
                if (!item.value) {
                        item.value = [];
                    }

                    sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                    sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');

                    if (item.description) {
                        sb.push('<p class="ux-description"><i class="icon-info"></i> ' + item.description + '</p>');
                    }

                    sb.push('<div class="options">');

                    item.options.forEach(option => {
                        sb.push('<div class="option">');
                        sb.push(option.text + ' <input type="checkbox" value="' + $.attr_escape(option.value) + '" ' + (item.value.includes(option.value)?'checked':'') + ' role="switch" />');
                        sb.push('</div>');    
                    })

                    sb.push('</div>');    
                    sb.push('</div>');
                }

                break;  

            case 'toggle-switch': {
                sb.push('<div class="field" data-name="' + item.name + '" data-editor="' + item.editor +'">');    
                sb.push('<label><i class="icon-arrow-right"></i>' + $.default(item.display, item.name) + '</label>');
                sb.push('<span data-role="toggle-switch" data-value="' + $.default(item.value, 'off') + '"><span class="caption on">ON</span><span class="caption off">OFF</span></span>');
                sb.push('</div>');

                break;
            }              
        }
    }

    return sb.join('');
}

function serialize(field, node) {
    let editor = $.default(field.dataset['editor'], '');
    let name = field.dataset['name'];
    let ux_image_preview, icon_link;

    switch (editor) {
        case 'hidden': {
                node[name] = $.default(field.getAttribute('data-value'), '');
            }

            break;

        case 'textbox': {
                node[name] = field.querySelector('input').value;
            }

            break;

        case 'text': {
                node[name] = {};
                node[name]['text'] = field.querySelector('input').value;
                
                let image = field.querySelector('span.ux-image');

                if (image) {
                    let v_node = JSON.parse($.default(image.getAttribute('data-image'), '{}'));
                    node[name]['image'] = v_node.image;
                }

                let icon = field.querySelector('.ux-icon');

                if (icon) {
                    node[name]['icon'] = $.default(icon.innerText, '');                
                }

                let link = field.querySelector('.ux-link');

                if (link) {
                    node[name]['url'] = $.default(link.getAttribute('data-url'), '');
                }

                let setting = field.querySelector('.ux-setting');

                if (setting) {
                    node[name]['settings'] = JSON.parse($.default(setting.getAttribute('data-settings'), '{}'));
                }
            }

            break;

        case 'table': {
                node[name] = [];

                field.querySelectorAll('.flex-row[data-index]').forEach(row => {
                    let item = {};
                    
                    item.key = row.querySelector('input[name="key"]').value;            
                    item.value = row.querySelector('input[name="value"]').value;            

                    let ux_icon = row.querySelector('.ux-icon');

                    if (ux_icon) {
                        item.icon = $.default(ux_icon.innerText, '');
                    }

                    node[name].push(item);
                })
            }

            break;

        case 'time-table': {
                node[name] = [];

                field.querySelectorAll('.flex-row[data-index]').forEach(row => {
                    let item = {};
                    item.key = row.querySelector('input[name="key"]').value;            
                    item.value = row.querySelector('input[name="value"]').value;            
                    node[name].push(item);
                })
            }

            break;

        case 'list-of-tuple': {
                node[name] = [];

                field.querySelectorAll('.flex-row[data-index]').forEach(row => {
                    let item = {};

                    item.id = row.getAttribute('data-id');
                    item.key = row.querySelector('input[name="key"]').value;            
                    item.value = row.querySelector('input[name="value"]').value;     

                    let image = row.querySelector('span.ux-image');

                    if (image) {
                        let node = JSON.parse($.default(image.getAttribute('data-image'), '{}'));
                        item.image = node.image;
                    }

                    let link = row.querySelector('span.ux-link');

                    if (link) {
                        item.url = $.default(link.getAttribute('data-url'), '');
                    }

                    let setting = row.querySelector('span.ux-setting');

                    if (setting) {
                        item.settings = JSON.parse($.default(setting.getAttribute('data-settings'), '{}'));
                    }

                    node[name].push(item);
                })
            }

            break;

        case 'list-of-text': {
                node[name] = [];

                field.querySelectorAll('.flex-row[data-index]').forEach(row => {
                    let item = {};

                    item.id = row.getAttribute('data-id');
                    item.text = row.querySelector('input').value;            

                    let icon = row.querySelector('span.ux-icon');

                    if (icon) {
                        item.icon = $.default(icon.innerText, '');
                    }

                    let link = row.querySelector('span.ux-link');

                    if (link) {
                        item.url = $.default(link.getAttribute('data-url'), '');
                    }

                    let setting = row.querySelector('span.ux-setting');

                    if (setting) {
                        item.settings = JSON.parse($.default(setting.getAttribute('data-settings'), '{}'));
                    }

                    let image = row.querySelector('span.ux-image');

                    if (image) {
                        let node = JSON.parse($.default(image.getAttribute('data-image'), '{}'));
                        item.image = node.image;
                    }

                    node[name].push(item);
                })
            }

            break;

        case 'image': {
                ux_image_preview = field.querySelector('.ux-image-preview');

                node[name] = {};
                node[name]['x-filename'] = $.default(ux_image_preview.dataset['xFilename'], '');
                node[name]['filename'] = $.default(ux_image_preview.dataset['filename'], '');
                node[name]['width'] = $.default(ux_image_preview.dataset['width'], '');
                node[name]['height'] = $.default(ux_image_preview.dataset['height'], '');
                node[name]['file_size'] = $.default(ux_image_preview.dataset['fileSize'], '');
                node[name]['alt'] = $.default(ux_image_preview.dataset['alt'], '');

                icon_link = ux_image_preview.querySelector('.icon-link');

                if (icon_link) {
                    node[name]['url'] = $.default(icon_link.dataset['url'], '');                
                }
            }

            break;

        case 'textarea': {
                node[name] = field.querySelector('textarea').value;
            }

            break;

        case 'html': {
                let html = field.querySelector('.ux-editor').innerHTML;
                let pattern;

                pattern = /<p><\/p>/g;
                html = html.replaceAll(pattern, '');

                pattern = /<div><\/div>/g;
                html = html.replaceAll(pattern, '');

                pattern = /<div><br><\/div>/g;
                html = html.replaceAll(pattern, '');

                node[name] = html.trim();
            }

            break;

        case 'html-lite': {
                let html = field.querySelector('.ux-editor').innerHTML;
                let pattern;

                pattern = /<p><\/p>/g;
                html = html.replaceAll(pattern, '');

                pattern = /<div><\/div>/g;
                html = html.replaceAll(pattern, '');

                pattern = /<div><br><\/div>/g;
                html = html.replaceAll(pattern, '');

                node[name] = html.trim();
            }

            break;

        case 'url': {
                node[name] = field.querySelector('input').value;
            }

            break;

        case 'tuple': {
                node[name] = {};

                field.querySelectorAll('input').forEach(input => {
                    node[name][input.name] = input.value;
                })
            }

            break;

        case 'radio': {
                if (field.querySelector('input:checked')) {
                    node[name] = field.querySelector('input:checked').value;
                } else {
                    node[name] = '';
                }
            }

            break;

        case 'checkbox': {
                node[name] = [];

                if (field.querySelectorAll('input:checked')) {
                    if (field.querySelectorAll('input:checked')) {
                        field.querySelectorAll('input:checked').forEach(input => {
                            node[name].push(input.value);
                        })
                    }
                }
            }

            break;

        case 'dropdown': {
                node[name] = field.querySelector('select').value;
            }

            break;

        case 'switch': {
                node[name] = [];

                if (field.querySelectorAll('input:checked')) {
                    field.querySelectorAll('input:checked').forEach(input => {
                        node[name].push(input.value);
                    })
                }
            }

            break;

        case 'range': {
                node[name] = field.querySelector('input').value;
            }

            break;

        case 'toggle-switch': {
                node[name] = $.default(field.querySelector('span[data-role="toggle-switch"]').getAttribute('data-value'), 'off');
            }

            break;

        case 'list': {
            node[name] = [];
                field.querySelectorAll('[role="page"]').forEach(page => {
                    let v_node = {};
                    
                    page.querySelectorAll('.field[data-name]').forEach(field => {
                        serialize(field, v_node);
                    })
                
                    node[name].push(v_node);
                })
            }

            break;
    
        case 'color': {
                node[name] = $.default(field.querySelector('.color').getAttribute('data-color'), '');
            }

            break;

        case 'pattern': {
                node[name] = $.default(field.querySelector('.pattern').getAttribute('data-pattern'), '');
            }

            break;
    }

    return node;
}

function edit_item(index, widget, profile = '', data = {}, options = '') {
    return new Promise((resolve, reject) => {
        let schema = 'item-schema.json';
        let schema_node;

        $.get_json('widgets/' + widget + '/item-schema.php?profile=' + profile + '&' + options).then(node => {
            schema_node = node;

            schema_node.forEach(item => {
                let name = item.name;      
                item.value = $.default(data[name], '');    
            })

            const dialog = new ModalDialog({
                id: 'widget-editor',
                width: 880,
                padding: '2.4em'
            })

            dialog.popup.addEventListener('click', evt => {
                let el = evt.target;

                if (el.matches('a[role="button"][data-cmd="save"]')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let popup = el.closest('.popup');
                    let node = {
                    }

                    popup.querySelectorAll('form > .field[data-name]').forEach(field => {
                        serialize(field, node);
                    })

                    dialog.set_cmd('ok');
                    dialog.set_value(node);
                    dialog.close();                        
                } else if (el.matches('.ux-image-preview i.icon-link')) { 
                    evt.preventDefault();
                    evt.stopPropagation();

                    let url = $.default(el.getAttribute('data-url'), '');
                    let url_dialog = new Graphite.Admin.URLDialog(url);

                    url_dialog.get_url().then(data => {
                        if (data.cmd == 'ok') {
                            el.setAttribute('data-url', data.value);
                        }
                    })
                } else if (el.matches('span.ux-link')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let url = $.default(el.getAttribute('data-url'), '');
                    let url_dialog = new Graphite.Admin.URLDialog(url);

                    url_dialog.get_url().then(data => {
                        if (data.cmd == 'ok') {
                            el.setAttribute('data-url', data.value);
                        }
                    })
                } else if (el.matches('span.ux-icon')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let icon_dialog = new IconsDialog();

                    icon_dialog.select_icon().then(data => {
                        if (data.cmd === 'ok') {
                            el.innerText = data.value;
                        }
                    })
                } else if (el.matches(':is([data-cmd="remove-item"])')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let field = el.closest('.field');
                    let editor = field.getAttribute('data-editor');

                    if (['list-of-text','table', 'time-table', 'list-of-tuple'].includes(editor)) {
                        el.closest('.flex-row').remove();
                    }
                } else if (el.matches(':is([data-cmd="new-item"])')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let field = el.closest('.field');
                    let editor = field.getAttribute('data-editor');

                    if (['list-of-text'].includes(editor)) {
                        let new_item = el.closest('.flex-row').cloneNode(true);

                        if (['list-of-text'].includes(editor)) {
                            new_item.querySelector('input').value = '';
                            new_item.setAttribute('data-id', Graphite.short_uid());
                        }

                        el.closest('.flex-row').after(new_item);
                    } else if (['table', 'time-table', 'list-of-tuple'].includes(editor)) {
                        let new_item = el.closest('.flex-row').cloneNode(true);

                        new_item.querySelectorAll('input').forEach(el => {
                            el.value = '';
                        })

                        if (editor === 'list-of-tuple') {
                            new_item.setAttribute('data-id', Graphite.short_uid());
                        }

                        el.closest('.flex-row').after(new_item);                        
                        el.closest('.flex-row').after(new_item);     

                        let input = new_item.querySelector('input[type="text"]');

                        if (input) {
                            input.focus();
                            input.selectionStart = input.selectionEnd = input.value.length;
                        }
                    }
                } 
            })

            let sb = [];

            sb.push('<form>');

            node.forEach(item => {
                sb.push(get_editor(item));
            })

            sb.push('</form>');
            sb.push('<footer class="flex-row center gap-10px">');
            sb.push('<a href="#" role="button" data-cmd="save"><i class="icon-save"></i> SAVE</a>');
            sb.push('<a href="#" role="button" data-cmd="close"><i class="icon-cancel"></i> CANCEL</a>');
            sb.push('</footer>');

            dialog.set(sb.join(''));

            dialog.popup.querySelectorAll('input[type="range"]').forEach(el => {
                let unit = el.dataset['unit'];

                el.nextElementSibling.innerText = el.value + (unit?(' ' + unit):'');

                el.addEventListener('input', evt => {
                    let el = evt.target;
                    el.nextElementSibling.innerText = el.value + (unit?(' ' + unit):'');
                })
            })

            dialog.popup.querySelectorAll('[data-editor="image"]').forEach(el => {
                let text;

                el.querySelectorAll('.ux-image-preview').forEach(el => {
                    let uploader = new ImageUpload(el, $.default(el.getAttribute('data-max-width'), '1020'), $.default(el.getAttribute('data-max-height'), ''));

                    uploader.on('complete', res => {
                        if (res.status === 200) {
                            if (!el.getAttribute('data-x-filename')) {
                                el.setAttribute('data-x-filename', el.getAttribute('data-filename'));                                
                            } 

                            el.querySelector('img').setAttribute('src', res.filename);
                            el.setAttribute('data-width', res.width);
                            el.setAttribute('data-height', res.height);
                            el.setAttribute('data-file-size', res.size);

                            setTimeout(() => {
                                el.setAttribute('data-filename', res.filename);
                            }, 300)

                            let field = el.closest('.field');

                            field.querySelector('.ux-caption').innerText = res.width + ' x ' + res.height + ' (' + $.friendly_file_size(res.size) + ')';                            
                        } else if (res.status === 303) {
                            text = 'ไฟล์รูปภาพมีขนาดใหญ่เกินไป กรุณาลดขนาดไฟล์ลงไม่ให้เกิน ' + Graphite.friendly_file_size(Graphite.config.max_file_size);

                            if (Graphite.config.default_language != 'th') {
                                text = 'Sorry, your file is too large. The maximum file size is ' + Graphite.friendly_file_size(Graphite.config.max_file_size);
                            }

                            Graphite.show_message(Graphite.admin.profile_image, text);
                        }
                    })
                })
            })

            dialog.popup.querySelectorAll('[role="html-editor"]').forEach(el => {
                 new Graphite.Admin.HTMLEditor(el, {
                    buttons: el.dataset['buttons'].split(',')
                 })
            })

            dialog.popup.querySelectorAll('.ux-draggable-list').forEach(el => {
                new DraggableList(el);
            })

            dialog.popup.addEventListener('keydown', evt => {
                if (evt.key === 'Enter') {                    
                    if (evt.target.matches('.ux-editor') || evt.target.matches('textarea')) {

                    } else {
                        evt.preventDefault();
                        evt.stopPropagation();

                        if (evt.target.matches('input[type="text"][name="value"]')) {
                            if (evt.target.closest('[data-editor="list-of-tuple"]')) {
                                let row = evt.target.closest('.flex-row');

                                if (row) {
                                    row.querySelector('i[data-cmd="new-item"]').click();
                                }
                            }
                        } else {
                            dialog.popup.querySelector('a[data-cmd="save"]').click();
                        }
                    }
                }
            })

            dialog.on('open', () => {
                let el = dialog.popup.querySelector(':is(input:not([type="file"]),textarea,.ux-editor)');

                if (el) {
                    el.focus();
                
                    if (el.matches('.ux-editor')) {
                        let range = null;
                        let sel = null;
                        let span = $.el('<span></span>');
                        
                        el.append(span);
                        range = document.createRange();
                        range.selectNode(span);
                        sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                        span.remove();
                    } else if (el.matches('input[type="text"]')) {
                        el.selectionStart = el.selectionEnd = el.value.length;
                    }
                }
            })
            
            resolve(dialog.open());
        })   
    })    
}

function edit(id, widget, profile = '', options = '', reload = 'true') {
    return new Promise((resolve, reject) => {
        let data = '';

        if (id == '100000') {
            data = $.default(Graphite.node_100000, {});
            reload = 'false';
        }

        function setup_event_handlers(root, idx = 1) {
            function update_pages(ux_pages, idx = 1) {
                let field = ux_pages.closest('.field');
                let sb = [];
                let page = 1;
                let pages = field.querySelectorAll('div[role="page"]');
                let active_index = idx;

                pages.forEach(item => {
                    if (page == active_index) {
                        item.classList.add('active');
                    } else {
                        item.classList.remove('active');
                    }

                    item.setAttribute('data-page', page);
                    sb.push('<li><a href="#" class="' + (page==active_index?'active':'') + '" data-page="' + page + '" draggable="true">' + page +'</a></li>');
                    page++;
                })

                ux_pages.innerHTML = sb.join('');
            }

            root.querySelectorAll('.ux-pages').forEach(el => {
                update_pages(el, idx);
            })

            root.querySelectorAll('input[data-type="number"]').forEach(input => {
                Graphite.Admin.make_numeric_input(input);
            })

            root.querySelectorAll('input[data-type="decimal"]').forEach(input => {
                Graphite.Admin.make_decimal_input(input);
            })

            root.querySelectorAll('input[data-type="phone"]').forEach(input => {
                Graphite.Admin.make_phone_input(input);
            })

            root.querySelectorAll('input[type="range"]').forEach(el => {
                let unit = el.dataset['unit'];

                el.nextElementSibling.innerText = el.value + (unit?(' ' + unit):'');

                el.addEventListener('input', evt => {
                    let el = evt.target;
                    el.nextElementSibling.innerText = el.value + (unit?(' ' + unit):'');
                })
            })

            root.querySelectorAll('[data-editor="image"]').forEach(el => {
                let text;

                el.querySelectorAll('.ux-image-preview').forEach(el => {
                    let uploader = new ImageUpload(el, $.default(el.getAttribute('data-max-width'), '1020'), $.default(el.getAttribute('data-max-height'), ''));

                    uploader.on('complete', res => {
                        if (res.status === 200) {
                            if (!el.getAttribute('data-x-filename')) {
                                el.setAttribute('data-x-filename', el.getAttribute('data-filename'));                                
                            } 

                            el.querySelector('img').setAttribute('src', res.filename);
                            el.setAttribute('data-width', res.width);
                            el.setAttribute('data-height', res.height);
                            el.setAttribute('data-file-size', res.size);

                            setTimeout(() => {
                                el.setAttribute('data-filename', res.filename);
                            }, 300)

                            let field = el.closest('.field');

                            field.querySelector('.ux-caption').innerText = res.width + ' x ' + res.height + ' (' + $.friendly_file_size(res.size) + ')';                            
                        } else if (res.status === 303) {
                            text = 'ไฟล์รูปภาพมีขนาดใหญ่เกินไป กรุณาลดขนาดไฟล์ลงไม่ให้เกิน ' + Graphite.friendly_file_size(Graphite.config.max_file_size);

                            if (Graphite.config.default_language != 'th') {
                                text = 'Sorry, your file is too large. The maximum file size is ' + Graphite.friendly_file_size(Graphite.config.max_file_size);
                            }

                            Graphite.show_message(Graphite.admin.profile_image, text);
                        }
                    })
                })
            })

            root.querySelectorAll('[data-role="toggle-switch"]').forEach(el => {
                Graphite.Admin.create_toggle_switch(el);
            })

            root.querySelectorAll('[role="html-editor"]').forEach(el => {
                let options = {
                    buttons: el.dataset['buttons'].split(',')                    
                }
                
                let height = el.getAttribute('data-height');

                if (height) {
                    options.height = height;
                }

                new Graphite.Admin.HTMLEditor(el, options);
            })

            root.querySelectorAll('.ux-draggable-list').forEach(el => {
                if (!el.classList.contains('ux-pages')) {
                    new DraggableList(el);
                }
            })

            root.querySelectorAll('.ux-pages').forEach(el => {
                let draggable_list = new DraggableList(el);

                draggable_list.on('drag-end', (data) => {
                    let fieldset = data.drag_item.closest('fieldset');
                    let x = data.drag_item.getAttribute('data-index');
                    let y = data.drop_target.getAttribute('data-index');

                    if (x < y) {
                        fieldset.querySelector('[role="page"][data-page="' + y + '"]').after(fieldset.querySelector('[role="page"][data-page="' + x + '"]'));
                    } else {
                        fieldset.querySelector('[role="page"][data-page="' + y + '"]').before(fieldset.querySelector('[role="page"][data-page="' + x + '"]'));
                    }

                    let i = 1;

                    fieldset.querySelectorAll('[role="page"]').forEach(el => {
                        el.setAttribute('data-page', i);    
                        el.classList.remove('active');
                        i++;
                    })

                    i = 1;

                    fieldset.querySelectorAll('.ux-pages > li').forEach(li => {
                        li.setAttribute('data-index', i); 
                        let a = li.querySelector('a'); 
                        a.setAttribute('data-page', i); 
                        a.classList.remove('active');  
                        a.innerText = i;
                        i++;
                    })

                    fieldset.querySelector('[role="page"][data-page="' + y + '"]').classList.add('active');
                    fieldset.querySelector('.ux-pages a[data-page="' + y + '"]').classList.add('active');
                })
            })

            root.querySelectorAll('[data-editor="color"] .color').forEach(el_color => {
                el_color.addEventListener('click', evt => {
                    let el = evt.target;
                    let current_color = $.default(el.getAttribute('data-color'), '');

                    evt.preventDefault();
                    evt.stopPropagation();

                    let settings = {};

                    if ((current_color) && (current_color.startsWith('#'))) {
                        settings.color = current_color;
                    }

                    let color_dialog = new Graphite.Admin.ColorDialog(settings); 

                    color_dialog.select_color().then(res => {
                        if (res.cmd == 'ok') {
                            let color = res.value;

                            if (color == 'none') {
                                color = '';
                            }

                            el.setAttribute('data-color', color);
                            el.style.setProperty('--bg-color', color);
                        }
                    })
                })
            })

            root.querySelectorAll('[data-editor="pattern"] .pattern').forEach(el_pattern => {
                el_pattern.addEventListener('click', evt => {
                    let el = evt.target;

                    evt.preventDefault();
                    evt.stopPropagation();

                    Graphite.Admin.select_light_dark().then(res => {
                        if (res.cmd == 'ok') {
                            let mode = res.value;
                            let current_pattern = $.default(el.getAttribute('data-pattern'), '');

                            Graphite.select_pattern(current_pattern, mode).then(res => {
                                if (res.cmd == 'ok') {
                                    if (res.value.src == '') {
                                        el.setAttribute('data-pattern', '');
                                        el.style.setProperty('--bg-image', '');
                                    } else {
                                        el.setAttribute('data-pattern', res.value.src);
                                        el.style.setProperty('--bg-image', 'url(../../../patterns/' + res.value.src + ')');
                                    }
                                }
                            })
                        }
                    })
                })
            })
        }

        $.get_json('process/get-widget-data.php?id=' + id + (id=='100000'?('&data=' + encodeURIComponent(JSON.stringify(data))):'') + '&widget=' + widget + (profile==''?'':('&profile=' + profile)) + (options===''?'':('&' + options))).then(node => {
            const dialog = new ModalDialog({
                id: 'widget-editor',
                width: 880,
                padding: '2.4em'
            })

            dialog.popup.addEventListener('click', evt => {
                let el = evt.target;

                if (el.matches('.ux-pages a')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let page = el.dataset['page'];

                    dialog.popup.querySelectorAll('[role="page"].active').forEach(el => {
                        el.classList.remove('active');
                    })

                    dialog.popup.querySelectorAll('.ux-pages a.active').forEach(el => {
                        el.classList.remove('active');
                    })

                    el = dialog.popup.querySelector('[role="page"][data-page="' + page + '"]');

                    if (el) {
                        el.classList.add('active');
                    }

                    el = dialog.popup.querySelector('.ux-pages a[data-page="' + page  + '"]');

                    if (el) {
                        el.classList.add('active');
                    }
                } else if (el.matches('a[role="button"][data-cmd="save"]')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let popup = el.closest('.popup');
                    let node = {
                        id: id + ''
                    }

                    popup.querySelectorAll('form > .field[data-name]').forEach(field => {
                        serialize(field, node);
                    })

                    let headers = {};
                    let v_widget = document.querySelector('[data-widget="' + widget + '"][data-id="' + id + '"]');

                    if (v_widget) {
                        let parent_cached_name = Graphite.Admin.get_parent_cached_name(v_widget);

                        if (parent_cached_name) {
                            headers['x-parent-cached-name'] = parent_cached_name;
                        }
                    }

                    if (node.id == '100000') {
                        dialog.set_cmd('ok');
                        dialog.set_value(node);
                        dialog.close();
                    } else {
                        $.post_json(Graphite.config.base_url + 'process/save-widget.php', node, headers).then(res => {
                            if (res.status === 200) {
                                dialog.set_cmd('ok');
                                dialog.set_value(node);
                                dialog.close();

                                Graphite.iconify('ok');

                                let content_type = $.default(res.node.content_type, '');

                                if (['page','article','news','product'].includes(content_type) || (['page-metadata'].includes(widget))) {
                                    $(document).trigger($.create_custom_event('metadata.updated', {id: id, metadata: res.node}, false));

                                    if (res.x_nodes) {
                                        res.x_nodes.forEach(id => {
                                            $.get_json(Graphite.config.base_url + 'data/' + id + '.json').then(node => {
                                                $(document).trigger($.create_custom_event('metadata.updated', {id: id, metadata: node}, false));
                                            })                                        
                                        })
                                    }

                                    if (['page-header','product-header'].includes(widget)) {
                                        $(document).trigger($.create_custom_event('widget.updated', {id: id, widget: widget, reload: reload}, false)); 
                                    }
                                } else {
                                    $(document).trigger($.create_custom_event('widget.updated', {id: id, widget: widget, reload: reload}, false)); 
                                }
                            }
                        })
                    }
                } else if (el.matches('.ux-image-preview i.icon-link')) { 
                    evt.preventDefault();
                    evt.stopPropagation();

                    let url = $.default(el.getAttribute('data-url'), '');

                    let url_dialog = new Graphite.Admin.URLDialog(url);

                    url_dialog.get_url().then(data => {
                        if (data.cmd == 'ok') {
                            el.setAttribute('data-url', data.value);
                        }
                    })
                } else if (el.matches('span.ux-link')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    Graphite.node_100000 = {"id":"100000","url":$.default(el.getAttribute('data-url'), '')};

                    Graphite.Admin.edit('100000', 'setting-link').then(res => {
                        if (res.cmd == 'ok') {
                            el.setAttribute('data-url', res.value.url);
                        }
                    })
                } else if (el.matches('span.ux-icon')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let icon_dialog = new IconsDialog();

                    icon_dialog.select_icon().then(data => {
                        if (data.cmd === 'ok') {
                            el.innerText = data.value;
                        }
                    })
                } else if (el.matches('span.ux-setting')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let setting_widget = el.getAttribute('data-setting-widget');

                    Graphite.node_100000 = JSON.parse($.default(el.getAttribute('data-settings'), '{}'));

                    Graphite.Admin.edit('100000', setting_widget).then(res => {
                        if (res.cmd == 'ok') {
                            el.setAttribute('data-settings', JSON.stringify(res.value));
                        }
                    })
                } else if (el.matches('span.ux-image')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let max_width = $.default(el.getAttribute('data-max-width'), '');
                    let max_height = $.default(el.getAttribute('data-max-height'), '');

                    Graphite.node_100000 = JSON.parse($.default(el.getAttribute('data-image'), '{}'));

                    Graphite.Admin.edit('100000', 'setting-image', '', 'max_width=' + max_width + '&max_height=' + max_height).then(res => {
                        if (res.cmd == 'ok') {
                            let filename = $.default(res.value.image.filename, '');

                            el.setAttribute('data-filename', filename);
                            el.setAttribute('data-image', JSON.stringify(res.value));
                        }
                    })
                } else if (el.matches(':is([data-cmd="remove-item"])')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let field = el.closest('.field');
                    let editor = field.getAttribute('data-editor');

                    if (['list-of-text','table','list-of-tuple'].includes(editor)) {
                        el.closest('.flex-row').remove();
                    } else if (['list'].includes(editor)) {
                        let field = el.closest('.field');
                        let p = field.querySelector('div[role="page"].active');

                        if (p) {
                            let idx = parseInt(p.getAttribute('data-page'));
                            let total_pages = field.querySelectorAll('div[role="page"]').length  - 1;

                            if (total_pages < 1) {
                                let text = 'ไม่สามารถลบข้อมูลได้ เนื่องจากข้อมูลประเภท List Items จะต้องมีอย่างน้อย 1 รายการ';

                                if (Graphite.config.default_language != 'th') {
                                    text = 'Can\'t remove this item. List Items should have 1 item at least.';
                                }

                                Graphite.show_message(Graphite.admin.profile_image, text);
                            } else {
                                if (idx > total_pages) {
                                    idx = total_pages;
                                }

                                $(p).fadeOut(400, () => {
                                    p.remove();
                                    
                                    field = Graphite.remove_all_events(field);
                                    setup_event_handlers(field, idx);
                                })
                            }
                        }
                    }
                } else if (el.matches(':is([data-cmd="new-item"])')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let field = el.closest('.field');
                    let editor = field.getAttribute('data-editor');

                    if (['list-of-text'].includes(editor)) {
                        let new_item = el.closest('.flex-row').cloneNode(true);

                        new_item.querySelector('input').value = '';
                        new_item.setAttribute('data-id', Graphite.short_uid());

                        let settings = new_item.querySelector('span.ux-setting');

                        if (settings) {
                            settings.setAttribute('data-settings', '{}');
                        }

                        let ux_icon = new_item.querySelector('.ux-icon');

                        if (ux_icon) {
                            ux_icon.innerText = '';
                        }

                        el.closest('.flex-row').after(new_item);
                    } else if (['table','list-of-tuple'].includes(editor)) {
                        let new_item = el.closest('.flex-row').cloneNode(true);

                        new_item.querySelectorAll('input').forEach(el => {
                            el.value = '';
                        })

                        let ux_icon = new_item.querySelector('.ux-icon');

                        if (ux_icon) {
                            ux_icon.innerText = '';
                        }

                        if (editor === 'list-of-tuple') {
                            new_item.setAttribute('data-id', Graphite.short_uid());
                        }

                        el.closest('.flex-row').after(new_item);     

                        let input = new_item.querySelector('input[type="text"]');

                        if (input) {
                            input.focus();
                            input.selectionStart = input.selectionEnd = input.value.length;
                        }
                    } else if (['list'].includes(editor)) {
                        let field = el.closest('.field');
                        let t = field.querySelector('template');                        
                        
                        if (t) {
                            let new_page = $.el('<div role="page"></div>');

                            new_page.innerHTML = t.innerHTML;

                            let pages = field.querySelectorAll('div[role="page"]');

                            if (pages.length > 0) {
                                let last_page = pages[pages.length - 1];
                                let idx = parseInt(last_page.getAttribute('data-page')) + 1;

                                last_page.after(new_page);
                                field = Graphite.remove_all_events(field);
                                setup_event_handlers(field, idx);
                            }
                        }
                    }
                } else if (el.matches('[data-cmd="new-page"]')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let dialog = new Graphite.Admin.PageTypeDialog();

                    dialog.select().then(res => {
                        if (res.cmd == 'ok') {
                            let page_type = res.value;

                            Graphite.Admin.create_new_page(page_type, 'page').then(id => {
                                let field = el.closest('.field');
                                let input = field.querySelector('input');

                                if (input) {
                                    input.value = 'page/' + id;
                                }
                            })
                        }
                    })
                }
            })

            let sb = [];

            sb.push('<header>');
            sb.push('<div>');
            sb.push('<h1 class="ux-widget-id">' + id + '</h1>');
            sb.push('<p class="ux-last-updated"><i class="icon-clock"></i> ' + node.last_updated + '</p>');
            sb.push('</div>');
            sb.push('</header>');
            sb.push('<form>');

            $.default(node.items, []).forEach(item => {
                sb.push(get_editor(item));
            })

            sb.push('</form>');
            sb.push('<footer class="flex-row center gap-10px">');

            if (id == '100000') {
                sb.push('<a draggable="false" href="#" role="button" data-cmd="save"><i class="icon-checked"></i> OK</a>');
            } else {
                sb.push('<a draggable="false" href="#" role="button" data-cmd="save"><i class="icon-save"></i> SAVE</a>');
            }

            sb.push('<a draggable="false" href="#" role="button" data-cmd="close"><i class="icon-cancel"></i> CANCEL</a>');
            sb.push('</footer>');

            dialog.set(sb.join(''));
            setup_event_handlers(dialog.popup.querySelector('form'), 1);

            dialog.popup.addEventListener('keydown', evt => {
                if (evt.key === 'Enter') {                    
                    if (evt.target.matches('.ux-editor') || evt.target.matches('textarea')) {

                    } else {
                        evt.preventDefault();
                        evt.stopPropagation();

                        dialog.popup.querySelector('a[data-cmd="save"]').click();
                    }
                }
            })

            dialog.on('open', () => {
                let el = dialog.popup.querySelector(':is(input:not([type="file"]),textarea,.ux-editor)');

                if (el) {
                    el.focus();
                
                    if (el.matches('.ux-editor')) {
                        let range = null;
                        let sel = null;
                        let span = $.el('<span></span>');
                        
                        el.append(span);
                        range = document.createRange();
                        range.selectNode(span);
                        sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                        span.remove();
                    } else if (el.matches('input[type="text"]')) {
                        el.selectionStart = el.selectionEnd = el.value.length;
                    }
                }
            })

            resolve(dialog.open());
        })
    })
}

export { edit, edit_item };