import ModalDialog from "../../modal-dialog/index.js";

class URLDialog {
    constructor(url = '') {
        this.dialog = new ModalDialog({
            id: 'url-dialog',
            width: 720,
            padding: '2em'
        })
    
        let sb = [];
        
        sb.push('<form>');
        sb.push('<div class="field">');
        sb.push('<label>URL</label>');
        sb.push('<div class="container">');
        sb.push('<input type="text" name="url" value="' + url + '"></input>');
        sb.push('<a href="#" role="button" class="is-icon-button" data-cmd="new-page"><i class="icon-text-file"></i></a>');
        sb.push('</div>');
        sb.push('</div>');
        sb.push('</form>');

        this.dialog.set(sb.join('') + '<footer><a href="#" role="button" data-cmd="ok"><i class="icon-checked"></i> OK</a><a href="#" role="button" data-cmd="close"><i class="icon-close"></i> CLOSE</a></footer>');
 
        this.dialog.popup.addEventListener('keydown', evt => {
            if (evt.key === 'Enter') {
                evt.preventDefault();
                evt.stopPropagation();

                let url = this.dialog.popup.querySelector('input[name="url"]').value;

                this.dialog.set_cmd('ok');
                this.dialog.set_value(url);
                this.dialog.close();
            }
        })

        this.dialog.on('open', () => {
            this.dialog.popup.addEventListener('click', evt => {
                let el = evt.target;

                if (el.matches('a[data-cmd="new-page"]')) {
                    evt.preventDefault();
                   
                    let loading = new Graphite.Loading();

                    if (Graphite.user_permissions.includes('#main-page#')) {
                        let _dlg = new Graphite.Admin.PageTypeDialog();

                        _dlg.select().then(data => {
                            if (data.cmd == 'ok') {
                                loading.show().then(() => {
                                    Graphite.Admin.create_new_page(data.value).then(id => {
                                        let input = this.dialog.popup.querySelector('input[name="url"]');
                                        input.value = 'page/' + id;    
                                        input.focus();
                                    }).finally(() => {
                                        loading.hide();
                                    })                                    
                                })
                            }
                        })
                    } else {
                        loading.show().then(() => {
                            Graphite.Admin.create_new_page('1').then(id => {
                                let input = this.dialog.popup.querySelector('input[name="url"]');
                                input.value = 'page/' + id;    
                                input.focus();
                            }).finally(() => {
                                loading.hide();
                            })                            
                        })
                    }
                } else if (el.matches('a[data-cmd="ok"]')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let url = this.dialog.popup.querySelector('input[name="url"]').value;

                    this.dialog.set_cmd('ok');
                    this.dialog.set_value(url);
                    this.dialog.close();                    
                }
            })

            let input = this.dialog.popup.querySelector('input[name="url"]');

            setTimeout(() => {
                input.selectionStart = input.selectionEnd = input.value.length; 
                input.focus();
            }, 10)
        })
    }

    get_url = () => {
        return this.dialog.open();
    }
}

export default URLDialog;