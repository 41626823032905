function create_admin_toolbar() {
    if (document.body.classList.contains('login')) return;
    if (document.body.classList.contains('auto-login')) return;
    if (document.body.classList.contains('access-denied')) return;

    let toolbar = document.createElement('div');
    let sb = [];

    sb.push('<a draggable="false" href="home" title="Home"><i class="icon-home"></i></a>');
    sb.push('<a draggable="false" href="admin" title="ระบบสำหรับ Admin"><i class="icon-user"></i></a>');
    // sb.push('<a draggable="false" href="pages" title="ระบบจัดการ Pages"><i class="icon-text-file"></i></a>');

    if (Graphite.check_permission('#shopping#')) {
        sb.push('<a draggable="false" href="products" title="ระบบจัดการสินค้า"><i class="icon-gift"></i></a>');

        if (Graphite.check_permission('#shopping-cart#')) {
            sb.push('<a draggable="false" href="orders" title="ใบสั่งซื้อสินค้า"><i class="icon-list"></i></a>');
        }
    }

    if (Graphite.check_permission('#articles#')) {
        sb.push('<a draggable="false" href="articles" title="ระบบจัดการบทความ"><i class="icon-book"></i></a>');
    }

    if (Graphite.check_permission('#news#')) {
        sb.push('<a draggable="false" href="news" title="ระบบจัดการข่าว"><i class="icon-news"></i></a>');
    }

    if (Graphite.check_permission('#personal-message#')) {
        sb.push('<a draggable="false" href="inbox" title="Inbox"><i class="icon-envelope"></i></a>');
    }

    sb.push('<a draggable="false" href="#" data-cmd="edit-mode" title="เข้าสู่โหมดแก้ไข"><i class="icon-enter"></i></a>');
    sb.push('<a draggable="false" href="#" data-cmd="live-mode" title="ออกจากโหมดแก้ไข"><i class="icon-enter flip-x"></i></a>');

    if (Graphite.user_permissions.includes('#theme#')) {
        sb.push('<a draggable="false" href="#" data-cmd="light-mode" title="Light Mode"><i class="icon-sun"></i></a>');
        sb.push('<a draggable="false" href="#" data-cmd="dark-mode" title="Dark Mode"><i class="icon-moon"></i></a>');
    }

    sb.push('<a draggable="false" href="admin/about" title="เกี่ยวกับระบบ"><i class="icon-info-alt-d"></i></a>');
    sb.push('<a draggable="false" href="#" data-cmd="logout" title="ออกจากระบบ"><i class="icon-power-off"></i></a>');

    toolbar.classList.add('admin-toolbar');
    toolbar.innerHTML = sb.join('')
    document.body.appendChild(toolbar); 
}

export default create_admin_toolbar;