let icons = [
    '&#xE000','&#xE001','&#xE003','&#xE004','&#xE005','&#xE006','&#xE009','&#xE00A','&#xE00B','&#xE00C','&#xE00E',
    '&#xE00F','&#xE010','&#xE011','&#xE016','&#xE01B','&#xE01C','&#xE01D','&#xE01F','&#xE022','&#xE023','&#xE024',
    '&#xE025','&#xE026','&#xE028','&#xE029','&#xE02A','&#xE02D','&#xE02E','&#xE02F','&#xE030','&#xE031','&#xE032',
    '&#xE033','&#xE034','&#xE035','&#xE036','&#xE037','&#xE038','&#xE039','&#xE03A','&#xE03B','&#xE03D','&#xE03E',
    '&#xE03F','&#xE040','&#xE041','&#xE042','&#xE043','&#xE04B','&#xE04F','&#xE050','&#xE052','&#xE054','&#xE055',
    '&#xE056','&#xE058','&#xE059','&#xE05A','&#xE05B','&#xE05C','&#xE05D','&#xE060','&#xE061','&#xE063','&#xE064',
    '&#xE065','&#xE066','&#xE067','&#xE069','&#xE06A','&#xE06B','&#xE06C','&#xE06D','&#xE06E','&#xE070','&#xE073',
    '&#xE074','&#xE076','&#xE077','&#xE078','&#xE079','&#xE07B','&#xE080','&#xE081','&#xE083','&#xE084','&#xE086',
    '&#xE087','&#xE08B','&#xE08C','&#xE08E','&#xE08F','&#xE091','&#xE094','&#xE095','&#xE097','&#xE099','&#xE09B',
    '&#xE09C','&#xE09D','&#xE09F','&#xE0A0','&#xE0A2','&#xE0A3','&#xE0A4','&#xE0A6','&#xE0A7','&#xE0A8','&#xE0A9',
    '&#xE0AA','&#xE0AF','&#xE0B0','&#xE0B1','&#xE0B2','&#xE0B3','&#xE0B4','&#xE0B6','&#xE0E4','&#xE0E5','&#xE0E6',
    '&#xE0ED','&#xE102','&#xE103','&#xE106','&#xE108','&#xE109','&#xE10C','&#xE10D','&#xE10E','&#xE10F','&#xE110',
    '&#xE112','&#xE113','&#xE114','&#xE115','&#xE117','&#xE118','&#xE119','&#xE11A','&#xE11B','&#xE11C','&#xE11E',
    '&#xE11F','&#xE121','&#xE122','&#xE123','&#xE126','&#xE127','&#xE12D','&#xE12E','&#xE12F','&#xE130','&#xE131',
    '&#xE132','&#xE123','&#xE134','&#xE135','&#xE136','&#xE137','&#xE138','&#xE139','&#xE13A','&#xE13B','&#xE13C',
    '&#xE13D','&#xE13E','&#xE13F','&#xE140','&#xE144','&#xE145','&#xE146','&#xE148','&#xE149','&#xE14A','&#xE14B',
    '&#xE14B','&#xE14C','&#xE14D','&#xE14E','&#xE150','&#xE151','&#xE152','&#xE154','&#xE155','&#xE156',
    '&#xE158','&#xE15B','&#xE15C','&#xE15D','&#xE15E','&#xE15F','&#xE160','&#xE161','&#xE162','&#xE163','&#xE164',
    '&#xE165','&#xE166','&#xE167','&#xE168','&#xE169','&#xE16A','&#xE16B','&#xE16C','&#xE16D','&#xE16E','&#xE16F',
    '&#xE170','&#xE171','&#xE172','&#xE173','&#xE174','&#xE175','&#xE176','&#xE177','&#xE178','&#xE179','&#xE17A',
    '&#xE17B','&#xE17C','&#xE17D','&#xE17E','&#xE17F','&#xE180','&#xE181','&#xE182','&#xE183','&#xE184','&#xE185',
    '&#xE186','&#xE187','&#xE188','&#xE189','&#xE18A','&#xE18B','&#xE18C','&#xE18D','&#xE18E','&#xE18F','&#xE190',
    '&#xE191','&#xE192','&#xE193','&#xE194','&#xE195','&#xE196','&#xE197','&#xE198','&#xE199','&#xE19A','&#xE19B',
    '&#xE19C','&#xE19D','&#xE19E','&#xE19F','&#xE1A0','&#xE1A1','&#xE1A2','&#xE1A3','&#xE1A4','&#xE1A5','&#xE1A6',
    '&#xE1A7','&#xE1A8','&#xE1A9','&#xE1AA','&#xE1AB','&#xE1AC','&#xE1AD','&#xE1AE','&#xE1AF','&#xE1B0','&#xE1B1',
    '&#xE1B2','&#xE1B3','&#xE1B4','&#xE1B5','&#xE1B6','&#xE1B7','&#xE1B8','&#xE1B9','&#xE1BA','&#xE1BB','&#xE1E4',
    '&#xE1E5','&#xE1E6','&#xE1E7','&#xE1E9','&#xE1EA','&#xE1EB','&#xE1EC','&#xE1ED','&#xE1EE','&#xE1F0','&#xE1F1',
    '&#xE1F2','&#xE1F3','&#xE1F4','&#xE1F5','&#xE1F6','&#xE1F7','&#xE1F8','&#xE1F9','&#xE1FE','&#xE1FF','&#xE200',
    '&#xE201','&#xE202','&#xE203','&#xE204','&#xE205','&#xE206','&#xE207','&#xE208','&#xE209','&#xE20A','&#xE20B',
    '&#xE20C','&#xE20D','&#xE20F','&#xE210','&#xE211','&#xE213','&#xE214','&#xE215','&#xE216','&#xE217','&#xE218',
    '&#xE219','&#xE21A','&#xE21B','&#xE21C','&#xE21D','&#xE21E','&#xE21F','&#xE220','&#xE221','&#xE222','&#xE223',
    '&#xE224','&#xE225','&#xE1C4','&#xE1C5','&#xE1C6','&#xE1C7','&#xE1CD','&#xE1CE','&#xE1CF','&#xE1D1','&#xE1D2','&#xE1DC'
]

export default icons;