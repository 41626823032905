import ModalDialog from "../../modal-dialog/index.js";

function wechat_dialog(url) {            
    const dialog = new ModalDialog({
        id: 'wechat-dialog',
        width: 380,
        padding: '2em',
        transition_duration: 200,
        delay_before_show: 0
    })

    const sb = [];

    sb.push('<div class="flex-column ai-center">');
    sb.push('<div class="ux-image-container" data-role="placeholder"><img width="164" heihgt="164" src="' + Graphite.config.base_url + 'qr?url=' + encodeURIComponent(url) + '"/></div>');
    sb.push('<p class="flex-1">สแกน QR เพื่อ Chat</p>');
    sb.push('</div>');
    sb.push('<footer class="flex-row center mt-30px">');
    sb.push('<a href="#" role="button" data-cmd="close"><i class="icon-close"></i> Close</a>');
    sb.push('</footer>');

    dialog.set(sb.join(''));

    Graphite.preload_images(dialog.popup).then(() => {
        dialog.open();        
    })
}

export default wechat_dialog;