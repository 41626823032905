export default class ImageUpload {
    constructor(el, max_width = '1020', max_height = '') {
        this.el = el;
        this.max_width = max_width;
        this.max_height = max_height;
        this.handlers = {};
        this.support_types = ['image/jpeg','image/png','image/gif'];

        if (Graphite.user_permissions.includes('#webp#')) {
            this.support_types.push('image/webp');
        }

        let input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept','image/jpeg,image/png,image/gif' + (Graphite.user_permissions.includes('#webp#')?',image/webp':''));

        input.addEventListener('change', evt => {
            let input = evt.target;
            let files = input.files;
            let text;

            if (files.length > 0) {
                let file = files.item(0);

                if (!this.support_types.includes(file.type)) {
                    let arr = file.name.split('.');
                    let file_type = '';

                    if (arr.length === 2) {
                        file_type = arr[1];
                    }

                    text = 'ระบบไม่รองรับไฟล์รูปภาพชนิด (${file_type}) ' + Graphite.get_ending();

                    if (Graphite.config.default_language != 'th') {
                        text = 'File type (${file_type}) is not supported!';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, $.parse(text, {file_type: file_type}));
                    return;
                }

                let loading = new Graphite.Loading();

                loading.show().then(() => {
                    this.upload(file, {"filename": file.name, "max_width": this.max_width, "max_height": this.max_height}).then(res => {
                        if (this.handlers['complete']) {
                            this.handlers['complete'](res);
                        }
                    }).finally(() => {
                        loading.hide();
                    })                    
                })
            }            
        })

        this.el.append(input);

        let toolbar = document.createElement('div');

        toolbar.classList.add('ux-toolbar');


        let i;

        i = document.createElement('i');
        i.classList.add('icon-cloud-upload');
        i.setAttribute('title', 'Upload');
        
        i.addEventListener('click', evt => {
            input.click();
        })

        toolbar.append(i);

        i = document.createElement('i');
        i.classList.add('icon-tag');
        i.setAttribute('title', 'ALT');
        
        i.addEventListener('click', evt => {
            let el = evt.target;
            let image_preview = el.closest('.ux-image-preview');

            Graphite.node_100000 = {
                "id": "100000",
                "alt": image_preview.getAttribute('data-alt')
            }

            Graphite.Admin.edit('100000', 'setting-alt', '', '', false).then(res => {
                if (res.cmd == 'ok') {
                    let alt = $.default(res.value.alt, '');

                    image_preview.setAttribute('data-alt', alt);
                }
            })
        })

        toolbar.append(i);

        i = document.createElement('i');
        i.classList.add('icon-bin');
        i.setAttribute('title', 'Remove Image');

        i.addEventListener('click', evt => {
            let text = 'กรุณาคลิ้กปุ่ม "OK" เพื่อ "ยืนยัน" การลบรูปนี้ หรือ คลิ้กปุ่ม "Cancel" เพื่อ "ยกเลิก"';

            if (Graphite.config.default_language != 'th') {
                text = 'Please click "OK" if you want to "Remove" this image, or click "Cancel" to abort';
            }

            Graphite.confirm(Graphite.admin.profile_image, text).then(res => {
                if (res.cmd == 'ok') {
                    if (!this.el.getAttribute('data-x-filename')) {
                        if (this.el.getAttribute('data-filename')) {
                            if (this.el.getAttribute('data-filename') != '') {
                                this.el.setAttribute('data-x-filename', this.el.getAttribute('data-filename'));
                            }
                        }
                    }

                    this.el.setAttribute('data-filename', '');
                    this.el.setAttribute('data-width', '');
                    this.el.setAttribute('data-height', '');
                    this.el.setAttribute('data-file-size', '');
                }
            })
        })

        toolbar.append(i);
        this.el.append(toolbar);

        this.el.addEventListener('dragover', (evt) => {
            evt.preventDefault();
        })

        this.el.addEventListener('drop', (evt) => {
            evt.preventDefault();

            let x = evt.layerX;
            let y = evt.layerY;
            let text;

            if (evt.dataTransfer.items && (evt.dataTransfer.items.length > 0)) {
                let item = evt.dataTransfer.items[0];
                const file = item.getAsFile();

                if (!this.support_types.includes(file.type)) {
                    let arr = file.name.split('.');
                    let file_type = '';

                    if (arr.length === 2) {
                        file_type = arr[1];
                    }

                    text = 'ระบบไม่รองรับไฟล์รูปภาพชนิด (${file_type}) ' + Graphite.get_ending();

                    if (Graphite.config.default_language != 'th') {
                        text = 'File type (${file_type}) is not supported!';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, $.parse(text, {file_type: file_type}));
                    return;
                }

                this.upload(file, {"filename": file.name, "max_width": this.max_width, "max_height": this.max_height}).then(res => {
                    if (this.handlers['complete']) {
                        this.handlers['complete'](res);
                    }
                })
            }
        })
    }

    upload(file, data) {
        return new Promise(async (resolve) => {
            let url = 'process/upload.php';
            let form_data = new FormData();

            form_data.append('file', file);
            
            Object.keys(data).forEach(key => {
                form_data.append(key, data[key]);
            })

            let request = new XMLHttpRequest();

            request.onload = () => {
                return resolve(JSON.parse(request.responseText))
            }

            request.upload.addEventListener('progress', (e) => {
                let file_size = file.size;

                if (e.loaded <= file_size) {
                    let percent = Math.round(e.loaded / file_size * 100);
                    
                    if (this.handlers['progress']) {
                        this.handlers['progress']({filename: file.name, size: file.size, loaded: e.loaded,  percent: percent});
                    }
                } 

                if (e.loaded == e.total) {
                    if (this.handlers['progress']) {
                        this.handlers['progress']({filename: file.name, size: file.size, loaded: e.loaded,  percent: 100});
                    }
                }
            })

            request.open('post', url);
            request.timeout = 45000;
            request.send(form_data);

            if (this.handlers['begin']) {
                this.handlers['begin']({filename: file.name, size: file.size});
            }
        })
    }

    on(event, handler) {
        if (typeof handler === 'function') {
            this.handlers[event] = handler;
        }
    }
}