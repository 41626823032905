import ModalDialog from "../../modal-dialog/index.js";

class MenuItemSettingsDialog {
    constructor(url = '') {
        this.dialog = new ModalDialog({
            id: 'menu-item-settings-dialog',
            width: 580,
            padding: '2em'
        })
    
        let sb = [];
        
        sb.push('<div class="table">');
        sb.push('<div class="row label"><div></div><div>Normal</div><div>Hover</div></div>');
        sb.push('<div class="row"><div><i class="icon-arrow-right"></i> Icon </div><div><span class="color" data-name="menu_item_icon_color" data-color="" /></div><div><span class="color" data-name="menu_item_icon_color_hover" data-color="" /></div></div>');
        sb.push('<div class="row"><div><i class="icon-arrow-right"></i> Text </div><div><span class="color" data-name="menu_item_text_color" data-color="" /></div><div><span class="color" data-name="menu_item_text_color_hover" data-color="" /></div></div>');
        sb.push('<div class="row"><div><i class="icon-arrow-right"></i> Menu Item Background </div><div><span class="color" data-name="menu_item_bg_color" data-color="" /></div><div><span class="color" data-name="menu_item_bg_color_hover" data-color="" /></div></div>');
        sb.push('</div>');

        this.dialog.set(sb.join('') + '<footer class="flex-row center"><a href="#" role="button" data-cmd="ok"><i class="icon-checked"></i> OK</a><a href="#" role="button" data-cmd="close"><i class="icon-close"></i> CLOSE</a></footer>');
 
        this.dialog.on('open', () => {
            this.dialog.popup.querySelectorAll('span.color').forEach(el => {
                let name = $.default(el.getAttribute('data-name'), '');
                let color = $.default(this.node[name], '');

                el.setAttribute('data-color', color);
                el.style.setProperty('--bg-color', color);
            })

            this.dialog.popup.addEventListener('click', evt => {
                let el = evt.target;

                if (el.matches('span.color')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    let settings = {};
                    let current_color = $.default(el.getAttribute('data-color'));                    
                    let color_dialog = new Graphite.Admin.ColorDialog(settings); 


                    if (current_color && current_color.startsWith('#')) {
                        settings.color = current_color;
                    }

                    color_dialog.select_color().then(res => {
                        if (res.cmd == 'ok') {
                            let  color = res.value;

                            if (color == 'none') {
                                color = '';
                            }

                            el.setAttribute('data-color', color);
                            el.style.setProperty('--bg-color', color);
                        }
                    })
                } else if (el.matches('[data-cmd="ok"]')) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    this.serialize().then(node => {
                        this.dialog.set_cmd('ok');
                        this.dialog.set_value(node);
                        this.dialog.close();                                            
                    })
                }
            })
        })
    }

    serialize = () => new Promise(resolve => {
        let node = {};

        this.dialog.popup.querySelectorAll('.table span.color').forEach(el => {
            let name = el.getAttribute('data-name');
            let color = el.getAttribute('data-color');

            node[name] = color; 
        })

        resolve(node);
    })

    open = (node = null) => {
        this.node = node;
        return this.dialog.open();
    }
}

export default MenuItemSettingsDialog;