import ModalDialog from "../../modal-dialog/index.js";
import default_icons from "./icons.js";

class IconsDialog {
    constructor(options = {}) {
        const _default = {
            icons: default_icons
        }

        this.options = Object.assign(_default, options);

        this.dialog = new ModalDialog({
            id: 'icons-dialog',
            width: 824,
            padding: '2em'
        })
    
        let sb = [];
        
        this.options.icons.forEach(item => {
            sb.push('<span data-value="' + item +'">' + item +'</span>');
        })

        this.dialog.set('<div class="ux-icons-picker">' + sb.join('') + '</div><footer><a href="#" role="button" data-cmd="close"><i class="icon-close"></i> CLOSE</footer>');
 
        this.dialog.popup.addEventListener('click', evt => {
            if (evt.target.matches('span')) {
                let icon = evt.target.innerText;

                if (icon == '') {
                    icon = '';
                }

                console.log(icon);

                this.dialog.set_cmd('ok');                
                this.dialog.set_value(icon);
                this.dialog.close();
            }
        })
    }

    select_icon = () => {
        return this.dialog.open();
    }
}

export default IconsDialog;