class DraggableList {
    constructor(el) {
        this.id = (new Date()).getTime() + '';
        this.el = el;
        this.el.classList.add('ux-draggable-list');
        this.draggedItem = null;
        this.is_dragging = false;
        this.hash = '';
        this.handlers = {};
        this.allow_drag = true;

        this.index();

        let x, y;

        if (!this.el.closest('dialog[data-id="widget-editor')) {
            /*
            this.el.addEventListener('mouseenter', (evt) => {
                if (!this.allow_drag) return;

                evt.stopPropagation();
                if (evt.explicitOriginalTarget.matches) {    
                    if (evt.explicitOriginalTarget.matches('[draggable]')) {
                        if (evt.explicitOriginalTarget.closest('.ux-draggable-list') === this.el) {
                            evt.explicitOriginalTarget.closest(':is(.flex-row,li)').classList.add('dashed');
                        }
                    }
                }
            })

            this.el.addEventListener('mouseleave', (evt) => {
                if (!this.allow_drag) return;

                evt.stopPropagation();
                this.el.querySelectorAll('.dashed').forEach(el => {
                    el.classList.remove('dashed');
                })
            })
            */
        }

        this.el.addEventListener("dragstart", (evt) => {
            if (!this.allow_drag) return;

            evt.stopPropagation();

            this.hash = this.get_hash();
            this.is_dragging = true;
            this.draggedItem = evt.target.closest(':is(.flex-row,li,tr)');

            setTimeout(() => {
                this.el.classList.add('dragging');
            }, 0);
        })

        this.el.addEventListener("dragover", (evt) => {
            if (!this.allow_drag) return;
            if (!this.is_dragging) return;

            x = evt.clientX;
            y = evt.clientY;

            if (evt.target.matches(':is(.flex-row,li,tr)[data-index][data-draggable-id="' + this.id + '"]')) {
                evt.preventDefault();
                evt.stopPropagation();

                if (!evt.target.classList.contains('dashed')) {
                    this.el.querySelectorAll('& > [data-index]').forEach(el => {
                        el.classList.remove('dashed');
                    })

                    evt.target.classList.add('dashed');
                }
            } else {
                return false;
            }
        })

        this.el.addEventListener("dragend", (evt) => {
            if (!this.allow_drag) return;
            if (!this.is_dragging) return;

            setTimeout(() => {
                this.el.classList.remove('dragging');

                let drop_target = document.elementFromPoint(x, y).closest('li[data-index][data-draggable-id="' + this.id + '"]');

                if (!drop_target) {
                    drop_target = document.elementFromPoint(x, y).closest(':is(div,tr)[data-index][data-draggable-id="' + this.id + '"]');                    
                }

                if (drop_target) {
                    let arr = Array.prototype.slice.call(drop_target.parentElement.children);

                    if (arr.indexOf(this.draggedItem) > arr.indexOf(drop_target)) {
                        drop_target.before(this.draggedItem);
                    } else {
                        drop_target.after(this.draggedItem);   
                    }
                }

                setTimeout(() => {
                    this.el.querySelectorAll('& > [data-index]').forEach(el => {
                        el.classList.remove('dashed');
                    })                    
                }, 10)

                if (this.get_hash() !== this.hash) {
                    if (typeof this.handlers['change'] === 'function') {
                        this.handlers['change']({drag_item:this.draggedItem, drop_target: drop_target});
                    }
                }

                if (typeof this.handlers['drag-end'] === 'function') {
                    this.handlers['drag-end']({drag_item:this.draggedItem, drop_target: drop_target});
                }

                this.draggedItem.classList.remove('dragging');
                this.draggedItem = null;
                this.is_dragging = false;
            }, 10);
        })
    }

    index() {
        let i = 1;
        this.el.querySelectorAll('& > :is(.flex-row,li,tr)').forEach(el => {
            el.setAttribute('data-index', i);
            el.setAttribute('data-draggable-id', this.id);
            i++;
        })        
    }

    disable() {
        this.allow_drag = false;
        this.el.classList.add('disabled');
    }

    enable() {
        this.allow_drag = true;
        this.el.classList.remove('disabled');
    }

    get_hash() {
        const sb = [];

        this.el.querySelectorAll('& > :is(.flex-row,li,tr)').forEach(el => {
            sb.push(el.getAttribute('data-index'));
        })

        return sb.join('');
    }

    on(event, handler) {
        this.handlers[event] = handler;
    }
}

export default DraggableList;