import ModalDialog from "../../modal-dialog/index.js";

function line_dialog(url, mode = 'chat') {            
    const dialog = new ModalDialog({
        id: 'line-dialog',
        width: 380,
        padding: '2em',
        transition_duration: 200,
        delay_before_show: 0
    })

    const sb = [];

    sb.push('<div class="flex-column ai-center">');
    sb.push('<div class="ux-image-container" data-role="placeholder"><img width="201" heihgt="201" src="' + Graphite.config.base_url + 'qr?url=' + encodeURIComponent(url) + '"/></div>');

    let text = '';

    switch (mode) {
        case 'chat':
            text = 'สแกน QR เพื่อ Chat';
            break;

        case 'inquiry':
            text = 'สแกน QR เพื่อสอบถามข้อมูล';
            break;

        case 'buy-now':
            text = 'สแกน QR เพื่อสั่งซื้อ';
            break;
    }

    sb.push('<p class="flex-1">' + text + '</p>');
    sb.push('</div>');
    sb.push('<footer class="flex-row center mt-30px">');
    sb.push('<a href="#" role="button" data-cmd="close"><i class="icon-close"></i> Close</a>');
    sb.push('</footer>');

    dialog.set(sb.join(''));

    Graphite.preload_images(dialog.popup).then(() => {
        dialog.open();        
    })
}

export default line_dialog;