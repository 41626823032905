export default function iconify(status = 'ok') {
    let icons = {
        'ok': '<svg viewBox="0 -0.5 17 17" xmlns="http://www.w3.org/2000/svg" class="si-glyph si-glyph-checked"><path d="M3.432 6.189a1 1 0 0 1 1.415 0L6.968 8.31l6.179-6.179a.99.99 0 0 1 1.401.013l2.122 2.122a.991.991 0 0 1 .014 1.4l-9.022 9.021a.99.99 0 0 1-1.401-.014l-4.95-4.95a.998.998 0 0 1 0-1.413l2.121-2.121Z" fill="currentColor" class="si-glyph-fill" fill-rule="evenodd"/></svg>'
    }

    let el = $.el('<div class="iconify ' + status +'">' + icons[status] + '</div>');

    document.body.append(el);
    
    el.addEventListener('transitionend', () => {
        setTimeout(() => {
            el.addEventListener('transitionend', () => {
                setTimeout(() => {
                    el.remove();
                }, 100)
            }, {once: true})

            setTimeout(() => {
                el.classList.add('hide');
            }, 100)
        }, 1500);

    }, {once: true});

    setTimeout(() => {
        el.classList.add('show');
    }, 100)
}