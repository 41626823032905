import ModalDialog from "../../modal-dialog/index.js";

let select_product_dialog = (item) => new Promise((resolve, reject) => {
    let prices = JSON.parse(item.prices);
    let valid = true;
    let text;

    if (!Array.isArray(prices)) {
        valid = false;
    }

    if (valid) {
        prices.every(v_item => {
            let id = v_item.id.trim();
            let title = v_item.key.trim();
            let price = v_item.value.trim();

            if ((id == '') || (title == '') || (price == '')) {
                valid = false;
                return false;
            }

            if (isNaN(parseInt(Graphite.extract_number(price)))) {
                valid = false;
                return false;
            }

            if (parseInt(Graphite.extract_number(price)) <= 0) {
                valid = false;
                return false;
            }

            return true;
        })
    }

    if (!valid) {
        text = 'สินค้านี้ยังไม่พร้อมจำหน่าย เนื่องจากยังใส่ข้อมูลไม่เรียบร้อย กรุณาติดต่อ Admin เพื่อขอรับความช่วยเหลือ' + Graphite.get_ending();

        if (Graphite.config.default_language != 'th') {
            text = 'The product\'s information is not completed. Please contact Admin for  support';
        }

        Graphite.show_message(Graphite.admin.profile_image, text);
        return;
    }

    const dialog = new ModalDialog({
        id: 'select-product-dialog',
        width: 720,
        padding: '2em',
        transition_duration: 200,
        delay_before_show: 0
    })

    const sb = [];

    let image = $.default(item.image, '');
    let free_shipping = $.default(item.free_shipping, 'off');

    if (image.startsWith('uploads')) {
        let arr = image.split('/');

        if (arr.length == 2) {
            image = 'resize?folder=' + arr[0] + '&file=' + arr[1] + '&w=400&h=400';
        }
    }

    sb.push('<div class="flex-column ai-center">');
    sb.push('<div class="ux-image-container" data-role="placeholder">');
    sb.push('<img width="400" heihgt="400" src="' + image + '"/>');

    if (free_shipping == 'on') {
        sb.push('<img class="badge-free-shipping" src="./images/free-shipping.png" alt="free-shipping" title="จัดส่งฟรี!">');
    }

    sb.push('</div>');
    sb.push('<div class="group">');
    sb.push('<p class="title">' + $.default(item.title, 'Lorem ipsum') + '</p>');

    let sub_title = $.default(item.sub_title, '').trim();

    if (sub_title) {
        sb.push('<p class="sub-title">' + sub_title + '</p>');
    }

    sb.push('</div>');
    sb.push('<div class="items">');
    
    let count = 1;

    if (Array.isArray(prices)) {
        prices.forEach(v_item => {
            sb.push('<div data-id="' + v_item.id + '" data-title="' + Graphite.escape(v_item.key) + '" data-price="' + Graphite.extract_number(v_item.value) + '" class="flex-row item ' + (count == 1?'selected':'') + '">');
            sb.push('<p class="title">' + $.default(v_item.key, 'Lorem ipsum doror') + '</p>');
            sb.push('<p class="price">' + Graphite.number_format($.default(v_item.value, '100')) + ' <span class="unit">' + Graphite.config.currency_unit + '</span></p>');
            sb.push('<i class="icon-checked"></i>');        
            sb.push('</div>');

            count++;
        })
    }

    sb.push('</div>');

    sb.push('<footer class="flex-row center gap-10px mt-30px">');
    sb.push('<a href="#" role="button" data-cmd="close"><i class="icon-close"></i> ยกเลิก</a>');
    sb.push('<a href="#" role="button" data-cmd="ok"><i class="icon-shopping-cart"></i> สั่งซื้อเลย</a>');
    sb.push('</footer>');

    dialog.set(sb.join(''));

    dialog.popup.addEventListener('click', evt => {
        let el = evt.target;

        if (el.matches('.item')) {
            dialog.popup.querySelectorAll('.items > .item').forEach(item => {
                item.classList.remove('selected');
            })

            el.classList.add('selected');
        } else if (el.matches('a[data-cmd="ok"]')) {
            evt.preventDefault();
            evt.stopPropagation();

            let option, v_el;
            let group = dialog.popup.querySelector('.group');
            let item = dialog.popup.querySelector('.item.selected');


            option = item.getAttribute('data-title');

            let node = {
                id: item.getAttribute('data-id'),
                option: option,
                price: item.getAttribute('data-price')
            }

            dialog.set_cmd('ok');
            dialog.set_value(node);
            dialog.close();
        }
    })

    Graphite.preload_images(dialog.popup).then(() => {
        resolve(dialog.open());        
    })
})



export default select_product_dialog;