function limit(value, min, max) {
    value = +value;
    return isNaN(value) ? min : (value < min ? min : (value > max ? max : value));
}

function rgb_to_hsv(r, g, b) {
    [r, g, b] = [limit(r, 0, 255) / 255, limit(g, 0, 255) / 255, limit(b, 0, 255) / 255];
    
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, v = max;
    let d = max - min;

    s = max === 0 ? 0 : d / max;

    if(max == min) {
        h = 0;
    } else {
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }
    return [h, s, v];
}

function hex_to_rgb(hex) {
    if (hex) {
        hex = hex.trim().toLowerCase();
        const [, , , r, g, b, , rr, gg, bb] = /^\s*#?((([0-9A-F])([0-9A-F])([0-9A-F]))|(([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})))\s*$/i.exec(hex) || [];
        if (r !== undefined) {
            return [
                parseInt(r + r, 16),
                parseInt(g + g, 16),
                parseInt(b + b, 16)
            ];
        } else if (rr !== undefined) {
            return [
                parseInt(rr, 16),
                parseInt(gg, 16),
                parseInt(bb, 16)
            ];
        }
    }

    return null;
}

function rgb_to_hsl(r, g, b) {
    [r, g, b] = [limit(r, 0, 255) / 255, limit(g, 0, 255) / 255, limit(b, 0, 255) / 255];

    const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max == min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = ((g - b) / d) + (g < b ? 6 : 0);
                break;
            case g:
                h = ((b - r) / d) + 2;
                break;
            case b:
                h = ((r - g) / d) + 4;
                break;
        }

        h /= 6;
    }

    return [h * 360, s * 100, l * 100].map(Math.round);
}

function rgb_to_hex(r, g, b) {
    [r, g, b] = [limit(r, 0, 255), limit(g, 0, 255), limit(b, 0, 255)];
    return "#" + ("000000" + ((r << 16) | (g << 8) | b).toString(16)).slice(-6);
}

export default function Spectrum(canvas) {
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const whiteBlackGradient = ctx.createLinearGradient(1, 1, 1, height - 1);
    
    whiteBlackGradient.addColorStop(0, 'white');
    whiteBlackGradient.addColorStop(1, 'black');

    return {
        set_hue(hue) {
            const colorGradient = ctx.createLinearGradient(1, 0, width - 1, 0);

            colorGradient.addColorStop(0, `hsla(${hue}, 100%, 50%, 0)`);
            colorGradient.addColorStop(1, `hsla(${hue}, 100%, 50%, 1)`);

            ctx.fillStyle = whiteBlackGradient;
            ctx.fillRect(0, 0, width, height);
            ctx.fillStyle = colorGradient;
            ctx.globalCompositeOperation = 'multiply';
            ctx.fillRect(0, 0, width, height);
            ctx.globalCompositeOperation = 'source-over';
        },

        grab_color(x, y) {
            return ctx.getImageData(x, y, 1, 1).data;
        },

        find_color(r, g, b) {
            const [, s, v] = rgb_to_hsv(r, g, b);
            const x = s * width;
            const y = height - (v * height);

            return [x, y];
        }
    }
}

export { rgb_to_hsv, hex_to_rgb, rgb_to_hsl, rgb_to_hex }