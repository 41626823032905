const options = {
  root: null, // Use the viewport as the root
  rootMargin: "0px",
  threshold: 0.1 // Specify the threshold for intersection
}

const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const el = entry.target;

            if (typeof el.cb === 'function') {
                el.cb(el);
            }
            
            observer.unobserve(el);
        }
    })
}

const observer = new IntersectionObserver(handleIntersection, options);

export default function in_view(el, cb = null) {
    if (typeof cb === 'function') {
        el.cb = cb;
    }

    observer.observe(el);
}

