import ModalDialog from "../../modal-dialog/index.js";

class InstagramObserver {
    static observe(el, cb) {
        let config = {attribute:false, childList:true, subtree:false}

        let callback = (mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.removedNodes.length) {
                    let node = mutation.removedNodes[0];
                    
                    if (node.nodeName === 'BLOCKQUOTE') {
                        observer.disconnect();
                        if (typeof cb === 'function') {
                            cb();
                        }
                    }
                }
            }
        }

        let observer = new MutationObserver(callback);  

        observer.observe(el, config);      
    }
}

function youtube_lightbox(url) {
    const dialog = new ModalDialog({
        id: 'youtube-lightbox',
        width: 940,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0
    })

    dialog.dialog.classList.add('lightbox');
    dialog.dialog.classList.add('loading');

    let matches = url.match(/https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9-_]+)/);

    if (matches) {
        $.get_json('https://youtube.com/oembed?url=' + encodeURIComponent(url) + '&format=json').then(node => {
            const sb = [];

            sb.push('<div class="video-container" style="--ratio: ' + (node.width / node.height) + '">');
            sb.push('<iframe class="lazy" src="images/blank.png" data-src="https://www.youtube.com/embed/' + matches[1] + '?feature=oembed?rel=0&autoplay=1" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>');
            sb.push('</div>');

            dialog.set(sb.join(''));

            let iframe = dialog.popup.querySelector('iframe.lazy');

            let on_load = () => {
                iframe.classList.remove('lazy');
                iframe.removeEventListener('load', on_load);                

                setTimeout(() => {
                    dialog.dialog.classList.remove('loading');
                    $(dialog.popup).fadeIn();
                }, 150)
            }

            let src = iframe.getAttribute('data-src');

            setTimeout(() => {
                iframe.addEventListener('load', on_load);
                iframe.contentWindow.location.replace(src);
            }, 100)

            return dialog.open();
        })
    }
}

function youtube_short_lightbox(url) {
    const dialog = new ModalDialog({
        id: 'youtube-short-lightbox',
        width: 480,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0
    })

    dialog.dialog.classList.add('lightbox');
    dialog.dialog.classList.add('loading');

    let matches = url.match(/https:\/\/www\.youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/);

    if (matches) {
        $.get_json('https://youtube.com/oembed?url=' + encodeURIComponent(url) + '&format=json').then(node => {
            const sb = [];

            sb.push('<div class="video-container" style="--ratio: ' + (node.width / node.height) + '">');
            sb.push('<iframe class="lazy" src="images/blank.png" data-src="https://www.youtube.com/embed/' + matches[1] + '?feature=oembed?rel=0&autoplay=1" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>');
            sb.push('</div>');

            dialog.set(sb.join(''));

            let iframe = dialog.popup.querySelector('iframe.lazy');

            let on_load = () => {
                iframe.classList.remove('lazy');
                iframe.removeEventListener('load', on_load);                

                setTimeout(() => {
                    dialog.dialog.classList.remove('loading');
                    $(dialog.popup).fadeIn();
                }, 150)
            }

            let src = iframe.getAttribute('data-src');

            setTimeout(() => {
                iframe.addEventListener('load', on_load);
                iframe.contentWindow.location.replace(src);
            }, 100)

            return dialog.open();
        })
    }
}

function tiktok_lightbox(url) {
    const dialog = new ModalDialog({
        id: 'tiktok-lightbox',
        width: 480,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0
    })

    dialog.dialog.classList.add('lightbox');
    dialog.dialog.classList.add('loading');

    $.get_json('https://www.tiktok.com/oembed?url=' + encodeURIComponent(url)).then(node => {
        const sb = [];

        sb.push('<div class="video-container" style="--ratio: ' + (node.thumbnail_width / node.thumbnail_height) + ';">');
        sb.push('<iframe class="lazy" src="images/blank.png" data-src="https://www.tiktok.com/player/v1/' + node.embed_product_id + '?autoplay=1"></iframe>');
        sb.push('</div>');

        dialog.set(sb.join(''));

        let iframe = dialog.popup.querySelector('iframe.lazy');

        let on_load = () => {
            let body = document.body;

            iframe.classList.remove('lazy');
            iframe.removeEventListener('load', on_load);                

            setTimeout(() => {
                dialog.dialog.classList.remove('loading');
                $(dialog.popup).fadeIn();
            }, 800)
        }

        let src = iframe.getAttribute('data-src');

        setTimeout(() => {
            iframe.addEventListener('load', on_load);
            iframe.contentWindow.location.replace(src);
        }, 100)

        return dialog.open();
    })
}

function facebook_reel_lightbox(url) {
    let options = {
        id: 'facebook-reel-lightbox',
        width: 480,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0        
    }

    const dialog = new ModalDialog(options);

    dialog.dialog.classList.add('lightbox');
    dialog.dialog.classList.add('loading');

    let matches = url.match(/https:\/\/www\.facebook\.com\/reel\/[0-9]+/);

    if (matches) {
        const sb = [];

        sb.push('<div class="video-container">');
        sb.push('<iframe class="lazy" src="images/blank.png" data-src="https://web.facebook.com/v20.0/plugins/video.php?app_id=&container_width=' + options.width + '&href=' + encodeURIComponent(url) + '&autoplay=true&allowfullscreen=true&locale=en_US&sdk=joey" allowfullscreen></iframe>');
        sb.push('</div>');

        dialog.set(sb.join(''));

        let iframe = dialog.popup.querySelector('iframe.lazy');

        let on_load = () => {
            iframe.classList.remove('lazy');
            iframe.removeEventListener('load', on_load);                

            setTimeout(() => {
                dialog.dialog.classList.remove('loading');
                $(dialog.popup).fadeIn();
            }, 150)
        }

        let src = iframe.getAttribute('data-src');

        setTimeout(() => {
            iframe.addEventListener('load', on_load);
            iframe.contentWindow.location.replace(src);
        }, 100)


        return dialog.open();
    }
}

function facebook_post_lightbox(url) {
    let options = {
        id: 'facebook-post-lightbox',
        width: 640,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0
    }

    const dialog = new ModalDialog(options);
    const sb = [];

    dialog.dialog.classList.add('lightbox');
    dialog.dialog.classList.add('loading');

    sb.push('<div class="plugin-container">');
    sb.push('<div class="fb-post" data-href="' + url + '" data-width="' + options.width + '" data-show-text="true"></div>');
    sb.push('</div>');

    dialog.set(sb.join(''));

    dialog.on('open', () => {
        FB.Event.subscribe("xfbml.render", function(evt) {
            setTimeout(() => {
                dialog.dialog.classList.remove('loading');
                $(dialog.popup).fadeIn();
            }, 400)

            FB.Event.unsubscribe("xfbml.render");
        })

        FB.XFBML.parse(dialog.popup.querySelector('.plugin-container'));
    })

    return dialog.open();
}

function facebook_video_lightbox(url) {
    let options = {
        id: 'facebook-video-lightbox',
        width: 640,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0
    }

    const dialog = new ModalDialog(options);
    const sb = [];


    dialog.dialog.classList.add('lightbox');
    dialog.dialog.classList.add('loading');

    sb.push('<div class="plugin-container">');
    sb.push('<div class="fb-video" data-href="' + url + '" data-width="' + options.width + '" data-show-text="true" data-autoplay="true" data-allowfullscreen="true"></div>');
    sb.push('</div>');

    dialog.set(sb.join(''));

    dialog.on('open', () => {
        FB.Event.subscribe("xfbml.render", function(evt) {
            setTimeout(() => {
                dialog.dialog.classList.remove('loading');
                $(dialog.popup).fadeIn();
            }, 300)

            FB.Event.unsubscribe("xfbml.render");
        })

        FB.XFBML.parse(dialog.popup.querySelector('.plugin-container'));
    })

    return dialog.open();
}

function streamable_lightbox(url) {
    let matches = url.match(/^https:\/\/streamable\.com\/([a-zA-Z0-9-_]+)$/);

    if (matches) {
        $.get_json('https://api.streamable.com/oembed.json?url=' + encodeURIComponent(url)).then(node => {
            let options = {
                id: 'streamable-lightbox',
                width: 940,
                padding: '0',
                transition_duration: 200,
                delay_before_show: 0
            }

            if (node.height > node.width) {
                options.width = 480;
            }

            const dialog = new ModalDialog(options);
            const sb = [];

            dialog.dialog.classList.add('lightbox');
            dialog.dialog.classList.add('loading');

            sb.push('<div class="video-container" style="--ratio: ' + (node.width / node.height) + '">');
            sb.push('<iframe class="lazy" src="images/blank.png" data-src="https://streamable.com/o/' + matches[1] + '?feature=oembed?rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>');
            sb.push('</div>');

            dialog.set(sb.join(''));

            let iframe = dialog.popup.querySelector('iframe.lazy');

            let on_load = () => {
                iframe.classList.remove('lazy');
                iframe.removeEventListener('load', on_load);                

                setTimeout(() => {
                    dialog.dialog.classList.remove('loading');
                    $(dialog.popup).fadeIn();
                }, 150)
            }

            let src = iframe.getAttribute('data-src');

            setTimeout(() => {
                iframe.addEventListener('load', on_load);
                iframe.contentWindow.location.replace(src);
            }, 100)

            return dialog.open();
        })
    }
}

function instagram_lightbox(url) {
    let options = {
        id: 'instagram-lightbox',
        width: 480,
        padding: '0',
        transition_duration: 200,
        delay_before_show: 0
    }

    const dialog = new ModalDialog(options);
    const sb = [];

    dialog.dialog.classList.add('lightbox');
    dialog.dialog.classList.add('loading');

    sb.push('<div class="plugin-container">');
    sb.push('<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="' + url + '" data-instgrm-version="14" style="max-width:' + options.width + 'px; min-width:326px;width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"></blockquote>');
    sb.push('</div>');

    dialog.set(sb.join(''));

    dialog.on('open', () => {
            InstagramObserver.observe(dialog.popup.querySelector('.plugin-container'), () => {
                dialog.dialog.classList.remove('loading');

                setTimeout(() => {
                    $(dialog.popup).fadeIn();
                }, 300)
            })

            if (!instgrm) {
                setTimeout(() => {
                    instgrm.Embeds.process();            
                }, 1000)
            } else {
                instgrm.Embeds.process();            
            }
    })

    return dialog.open();
}

function lightbox(url, type) {          
    switch (type) {
        case 'youtube':
            youtube_lightbox(url);
            break;

        case 'youtube-short':
            youtube_short_lightbox(url);
            break;

        case 'tiktok':
            tiktok_lightbox(url);
            break;

        case 'facebook-reel':
            facebook_reel_lightbox(url);
            break;

        case 'facebook-post':
            facebook_post_lightbox(url);
            break;

        case 'facebook-video':
            facebook_video_lightbox(url);
            break;

        case 'streamable':
            streamable_lightbox(url);
            break;

        case 'instagram':
            instagram_lightbox(url);
            break;
    }
}

export default lightbox;