let version = '1.02';
let binding_document_mousedown = false;
let active_menu = null;

function get_default(x, y) {
	if ((x === undefined) || (x === null) || (x === 0)) {
		return y;
	}

	return x;
}

function get_json(url) {
	return new Promise((resolve, reject) => {
	    fetch(url)
	        .then(response => response.json())
	        .then(json => resolve(json))
	        .catch(err => reject(err));		
	})
}

export default class SimpleContextMenu {
	constructor(menu) {
		this.version = version;
		this.handlers = {};
		this.menu = document.createElement('dialog');
		this.menu.classList.add('simple-context-menu');
		this.menu.innerHTML = '<ul></li>';
		this.menu_url = menu;
		this.menu_created = false;

		this.menu.addEventListener('contextmenu', evt => {
			evt.preventDefault();
			evt.stopPropagation();
		})

		this.menu.addEventListener('click', evt => {
			let ok = true;
			let li = evt.target.closest('li');

			if (!li) return;

			let action = get_default(li.dataset['action'], '');

			if ((action !== '') && (li.matches(':not(.ux-disabled)'))) {
				let handler = this.handlers[action];

				if (typeof handler === 'function') {
					let data = {
						action: action,
						value: get_default(li.dataset['value'], ''),
						el: li,
						context_menu: this
					}

					let res = handler.bind(this.context)(data);
				
					if (res === false) {
						ok = false;
					}
				}
			}

			if (ok) {
				this.hide();
				evt.preventDefault();
			}
		})

		if (!binding_document_mousedown) {
			document.addEventListener('mousedown', evt => {
				let el = evt.target;

				if (!el.closest('.simple-context-menu')) {
					this.hide();
				}				
			})
		}
	}

	create_menu() {
		return new Promise((resolve, reject) => {
			if (!this.menu_created) {
				this.menu_created = true;

				let ul = this.menu.querySelector('ul');

				try {
					let url = this.menu_url;

					if (Graphite.config.environment != 'production') {
						let ts = (new Date()).getTime();

						if (url.includes('?')) {
							url = url + '&ts=' + ts;
						} else {
							url = url + '?ts=' + ts;
						}
					}

					get_json(url).then(menu => {
						let w = get_default(menu.width, '180');

						ul.style.setProperty('--menu-width', w + 'px');

						menu.items.forEach(item => {
							ul.append(this.create_menu_item(item));
						})			

						document.body.append(this.menu);
						
						resolve(true);
					})
				} catch (error) {
					reject(error);
				}
			} else {
				resolve(true);
			}
		})
	}

	create_menu_item(item) {
		let li = document.createElement('li');
		let span = document.createElement('span');
		let a = document.createElement('a');
		let icon = get_default(item.icon, '');
		let text = get_default(item.text, '');

		if (item.id) li.setAttribute('data-id', item.id);
		if (item.text) li.setAttribute('data-text', item.text);
		if (item.action) li.setAttribute('data-action', item.action);
		if (item.value) li.setAttribute('data-value', item.value);
		if (item.group) li.setAttribute('data-group', item.group);
		if (item.class) li.setAttribute('class', item.class);
		if (item.style) li.setAttribute('style', item.style);
		if (item.role) li.setAttribute('data-role', item.role);

		a.setAttribute('href', '#');

		if (text !== '') {
			a.innerText = text;
		}

		li.prepend(a);

		if (item.menu) {
			li.append(a);
			a.innerHTML += '<svg viewBox="0 0 24 24" fill="none"><path d="M11 9L14 12L11 15" stroke="var(--icon-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

			let ul = document.createElement('ul');

			if (item.menu.id) ul.setAttribute('data-id', item.menu.id);

			if (item.menu.items) {
				item.menu.items.forEach(item => {
					ul.append(this.create_menu_item(item));
				})
			}

			a.after(ul);
		} else {
			li.append(a);
		}

		if (icon !== '') {
			span.innerHTML = icon;
		}

		li.prepend(span);

		return li;
	}

	show(offset, context) {
		let ok = true;

		this.create_menu().then(() => {
			if (active_menu) {
				active_menu.hide();				
			}

			this.reset_all_menu_items();
			this.context = context;

			let handler = this.handlers['before-show'];

			if (typeof handler === 'function') {
				let data = {
					context_menu: this
				}

				// return false if you don't want to show menu
				let res = handler.bind(this.context)(data);
			
				if (res === false) {
					ok = false;
				}
			}

			if (ok) {
				let margin = 14;
				let width, height;
				let ul;

				this.menu.classList.add('ux-show');
				this.update_dimension(offset);
				this.menu.classList.remove('popup-left');

				ul = this.menu.querySelector('& > ul');
				width = parseInt(ul.dataset['width']);
				height = parseInt(ul.dataset['height']);
				
				if (offset.x + width > (window.innerWidth - margin)) {
					offset.x = window.innerWidth - margin - width;
				} else if (offset.x < margin) {
					offset.x = margin;
				}

				if ((offset.y + height) > (document.documentElement.scrollTop + window.innerHeight - margin)) {
					offset.y = document.documentElement.scrollTop + window.innerHeight - margin - height;
				} else if (offset.y < margin) {
					offset.y = margin
				}

				if (offset.x + (width * 2) > window.innerWidth) {
					this.menu.classList.add('popup-left');					
				}

				this.menu.style.setProperty('left', offset.x + 'px');
				this.menu.style.setProperty('top', offset.y + 'px');
				this.menu.show();

				active_menu = this;
			}
		})
	}

	hide() {
		this.menu.classList.remove('ux-show');
		this.menu.close();
		active_menu = null;
	}

	reset_all_menu_items() {
		this.menu.querySelectorAll('.ux-hidden').forEach(item => {
			item.classList.remove('ux-hidden');
		})

		this.menu.querySelectorAll('.ux-disabled').forEach(item => {
			item.classList.remove('ux-disabled');
		})
	}

	show_menu_item_by_id(id) {
		this.menu.querySelectorAll('li[data-id="' + id + '"]').forEach(item => {
			item.classList.remove('ux-hidden');
		})
	}

	hide_menu_item_by_id(id) {
		this.menu.querySelectorAll('li[data-id="' + id + '"]').forEach(item => {
			item.classList.add('ux-hidden');
		})
	}

	hide_menu_item_by_cmd(cmd) {
		this.menu.querySelectorAll('li[data-cmd="' + cmd + '"]').forEach(item => {
			item.classList.add('ux-hidden');
		})
	}

	hide_menu_item_by_group(group) {
		this.menu.querySelectorAll('li[data-group*="' + group + '"]').forEach(item => {
			item.classList.add("ux-hidden");
		})
	}

	hide_menu_item_icon_by_group(group) {
		this.menu.querySelectorAll('li[data-group*="' + group + '"]').forEach(item => {
			let span = item.querySelector('span');
			span.innerHTML = '';
		})
	}

	get_menu_item(id) {
		return this.menu.querySelector('li[data-id="' + id + '"]');
	}

	disable_menu_item_by_id(id) {
		this.menu.querySelectorAll('li[data-id="' + id + '"]').forEach(item => {
			item.classList.add('ux-disabled');
		})			
	}

	enable_menu_item_by_id(id) {
		this.menu.querySelectorAll('li[data-id="' + id + '"]').forEach(item => {
			item.classList.remove('ux-disabled');
		})			
	}

	get_sub_menu(id) {
		return this.menu.querySelector('ul[data-id="' + id + '"]');
	}

	set_icon(id, icon = '') {
		let menu_item = this.get_menu_item(id);
		let span = menu_item.querySelector('span');

		if (icon == '') {
			span.innerHTML = '';
		} else {
			if (span) {
				span.remove();
			}

			span = document.createElement('span');
			span.innerHTML = icon;
			menu_item.prepend(span);
		}
	}

	update_dimension(offset) {
		let w, h;
		let arr = new Array();
		let i = 0;

		this.menu.style.visibility = 'hidden';
		this.menu.show();

		this.menu.querySelectorAll('ul').forEach(ul => {
			let style = window.getComputedStyle(ul);

			if (style.display == 'none') {
				arr.push(ul);
				ul.style.display = 'block';
			}
		})

		this.menu.querySelectorAll('ul').forEach(ul => {
			w = ul.getBoundingClientRect().width;
			h = ul.getBoundingClientRect().height;

			ul.dataset['width'] = w;
			ul.dataset['height'] = h;

			if (i > 0) {
				if ((h + offset.y) > (window.innerHeight - 14)) {
					ul.classList.add('ux-stick-to-bottom');
				} else {
					ul.classList.remove('ux-stick-to-bottom');
				}
			}

			i++;
		})

		arr.forEach(ul => {
			ul.style.display = null;
		})

		this.menu.close();
		this.menu.style.visibility = 'visible';
	}

	on(action, handler) {
		this.handlers[action] = handler;
	}
}
