import $ from '../../doriyaki/index.js';
import buttons from './buttons.js';

const _ = {
    find_button: name => {
      let res = null;

      buttons.every(button => {
        if (button.name === name) {
          res = button;
          return false;
        }

        return true;
      })

      return res;
    },

    select_none: () => {
        _.get_selection().removeAllRanges();
    },

    get_selection: () => {
        return document.getSelection();
    },

    get_range: () => {
        let selection = _.get_selection();

        if (selection.rangeCount) {
            return selection.getRangeAt(0);
        }

        return null;
    },

    find_parent: (node, selector) => {
        let p = (node.parentNode);

        if (!p) return null;

        if (p.matches && p.matches(selector)) {
            return p;
        } else {
            if (p.matches && p.matches(':is(p,ul,ol,div)')) return null;
        }

        return _.find_parent(p, selector);
    },

    find_top_node: (node) => {
        let p = (node.parentNode);

        if (!p) return node;

        if (p.matches && p.matches(':is(p,ul,ol,div)')) {
            return node;
        } else {
            return _.find_top_node(p);
        }
    },

    select_word_at_cursor: () => {
        const selection = document.getSelection();

        // Move cursor to beginning of the word
        selection?.modify('move', 'backward', 'word');

        // Extend selection to the end of the word
        selection?.modify('extend', 'forward', 'word');
    },

    exec_command: (cmd, value = '') => {
        let r = _.get_range();

        if (r) {
            if (r.collapsed) {
                if (!['icon'].includes(cmd)) {
                    _.select_word_at_cursor();
                }
            }

            switch (cmd) {
                case 'bold':
                    r = _.get_range();

                    if (r) {
                        let wrapper = $('<span id="target"></span>').get(0);
                        let c = r.extractContents();
                        let node;

                        r.insertNode(c);
                        r.surroundContents(wrapper);                            
     
                        node = wrapper.querySelector('b');

                        if (node) {
                            $(node).unwrap();
                        } else {
                            let new_node = $('<b></b>').get(0);

                            r.selectNode(wrapper);
                            r.surroundContents(new_node); 

                            let top_node = _.find_top_node(new_node);

                            if (!top_node.nextSibling) {
                                top_node.after(document.createTextNode(' '));
                            } else {
                                if (top_node.nextSibling.textContent.trim() == '') {
                                    top_node.after(document.createTextNode(' '));
                                }
                            }

                            $(wrapper).unwrap();  
                        }

                        _.select_none();                
                        r.detach();
                    }

                    break; 

                case 'italic':
                    r = _.get_range();

                    if (r) {
                        let wrapper = $('<span id="target"></span>').get(0);
                        let c = r.extractContents();
                        let node;

                        r.insertNode(c);
                        r.surroundContents(wrapper);                            
     
                        node = wrapper.querySelector('em');

                        if (node) {
                            $(node).unwrap();
                        } else {
                            let new_node = $('<em></em>').get(0);

                            r.selectNode(wrapper);
                            r.surroundContents(new_node); 

                            $(wrapper).unwrap();  
                        }

                        _.select_none();                
                        r.detach();
                    }

                    break; 

                case 'strikethrough':
                    r = _.get_range();

                    if (r) {
                        let wrapper = $('<span id="target"></span>').get(0);
                        let c = r.extractContents();
                        let node;

                        r.insertNode(c);
                        r.surroundContents(wrapper);                            
     
                        node = wrapper.querySelector('s');

                        if (node) {
                            $(node).unwrap();
                        } else {
                            let new_node = $('<s></s>').get(0);

                            r.selectNode(wrapper);
                            r.surroundContents(new_node); 

                            let top_node = _.find_top_node(new_node);

                            if (!top_node.nextSibling) {
                                top_node.after(document.createTextNode(' '));
                            } else {
                                if (top_node.nextSibling.textContent.trim() == '') {
                                    top_node.after(document.createTextNode(' '));
                                }
                            }

                            $(wrapper).unwrap();  
                        }

                        _.select_none();                
                        r.detach();
                    }

                    break; 

                case 'color':
                    r = _.get_range();

                    if (r) {
                        let wrapper = $('<span id="target"></span>').get(0);
                        let c = r.extractContents();
                        let node;

                        r.insertNode(c);
                        r.surroundContents(wrapper);                            
     
                        node = wrapper.querySelector('span[style^="color"]');

                        if (!node) {
                            node = _.find_parent(wrapper, 'span[style^="color"]');
                        }

                        if (node) {
                            if (value === 'none') {
                                $(node).unwrap();
                                $(wrapper).unwrap();
                            } else {
                                node.setAttribute('style', 'color:' + value);
                                $(wrapper).unwrap();
                            }
                        } else {
                            let new_node = $('<span style="color: ' + value + '"></span>').get(0);

                            r.selectNode(wrapper);
                            r.surroundContents(new_node); 

                            $(wrapper).unwrap();
                        }

                        _.select_none();                
                        r.detach();
                    }

                    break; 

                case 'hilight':
                    r = _.get_range();

                    if (r) {
                        let wrapper = $('<span id="target">&nbsp;</span>').get(0);
                        let c = r.extractContents();
                        let node;

                        r.insertNode(c);
                        r.surroundContents(wrapper);       
     
                        node = wrapper.querySelector('span.hilight');

                        if (!node) {
                            node = _.find_parent(wrapper, 'span.hilight');
                        }

                        if (node) {
                            if (value === 'none') {
                                node.querySelectorAll('*').forEach(el => {
                                    if (el.innerText.trim() == '') {
                                        el.remove();
                                    }
                                })

                                $(node).unwrap();
                                $(wrapper).unwrap();
                            } else {
                                node.setAttribute('style', '--bg-color:' + value);                            
                                $(wrapper).unwrap();
                                node.querySelectorAll('*').forEach(el => {
                                    if (el.innerText.trim() == '') {
                                        el.remove();
                                    }
                                })
                            }
                        } else {
                            let new_node = $('<span class="hilight" style="--bg-color:' + value + '"></span>').get(0);

                            r.selectNode(wrapper);
                            r.surroundContents(new_node); 

                            $(wrapper).unwrap();
                        }

                        _.select_none();                
                        r.detach();
                    }

                    break; 

                case 'icon':
                    // value = '&#xE000;';

                    r = _.get_range();

                    if (r) {
                        let node = $('<span>' + value + '</span>').get(0);
                        
                        if (!r.collapsed) {
                            r.deleteContents();
                        }

                        r.insertNode(node);


                        let prev = node.previousSibling;
                        let next = node.nextSibling;

                        if (prev && prev.nodeName === '#text') {
                            if (!prev.textContent.endsWith(' ')) {
                                node.prepend(document.createTextNode(' '));
                            }
                        } 

                        if (next && next.nodeName === '#text') {
                            if (!next.textContent.startsWith(' ')) {
                                node.append(document.createTextNode(' '));
                            }
                        } 

                        $(node).unwrap();
                        _.select_none();
                        r.detach();
                    }

                    break; 

                case 'link':
                    r = _.get_range();
                    
                    if (r) {
                        let wrapper = $('<span id="target"></span>').get(0);
                        let c = r.extractContents();
                        let node;

                        r.insertNode(c);
                        r.surroundContents(wrapper);                            
     
                        node = wrapper.querySelector('a');

                        if (node) {
                            $(node).attr('href', value);
                            $(wrapper).unwrap();  
                        } else {
                            let new_node = $('<a href="' + value + '" target="' + Graphite.get_target(value) + '"></a>').get(0);

                            r.selectNode(wrapper);
                            r.surroundContents(new_node); 

                            let top_node = _.find_top_node(new_node);

                            if (!top_node.nextSibling) {
                                top_node.after(document.createTextNode(' '));
                            } else {
                                if (top_node.nextSibling.textContent.trim() == '') {
                                    top_node.after(document.createTextNode(' '));
                                }
                            }

                            $(wrapper).unwrap();
                        }

                        _.select_none();                
                        r.detach();
                    }

                    break;
            }
        }
    },

    query_command_state: (cmd) => new Promise(resolve => {
        setTimeout(() => {
            let r = _.get_range();
            let res = false;

            if (r) {
                let el;

                switch (cmd) {
                    case 'bold':
                        el = r.startContainer;

                        if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                            if (_.find_parent(el, 'b')) {
                                res = true;
                            }
                        }

                        break;

                    case 'italic':
                        el = r.startContainer;

                        if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                            if (_.find_parent(el, 'em')) {
                                res = true;
                            }
                        }

                        break;

                    case 'strikethrough':
                        el = r.startContainer;

                        if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                            if (_.find_parent(el, 's')) {
                                res = true;
                            }
                        }

                        break;

                    case 'color':
                        el = r.startContainer;

                        if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                            if (_.find_parent(el, 'span[style^="color"]')) {
                                res = true;
                            }
                        }

                        break;

                    case 'hilight':
                        el = r.startContainer;

                        if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                            if (_.find_parent(el, 'span.hilight')) {
                                res = true;
                            }
                        }

                        break;

                    case 'link':
                        el = r.startContainer;

                        if (!el.parentNode.matches(':is(p,ul,ol,div)')) {
                            if (_.find_parent(el, 'a')) {
                                res = true;
                            }
                        }

                        break;
                }
            }

            resolve(res);

        }, 50);
    }),

    create_selection: (node) => {
        const selection = document.getSelection();
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(node);
        range.setStart(range.startContainer, 0); 
        range.setEnd(range.endContainer, 0); 
        selection.addRange(range);
    },

    create_selection_ex: (node) => {
        const selection = document.getSelection();
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNode(node);
        range.setStart(range.startContainer, 0); 
        range.setEnd(range.endContainer, 0); 
        selection.addRange(range);
    }
}

export default _;