import ModalDialog from "../../modal-dialog/index.js";

let select_pattern = (pattern = '', mode = 'light') => new Promise((resolve, reject) => {
    const dialog = new ModalDialog({
        id: 'pattern-dialog',
        width: 914,
        padding: '2em',
        transition_duration: 200,
        delay_before_show: 0
    })

    const light_patterns = [
        {"title":"Moroccan Flower", "src":"moroccan-flower.png","size":"auto","preview_size":"auto"},
        {"title":"Double Bubble", "src":"double-bubble.webp","size":"auto","preview_size":"auto"},
        {"title":"Double Bubble Outline", "src":"double-bubble-outline.webp","size":"auto","preview_size":"auto"},
        {"title":"Webb", "src":"webb.png","size":"auto","preview_size":"auto"},
        {"title":"Geometric Leaves", "src":"geometric-leaves.webp","size":"auto","preview_size":"auto"},
        {"title":"Embossed Diamond", "src":"embossed-diamond.webp","size":"auto","preview_size":"auto"},
        {"title":"Hotel Wallpaper", "src":"hotel-wallpaper.png","size":"auto","preview_size":"auto"},
        {"title":"Papyrus", "src":"papyrus.webp","size":"auto","preview_size":"auto"},
        {"title":"White Waves", "src":"white-waves.png","size":"auto","preview_size":"auto"},
        {"title":"Folk Pattern", "src":"folk-pattern.png","size":"auto","preview_size":"82px"},
        {"title":"Gravel", "src":"gravel.png","size":"auto","preview_size":"440px"},
        {"title":"Qbkls Pattern", "src":"qbkls.webp","size":"auto","preview_size":"564px"},
        {"title":"What The Hex", "src":"what-the-hex.png","size":"auto","preview_size":"auto"},
        {"title":"Round", "src":"round.png","size":"auto","preview_size":"auto"},
        {"title":"Regal", "src":"regal.png","size":"auto","preview_size":"auto"},
        {"title":"Seigaiha", "src":"seigaiha.png","size":"auto","preview_size":"auto"},
        {"title":"Cheap Diagonal Fabric", "src":"cheap_diagonal_fabric.png","size":"auto","preview_size":"auto"},
        {"title":"Symphony", "src":"symphony.png","size":"auto","preview_size":"auto"},
        {"title":"Swirl Pattern", "src":"swirl_pattern.png","size":"auto","preview_size":"auto"},
        {"title":"Sand Paper", "src":"sandpaper.png","size":"auto","preview_size":"auto"},
        {"title":"Squairy Light", "src":"squairy_light.png","size":"auto","preview_size":"auto"},
        {"title":"Binding Light", "src":"binding_light.png","size":"auto","preview_size":"auto"},
        {"title":"Textured Paper", "src":"textured_paper.png","size":"auto","preview_size":"auto"},
        {"title":"P5", "src":"p5.png","size":"auto","preview_size":"auto"},
        {"title":"Subtle White Feathers", "src":"subtle_white_feathers.png","size":"auto","preview_size":"auto"},
        {"title":"Subtle Kinda Jean", "src":"kindajean.png","size":"auto","preview_size":"auto"},
        {"title":"Subtle Grunge", "src":"subtle_grunge.png","size":"auto","preview_size":"auto"},
        {"title":"Gray Jean", "src":"gray_jean.png","size":"auto","preview_size":"auto"},
        {"title":"White Wall", "src":"white_wall.png","size":"auto","preview_size":"auto"},
        {"title":"Grid", "src":"grid.png","size":"auto","preview_size":"auto"},
        {"title":"Carboard Flat", "src":"cardboard_flat.png","size":"auto","preview_size":"auto"},
        {"title":"Egg Shell", "src":"egg_shell.png","size":"auto","preview_size":"auto"},
        {"title":"Skin Side Up", "src":"skin_side_up.png","size":"auto","preview_size":"auto"},
        {"title":"Natural Paper", "src":"natural_paper.png","size":"auto","preview_size":"auto"},
        {"title":"Wall 4", "src":"wall-4.png","size":"auto","preview_size":"auto"},
        {"title":"Diamond Uphostery", "src":"diamond_upholstery.png","size":"auto","preview_size":"auto"},
        {"title":"Subtle Stripes", "src":"subtle_stripes.png","size":"auto","preview_size":"auto"},
        {"title":"G-Play", "src":"g-play.png","size":"auto","preview_size":"auto"},
        {"title":"Tex2res5", "src":"tex2res5.png","size":"auto","preview_size":"auto"},
        {"title":"Little Triangles", "src":"little_triangles.png","size":"auto","preview_size":"auto"},
        {"title":"Vaio", "src":"vaio_hard_edge.png","size":"auto","preview_size":"auto"},
        {"title":"Texturetastic Gray", "src":"texturetastic_gray.png","size":"auto","preview_size":"auto"},
        {"title":"Soft Pad", "src":"soft_pad.png","size":"auto","preview_size":"12px"},
        {"title":"Old Wall", "src":"old_wall.webp","size":"auto","preview_size":"auto"},
        {"title":"Batthern", "src":"batthern.png","size":"auto","preview_size":"auto"},
        {"title":"Rough Cloth", "src":"rough-cloth.png","size":"auto","preview_size":"auto"},
        {"title":"Paven", "src":"paven.png","size":"auto","preview_size":"auto"},
        {"title":"Washi", "src":"washi.png","size":"auto","preview_size":"auto"},
        {"title":"White Texture", "src":"white_texture.webp","size":"auto","preview_size":"auto"},
        {"title":"Beige Paper", "src":"beige_paper.png","size":"auto","preview_size":"auto"},
        {"title":"Wine Cork", "src":"wine-cork.png","size":"auto","preview_size":"auto"},
        {"title":"Exclusive Paper", "src":"exclusive_paper.png","size":"auto","preview_size":"auto"},
        {"title":"Paper 2", "src":"paper_2.png","size":"auto","preview_size":"auto"},
        {"title":"White Sand", "src":"white_sand.png","size":"auto","preview_size":"auto"},
        {"title":"Leather 1", "src":"leather-1.png","size":"auto","preview_size":"auto"},
        {"title":"45 Degree Fabric", "src":"45-degreee-fabric.webp","size":"auto","preview_size":"auto"},
        {"title":"Noisy", "src":"noisy.webp","size":"auto","preview_size":"auto"},
        {"title":"None", "src":"","size":""}
    ]

    const dark_patterns = [
        {"title":"Double Bubble Dark", "src":"double-bubble-dark.webp","size":"auto"},
        {"title":"Y So Serious", "src":"y-so-serious.png","size":"auto"},
        {"title":"Webb Dark", "src":"webb-dark.png","size":"auto"},
        {"title":"Repeated Square Dark", "src":"repeated-square-dark.webp","size":"auto"},
        {"title":"Hotel Wallpaper Black", "src":"hotel-wallpaper-black.webp","size":"auto"},
        {"title":"Papyrus Dark", "src":"papyrus-dark.webp","size":"auto"},
        {"title":"Memphis Mini Dark", "src":"memphis-mini-dark.webp","size":"auto"},
        {"title":"Dark Honeycomb", "src":"dark-honeycomb.png","size":"auto"},
        {"title":"Mossaic", "src":"mosaic.webp","size":"auto"},
        {"title":"Prism", "src":"prism.png","size":"auto"},
        {"title":"Fancy Cushion", "src":"fancy-cushion.webp","size":"auto"},
        {"title":"Folk Pattern Black", "src":"folk-pattern-black.webp","size":"auto"},
        {"title":"Connect Work", "src":"connectwork.webp","size":"auto"},
        {"title":"Country Quilt Dark", "src":"country-quilt-dark.webp","size":"auto"},
        {"title":"What The Hex Dark", "src":"what-the-hex-dark.png","size":"auto"},
        {"title":"Christmas Black", "src":"christmas-black.webp","size":"auto"},
        {"title":"Spiration Dark", "src":"spiration-dark.webp","size":"auto"},
        {"title":"Curls", "src":"curls.webp","size":"auto"},
        {"title":"Dark Triangles", "src":"dark-triangles.png","size":"auto"},
        {"title":"Dark Embroidery", "src":"dark_embroidery.png","size":"auto"},
        {"title":"Footer Lodyas", "src":"footer_lodyas.png","size":"auto"},
        {"title":"Cougruent Outline", "src":"congruent_outline.png","size":"auto"},
        {"title":"Zwartevilt", "src":"zwartevilt.png","size":"auto"},
        {"title":"Binding Dark", "src":"binding_dark.png","size":"auto"},
        {"title":"Slash It", "src":"slash_it.png","size":"auto"},
        {"title":"Simple Dashed", "src":"simple_dashed.png","size":"auto"},
        {"title":"Moulin", "src":"moulin.png","size":"auto"},
        {"title":"Dark Exa", "src":"dark_exa.png","size":"auto"},
        {"title":"Dark Dotted", "src":"dark_dotted.png","size":"auto"},
        {"title":"Escheresque Ste", "src":"escheresque_ste.png","size":"auto"},
        {"title":"Black Lozenge", "src":"black_lozenge.png","size":"auto"},
        {"title":"Dark Fish Skin", "src":"dark_fish_skin.png","size":"auto"},
        {"title":"Maze Black", "src":"pw_maze_black.png","size":"auto"},
        {"title":"Black Orchid", "src":"blackorchid.png","size":"auto"},
        {"title":"Debut Dark", "src":"debut_dark.png","size":"auto"},
        {"title":"Twinkle Twinkle", "src":"twinkle_twinkle.png","size":"auto"},
        {"title":"Wild Oliva", "src":"wild_oliva.png","size":"auto"},
        {"title":"Stressed Linen", "src":"stressed_linen.png","size":"auto"},
        {"title":"Navy Blue", "src":"navy_blue.png","size":"auto"},
        {"title":"Office", "src":"office.png","size":"auto"},
        {"title":"Hexabump", "src":"hexabump.png","size":"auto"},
        {"title":"Subtle Carbon", "src":"subtle_carbon.png","size":"auto"},
        {"title":"Black Twill", "src":"black_twill.png","size":"auto"},
        {"title":"Asphalt", "src":"asfalt.png","size":"auto"},
        {"title":"Use Your Illusion", "src":"use_your_illusion.png","size":"auto"},
        {"title":"Low Contrast Linen", "src":"low_contrast_linen.png","size":"auto"},
        {"title":"Dirty Old Shirt", "src":"dirty_old_shirt.png","size":"auto"},
        {"title":"Burried", "src":"burried.png","size":"auto"},
        {"title":"Assault", "src":"assault.png","size":"auto"},
        {"title":"Skewed Print", "src":"skewed_print.png","size":"auto"},
        {"title":"Dark Wall", "src":"dark_wall.webp","size":"auto"},
        {"title":"Txture", "src":"txture.png","size":"auto"},
        {"title":"Cartographer", "src":"cartographer.png","size":"auto"},
        {"title":"Dark Tire", "src":"dark_tire.png","size":"auto"},
        {"title":"Outlets", "src":"outlets.png","size":"auto"},
        {"title":"Tasky", "src":"tasky_pattern.png","size":"auto"},
        {"title":"Pinstriped Suit", "src":"pinstriped_suit.png","size":"auto"},
        {"title":"BO Play", "src":"bo_play_pattern.webp","size":"auto"},
        {"title":"Argyle", "src":"argyle.webp","size":"auto"},
        {"title":"Dark Geometric", "src":"dark_geometric.png","size":"auto"},
        {"title":"Classy Fabric", "src":"classy_fabric.png","size":"auto"},
        {"title":"Hixs Pattern Evolution", "src":"hixs_pattern_evolution.png","size":"auto"},
        {"title":"Subtle Dark Vertical", "src":"dvsup.png","size":"auto"},
        {"title":"Dark Matter", "src":"dark_matter.png","size":"auto"},
        {"title":"Black Thread", "src":"black_thread.png","size":"auto"},
        {"title":"Vertical Cloth", "src":"vertical_cloth.png","size":"auto"},
        {"title":"Dark Denim 3", "src":"dark-denim-3.png","size":"auto"},
        {"title":"Iron Grip", "src":"iron-grip.png","size":"auto"},
        {"title":"Nami", "src":"nami.png","size":"auto"},
        {"title":"Dark Denim 2", "src":"dark-denim-2.webp","size":"auto"},
        {"title":"Broken Noise", "src":"broken_noise.png","size":"auto"},
        {"title":"Gun Metal", "src":"gun_metal.png","size":"auto"},
        {"title":"Fake Brick", "src":"fake_brick.png","size":"auto"},
        {"title":"3px Tile", "src":"px_by_Gre3g.png","size":"auto"},
        {"title":"Diagmonds", "src":"diagmonds.png","size":"auto"},
        {"title":"Zig Zag", "src":"zigzag.png","size":"auto"},
        {"title":"Carbon Fibre Big", "src":"carbon_fibre_big.png","size":"auto"},
        {"title":"Dark Wood", "src":"dark_wood.png","size":"auto"},
        {"title":"Soft Kill", "src":"soft_kill.png","size":"auto"},
        {"title":"Dark Circles", "src":"dark_circles.png","size":"auto"},
        {"title":"Squares", "src":"squares.png","size":"auto"},
        {"title":"Woven", "src":"woven.png","size":"auto"},
        {"title":"Real Carbon Fiber", "src":"real_cf.png","size":"auto"},
        {"title":"Black Scales", "src":"black_scales.png","size":"auto"},
        {"title":"Dark Brick Wall", "src":"dark_brick_wall.png","size":"auto"},
        {"title":"Dark Leather", "src":"dark_leather.png","size":"auto"},
        {"title":"Triangles", "src":"triangles.png","size":"auto"},
        {"title":"Dark Mosaic", "src":"dark_mosaic.png","size":"auto"},
        {"title":"Inflicted", "src":"inflicted.png","size":"auto"},
        {"title":"Carbon Fiber V2", "src":"carbon_fibre_v2.png","size":"auto"},
        {"title":"Black Linen V2", "src":"black_linen_v2.png","size":"auto"},
        {"title":"Rubber Grip", "src":"rubber_grip.png","size":"auto"},
        {"title":"Dart Stripe", "src":"darth_stripe.png","size":"auto"},
        {"title":"Concrete Wall", "src":"concrete_wall.png","size":"auto"},
        {"title":"Padded", "src":"padded.png","size":"auto"},
        {"title":"Black Paper", "src":"black_paper.webp","size":"auto"},
        {"title":"Wood 2", "src":"wood_2.webp","size":"auto"},
        {"title":"Dark Stripes", "src":"dark_stripes.png","size":"auto"},
        {"title":"Black Denim", "src":"black_denim.png","size":"auto"},
        {"title":"Micro Carbon", "src":"micro_carbon.png","size":"auto"},
        {"title":"Carbon Fiber", "src":"carbon_fibre.webp","size":"auto"}
    ]

    let patterns = light_patterns;

    if (mode == 'dark') {
        patterns = dark_patterns;
    }

    let sb =[];

    sb.push('<div class="container">');

    patterns.forEach(item => {
        let cls = '';

        if (mode + '/' + item.src == pattern) {
            cls = 'active';
        }

        if (!item.src) {
            sb.push('<div class="' + cls + '" title="' + item.title + '" data-src="" data-size="" style="--bg-image: url(' + Graphite.config.base_url + 'patterns/previews/' + mode + '/none.svg)"></div>');
        } else {
            sb.push('<div class="' + cls + '" title="' + item.title + '" data-src="' + mode + '/' + item.src + '" data-size="' + item.size + '" style="--preview-size:' + $.default(item.preview_size, 'auto') + ';--bg-image: url(' + Graphite.config.base_url + 'patterns/' + mode + '/' + item.src + ')"></div>');
        }
    })

    sb.push('</div>');

    sb.push('<footer>');
    sb.push('   <a href="#" role="button" data-cmd="close"><i class="icon-close"></i> Close</a>');
    sb.push('</footer>');

    dialog.popup.addEventListener('click', evt => {
        let el = evt.target;

        if (el.matches('.container > div')) {
            dialog.popup.querySelectorAll('div.active').forEach(div => {
                div.classList.remove('active');
            })

            el.classList.add('active');

            let node = {
                src: el.getAttribute('data-src'),
                size: el.getAttribute('data-size')
            }

            dialog.set_cmd('ok');
            dialog.set_value(node);
            dialog.close();
        }
    })

    dialog.set(sb.join(''));
    resolve(dialog.open());
})

export default select_pattern;