class Fonts {
    static load(font_family, url, font_weight = 'normal') {
        let font_file = new FontFace(font_family, 'url(' + url +')', {
            style: 'normal',
            weight: font_weight    
        })

        document.fonts.add(font_file);    
        return font_file.load();
    }

    static load_selected_fonts() {
        Graphite.selected_fonts.forEach(font => {
            if (!Graphite.system_fonts.includes(font)) {
                if (!Fonts.is_loaded(font)) {
                    switch (font) {
                        case 'bai-jamjuree':
                            Fonts.load('bai-jamjuree', Graphite.config.base_url + 'fonts/bai-jamjuree/bai-jamjuree-th.woff2', 'normal');
                            Fonts.load('bai-jamjuree', Graphite.config.base_url + 'fonts/bai-jamjuree/bai-jamjuree-th-bold.woff2', 'bold');
                            break;

                        case 'kanit':
                            Fonts.load('kanit', Graphite.config.base_url + 'fonts/kanit/kanit-th.woff2', 'normal');
                            Fonts.load('kanit', Graphite.config.base_url + 'fonts/kanit/kanit-th-bold.woff2', 'bold');
                            break;

                        case 'krub':
                            Fonts.load('krub', Graphite.config.base_url + 'fonts/krub/krub-th.woff2', 'normal');
                            Fonts.load('krub', Graphite.config.base_url + 'fonts/krub/krub-th-bold.woff2', 'bold');
                            break;

                        case 'maledpan':
                            Fonts.load('maledpan', Graphite.config.base_url + 'fonts/maledpan/maledpan-th.woff2', 'normal');
                            Fonts.load('maledpan', Graphite.config.base_url + 'fonts/maledpan/maledpan-th-bold.woff2', 'bold');
                            break;

                        case 'sarabun':
                            Fonts.load('sarabun', Graphite.config.base_url + 'fonts/sarabun/sarabun-th.woff2', 'normal');
                            Fonts.load('sarabun', Graphite.config.base_url + 'fonts/sarabun/sarabun-th-bold.woff2', 'bold');
                            break;

                        case 'cera-round':
                            Fonts.load('cera-round', Graphite.config.base_url + 'fonts/cera-round/cera-round-en.woff2', 'normal');
                            Fonts.load('cera-round', Graphite.config.base_url + 'fonts/cera-round/cera-round-en-bold.woff2', 'bold');
                            break;

                        case 'inter':
                            Fonts.load('inter', Graphite.config.base_url + 'fonts/inter/inter-en.woff2', 'normal');
                            Fonts.load('inter', Graphite.config.base_url + 'fonts/inter/inter-en-bold.woff2', 'bold');
                            break;

                        case 'nunito':
                            Fonts.load('nunito', Graphite.config.base_url + 'fonts/nunito/nunito-en.woff2', 'normal');
                            Fonts.load('nunito', Graphite.config.base_url + 'fonts/nunito/nunito-en-bold.woff2', 'bold');
                            break;

                        case 'oswald':
                            Fonts.load('oswald', Graphite.config.base_url + 'fonts/oswald/oswald-en.woff2', 'normal');
                            Fonts.load('oswald', Graphite.config.base_url + 'fonts/oswald/oswald-en-bold.woff2', 'bold');
                            break;

                        case 'roboto':
                            Fonts.load('roboto', Graphite.config.base_url + 'fonts/roboto/roboto-en.woff2', 'normal');
                            Fonts.load('roboto', Graphite.config.base_url + 'fonts/roboto/roboto-en-bold.woff2', 'bold');
                            break;
                    }
                }    
            }
        })
    }

    static is_loaded(font_family) {
        let founded = false;

        Array.from(document.fonts).every(item => {
            let _family = item.family.toLowerCase().trim().replaceAll('"', '');

            if (font_family === _family) {
                founded = true; 
                return false;
            }

            return true;
        })

        return founded;
    }

    static all() {
        return Array.from(document.fonts);
    }
}

export default Fonts;