import ModalDialog from "../../modal-dialog/index.js";
import color_schemes from "./color-schemes.js";

class ColorSchemeDialog {
    constructor() {
        this.color_schemes = color_schemes;

        this.dialog = new ModalDialog({
            id: 'color-scheme-dialog',
            width: 840,
            padding: '2em'
        })
    
        let sb = [];
        
        this.color_schemes.forEach(item => {
            sb.push('<div class="ux-color-scheme" data-value="' + item.name +'">')
            sb.push('<div><span style="--bg-color:' + item.primary + '"></span><span style="--bg-color:' + item.secondary + '"></span><span style="--bg-color:' + item.accent + '"></span></div><p class="caption">' + item.name + '</p>');
            sb.push('</div>');
        })

        this.dialog.set('<div class="ux-color-schemes">' + sb.join('') + '</div><footer><a href="#" role="button" data-cmd="close"><i class="icon-close"></i> CLOSE</footer>');
 
        this.dialog.popup.addEventListener('click', evt => {
            if (evt.target.matches('.ux-color-scheme')) {
                this.dialog.set_cmd('ok');
                this.dialog.set_value(evt.target.getAttribute('data-value'));
                this.dialog.close();
            }
        })
    }

    select_color_scheme = () => {
        return this.dialog.open();
    }
}

export default ColorSchemeDialog;