import $ from '../doriyaki/index.js';
import ModalDialog from '../modal-dialog/index.js';
import { show_message, confirm } from './message-box/index.js';
import phone_dialog from './phone-dialog/index.js';
import line_dialog from './line-dialog/index.js';
import wechat_dialog from './wechat-dialog/index.js';
import whatsapp_dialog from './whatsapp-dialog/index.js';
import email_dialog from './email-dialog/index.js';
import page_dialog from './page-dialog/index.js';
import select_product_dialog from './select-product-dialog/index.js';
import lightbox from './lightbox/index.js';
import lazy_load from './lazy-load/index.js';
import preload_images from './preload-images/index.js';
import Loading from './loading/index.js';
import in_view from './in-view/index.js';
import Fonts from './fonts/index.js';
import iconify from './iconify/index.js';
import create_contact_toolbar from './contact-toolbar/index.js';
import create_user_toolbar from './user-toolbar/index.js';
import ColorUtils from './color-utils/index.js';
import select_pattern from './pattern-dialog/index.js';

let Graphite = {
    scripts: [],
    styles: [],
    widget_scripts: [],
    widget_styles: [],    
    subscribers: {},   
    current_mode: 'live',
    current_theme: '',
    is_login: false,
    config: {},
    contact: {},
    admin:{
        profile_image:''
    },
    user:{},
    ModalDialog: ModalDialog,
    Fonts: Fonts,
    lazy_load: lazy_load,
    preload_images: preload_images,
    Loading: Loading,
    in_view: in_view,
    show_message: show_message,
    confirm: confirm,
    iconify: iconify,
    phone_dialog: phone_dialog,
    line_dialog: line_dialog,
    page_dialog: page_dialog,
    select_product_dialog: select_product_dialog,
    wechat_dialog: wechat_dialog,
    whatsapp_dialog: whatsapp_dialog,
    email_dialog: email_dialog,
    lightbox: lightbox,
    create_contact_toolbar: create_contact_toolbar,
    create_user_toolbar: create_user_toolbar,
    ColorUtils: ColorUtils,
    select_pattern: select_pattern,
    mode_change_subscribers: [],
    theme_change_subscribers: [],
    preloaded_widgets: ['page','page-header','product-header','blocks','header','text','image','page-footer','share-buttons','page-counter','price-and-buy-button','footer-a','sub-footer-a'],

    session: {
        set: (key, value) => {
            let node = JSON.parse(localStorage.getItem(Graphite.session_id));

            if (!node) {
                node = {};
            }

            node[key] = value;
            localStorage.setItem(Graphite.session_id, JSON.stringify(node));
        },

        get: (key) => {
            let node = JSON.parse(localStorage.getItem(Graphite.session_id));

            if (!node) {
                node = {};
            }

            return node[key];
        },

        clear: () => {
            localStorage.removeItem(Graphite.session_id);
        }
    },

    preload_widgets: () => {
        Graphite.preloaded_widgets.forEach(widget => {
            if (!Graphite.widget_styles.includes(widget)) {
                Graphite.widget_styles.push(widget);
            }
        })
    },

    inject_style: (widget, cb) => {
        let css = './widgets/' + widget + '/style.css?v=' + Graphite.config.version;
        let hash = Graphite.hash(css);
        let el;

        let on_load = evt => {
            let el = evt.target;

            el.classList.add('loaded');

            if (typeof cb === 'function') {
                cb();
            }

            if (el.subscribers && Array.isArray(el.subscribers)) {
                el.subscribers.forEach(fnc => {
                    if (typeof fnc == 'function') {
                        fnc();
                    }
                })
            }

            el.removeEventListener('load', on_load);
        }

        if (Graphite.widget_styles.includes(widget)) {
            if (Graphite.preloaded_widgets.includes(widget)) {
                if (typeof cb === 'function') {
                    cb();
                }                
            } else {
                el = document.head.querySelector('link[data-id="' +  hash + '"]');

                if (el.classList.contains('loaded')) {
                    if (typeof cb === 'function') {
                        cb();
                    }
                } else {
                    if (!el.subscribers) {
                        el.subscribers = [];
                    }

                    el.subscribers.push(cb);
                }
            }
        } else {
            let el = document.createElement("link");

            el.addEventListener('load', on_load);
            el.setAttribute('data-id', hash);
            el.type = "text/css";
            el.rel = 'stylesheet';
            el.href = css;

            document.head.appendChild(el);
            Graphite.widget_styles.push(widget);
        }
    },

    inject_script: (widget, cb) => {
        let script = './widgets/' + widget + '/index.js?v=' + Graphite.config.version;
        let hash = Graphite.hash(script);
        let el;

        let on_load = evt => {
            let el = evt.target;

            el.classList.add('loaded');

            if (typeof cb === 'function') {
                cb();
            }

            if (el.subscribers && Array.isArray(el.subscribers)) {
                el.subscribers.forEach(fnc => {
                    fnc();
                })
            }

            el.removeEventListener('load', on_load);
        }

        if (Graphite.widget_scripts.includes(widget)) {
            el = document.head.querySelector('script[data-id="' +  hash + '"]');

            if (el.classList.contains('loaded')) {
                if (typeof cb === 'function') {
                    cb();
                }
            } else {
                if (!el.subscribers) {
                    el.subscribers = [];
                }

                el.subscribers.push(cb);
            }
        } else {
            let el = document.createElement("script");

            el.setAttribute('data-id', hash);
            el.addEventListener('load', on_load);

            document.head.appendChild(el);
            Graphite.widget_scripts.push(widget);
            el.src = script;
        }
    },

    hash: function(str) {
        let hash = 0, i, chr;

        if (str.length === 0) return hash;
      
        for (i = 0; i < str.length; i++) {
            chr   = str.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        
        return hash;
    },

    inject_js: (js, cb) => {
        let hash = Graphite.hash(js);
        let el;

        let on_load = evt => {
            let el = evt.target;
            
            el.classList.add('loaded');
            
            if (typeof cb == 'function') {
                cb();
            }

            if (el.subscribers && Array.isArray(el.subscribers)) {
                el.subscribers.forEach(fnc => {
                    fnc();
                })

                el.subscribers = [];                    
            }

            el.removeEventListener('load', on_load);
        }

        if (Graphite.scripts.includes(js)) {
            el = document.head.querySelector('script[data-id="' + hash + '"]');
            
            if (el.classList.contains('loaded')) {
                cb();
            } else {
                if (!el.subscribers) {
                    el.subscribers = [];
                }

                el.subscribers.push(cb);
            }
        } else {
            el = document.createElement("script");            
            el.setAttribute('data-id', hash);
            el.addEventListener('load', on_load);

            document.head.appendChild(el);
            Graphite.scripts.push(js);
            el.src = js;
        }
    },

    inject_css: (css, cb) => {
        let hash = Graphite.hash(css);
        let el;

        let on_load = evt => {
            let el = evt.target;
            
            el.classList.add('loaded');
            
            if (typeof cb == 'function') {
                cb();
            }

            if (el.subscribers && Array.isArray(el.subscribers)) {
                el.subscribers.forEach(fnc => {
                    fnc();
                })

                el.subscribers = [];                    
            }

            el.removeEventListener('load', on_load);
        }

        if (Graphite.styles.includes(css)) {
            el = document.head.querySelector('link[data-id="' + hash + '"]');
            
            if (el.classList.contains('loaded')) {
                cb();
            } else {
                if (!el.subscribers) {
                    el.subscribers = [];
                }

                el.subscribers.push(cb);
            }
        } else {
            el = document.createElement("link");            
            el.setAttribute('rel', 'stylesheet');
            el.setAttribute('data-id', hash);
            el.addEventListener('load', on_load);

            document.head.appendChild(el);
            Graphite.styles.push(css);
            el.href = css;
        }
    },

    init_widget: (widget, options) => {
        Graphite.inject_style(widget, () => {
            Graphite.inject_script(widget, () => {
                let fnc = eval('window.$.init_widget_' + widget.replace(/-/g, '_'));
                fnc(options);
            })                
        })
    },

    mode: (mode = null) => {
        if (mode) {
            if (['live','edit'].includes(mode) && (Graphite.current_mode !== mode)) {
                Graphite.current_mode = mode;
                let html = $('html');

                if (Graphite.current_mode === 'edit') {
                    html.data('mode', 'edit')
                } else {
                    html.data('mode', 'live');                    
                }

                localStorage.setItem('mode', mode);
                $(document).trigger(new Event('mode-change'));
            }
        } else {
            return Graphite.current_mode;
        }
    },

    theme: (theme = null) => {
        if (theme) {
            if (['dark','light'].includes(theme)) {
                Graphite.current_theme = theme;
                let html = $('html');

                if (Graphite.current_theme === 'dark') {
                    html.data('theme', 'dark')
                } else {
                    html.data('theme', 'light');                    
                }

                localStorage.setItem('theme', theme);
                $(document).trigger(new Event('theme-change'));
            }
        } else {
            if (Graphite.current_theme) {
                return Graphite.current_theme;
            } else {
                if (localStorage.getItem('theme')) {
                    Graphite.current_theme = localStorage.getItem('theme');
                } else {
                    Graphite.current_theme = $.default(Graphite.default_theme, 'light');
                }

                return Graphite.current_theme;
            }
        }
    },

    authen: (username, password) => new Promise((resolve, reject) => {
        $.post_json(Graphite.config.base_url + 'process/authen.php?t=' + (new Date()).getTime(), {
            username: username,
            password: password
        }).then(data => {
            resolve(data);
        }).catch(err => {
            reject(err);
        })        
    }),

    login: () => {
        Graphite.is_login = true;
        Graphite.mode('edit');
        window.location ='home';
    },

    logout: () => {
        Graphite.is_login = false;
        localStorage.clear();
        Graphite.mode('live');
    },

    svg_placeholder: (width, height, text = '', font_size = '1em') => {
        const sb = [];

        let bg_color = '#ECECEC';
        let text_color = '#aaa';

        if (text == '') {
            text = width + ' x ' + height;
        } else if (text == '-') {
            text = '';
        } else if (text == 'blank') {
            text = '';
            bg_color = 'transparent';
        }

        sb.push('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' + width + ' ' + height + '" width="' + width + '" height="' + height + '">');
        sb.push('<rect width="' + width + '" height="' + height + '" fill="' + bg_color + '"></rect>');
        
        if (text !== '') {
            sb.push('<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="poppins" font-size="' + font_size + '" fill="' + text_color + '">' + text + '</text>');
        }

        sb.push('</svg>');

        return 'data:image/svg+xml;base64,' + $.base64_encode(sb.join(''));
    },

    get_target: (url) => {
        if (url.startsWith(Graphite.config.base_url)) {
            return '_self';
        }

        if (!url.startsWith('http')) {
            return '_self';
        }

        return '_blank';    
    },

    popup: (url, title, w = '200', h = '250') => {
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        if (w.includes('%')) {
            w = width * parseInt(w) / 100;
        }

        if (h.includes('%')) {
            h = height * parseInt(h) / 100;
        }

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, 
          `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        )

        if (window.focus) newWindow.focus();
    },

    is_mobile_device: () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }

        return false;
    },

    get_id_from_slug: (slug) => {
        let url = Graphite.config.base_url + 'process/get-id-from-slug.php?slug=' +  encodeURIComponent(slug);
        return $.get_json(url);
    },

    get_slug_from_id: (slug) => {
        let url = Graphite.config.base_url + 'process/get-slug-from-id.php?id=' +  encodeURIComponent(slug);
        return $.get_json(url);
    },

    check_permission: (permission) => {
        return Graphite.user_permissions.includes(permission);
    },

    get_percent_discount: (price, promotion_price) => {
        return Math.round(100 - (promotion_price / price * 100));
    },

    extract_number: (str) => {
        return str.replace(/\D/g, '');
    },

    number_format: x => {
        x = x.toString();
        let pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;    
    },

    short_uid: () => {
        let firstPart = (Math.random() * 46656) | 0;
        let secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    },

    escape: (str) => {
        return str.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
    },

    dialog_is_open: () => {
        return document.body.classList.contains('dialog-is-open');
    },

    share_to: (service, data = {}) => {
        let url;

        switch (service) {
            case 'facebook':
                url = 'https://www.facebook.com/share_channel/?link=' + encodeURIComponent($.default(data.url, '')) + '&source_surface=external_reshare';
                Graphite.popup(url, 'Share to Facebook', '700', '520');
                break; 

            case 'line':
                url = 'https://social-plugins.line.me/lineit/share?url=' + encodeURIComponent($.default(data.url, ''));
                Graphite.popup(url, 'Share to Line', '620', '500');
                break; 

            case 'x':
                url = 'https://x.com/intent/post?text=' + encodeURIComponent($.default(data.text, '') + ' ' + $.default(data.url, ''));
                Graphite.popup(url, 'Share to Line', '560', '400');
                break; 

            case 'pocket':
                url = 'https://getpocket.com/edit?url=' + encodeURIComponent($.default(data.url)) + '&title=' + encodeURIComponent($.default(data.title, ''));
                Graphite.popup(url, 'Share to Pocket', '560', '400');
                break; 

            case 'raindrop':
                url = 'https://app.raindrop.io/add?link=' + encodeURIComponent($.default(data.url)) + '&title=' + encodeURIComponent($.default(data.title, ''));
                Graphite.popup(url, 'Share to Raindrop', '560', '440');
                break; 
        }
    },

    simulate_buy_now: (product_id) => {
        $.get_json(Graphite.config.base_url + 'data/' + product_id + '.json').then(node => {
            let title = $.default(node.title, 'Lorem ipsum');
            let sub_title = $.default(node.sub_title, '');
            let full_title = title;

            if (sub_title) {
                full_title = title + ' ' + sub_title;
            }



            let item = {
                "id": node.id,
                "pid": node.id,
                "title": title,
                "sub_title": sub_title,
                "price": $.default(node.price, '300'),
                "promotion_price": $.default(node.promotion_price, ''),
                "image": $.default(node.image.filename, ''),
                "weight": $.default(node.weight, '0'),
                "price_mode": $.default(node.price_mode, '1'),
                "prices": $.default(node.prices, ''),
                "free_shipping": $.default(node.free_shipping, 'off'),
                "url": $.default(node.url, ''),
                "full_title": full_title
            }

            let shopping_method = $.default(Graphite.config.shopping_method, 'line');

            if (item.price_mode == '2') {
                Graphite.select_product_dialog(item).then(res => {
                    if (res.cmd == 'ok') {
                        item.id = res.value.id;
                        item.price = res.value.price;
                        item.option = res.value.option;

                        Graphite.buy_now(shopping_method, item);

                        if (shopping_method == 'shopping-cart') {
                            let cart = Graphite.ShoppingCart.get_cart();

                            if (Object.keys(cart).length > 0) {
                                Graphite.ShoppingCart.view_cart();
                            }                                            
                        }
                    }
                })
            } else {
                Graphite.buy_now(shopping_method, item);

                if (shopping_method == 'shopping-cart') {
                    let cart = Graphite.ShoppingCart.get_cart();

                    if (Object.keys(cart).length > 0) {
                        Graphite.ShoppingCart.view_cart();
                    }
                }
            }

        })
    },

    buy_now: async (shopping_method, item) => {
        let v_url, text;

        switch (shopping_method) {
            case 'phone':
                if (Graphite.get_main_contact_number()  == '') {
                    text = 'ไม่สามารถโทรออกได้ เนื่องจากเว็บไซต์ ยังไม่ระบุเบอร์โทร กรุณาติดต่อ Admin เพื่อขอรับความช่วยเหลือ' + Graphite.get_ending();

                    if (Graphite.config.default_language != 'th') {
                        text = 'Phone number is not defined, please contact Admin for support';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, text);
                    return;
                }

                if (Graphite.is_mobile_device()) {
                    window.open('tel:' + Graphite.get_main_contact_number(), '_blank');
                } else {
                    Graphite.phone_dialog('tel:' + Graphite.get_main_contact_number());
                }
                
                break;

            case 'line':
                if (Graphite.chat.line_id.startsWith('@')) {
                    text = 'ต้องการสั่งซื้อ ' + item.full_title + ' ' + item.url;
                    v_url = 'http://line.me/R/oaMessage/' + encodeURIComponent(Graphite.chat.line_id) + '/?' + encodeURIComponent(text);

                    if (Graphite.is_mobile_device()) {
                        window.open(v_url, '_blank');    
                    } else {
                        Graphite.line_dialog(v_url, 'buy-now');
                    }
                } else {
                    v_url = 'http://line.me/ti/p/' + encodeURIComponent(Graphite.chat.line_id);
                    Graphite.line_dialog(v_url, 'buy-now');    
                }

                break;

            case 'facebook':
                v_url = 'https://m.me/' + Graphite.chat.facebook_username;

                if (Graphite.is_mobile_device()) {
                    window.open(v_url, '_blank');   
                } else {
                    Graphite.popup(v_url, '', '880', '640');
                }

                break;

            case 'shopee':
                if (item.pid) {
                    $.get_json(Graphite.config.base_url + 'data/' + item.pid + '.json').then(node => {
                        if (node.shopee_url) {
                            window.open(node.shopee_url, '_blank'); 
                        } else {
                            let text = 'สินค้านี้ ยังไม่ได้กำหนด Link ไปเว็บ Shopee กรุณาติดต่อ Admin เพื่อขอรับความช่่วยเหลือนะ' + Graphite.get_ending();

                            if (Graphite.config.default_language != 'th') {
                                text = 'Link to Shopee for this product is not defined, please contact admin for support';
                            }

                            Graphite.show_message(Graphite.admin.profile_image, text);
                        }
                    })
                }

                break;    

            case 'shopping-cart': {
                    let price_mode = $.default(item.price_mode, '1');
                    
                    if (price_mode == '2') {
                        item.promotion_price = '';
                    }

                    let v_item = {
                        id: item.id,
                        title: item.title,
                        sub_title: $.default(item.sub_title, ''),
                        option: $.default(item.option, ''),
                        price: item.price,
                        promotion_price: $.default(item.promotion_price, ''),
                        weight: parseInt($.default(item.weight, 1)),
                        image: item.image,
                        url: item.url,
                        free_shipping: item.free_shipping
                    }

                    if (v_item.weight == 0) {
                        v_item.weight = 1
                    }

                    Graphite.ShoppingCart.add_to_cart(v_item).then(() => {
                        Graphite.iconify('ok');
                    })
                }

                break;
        }
    },

    inquiry: (inquiry_method, item) => {
        let v_url, text;

        switch (inquiry_method) {
            case 'phone':
                if (Graphite.contact.contact_number.trim() == '') {
                    text = 'ไม่สามารถโทรออกได้ เนื่องจากเว็บไซต์ ยังไม่ระบุเบอร์โทร กรุณาติดต่อ Admin เพื่อขอรับความช่วยเหลือ' + Graphite.get_ending();

                    if (Graphite.config.default_language != 'th') {
                        text = 'Phone number is not defined. Please contact Admin for support';
                    }

                    Graphite.show_message(Graphite.admin.profile_image, text);
                    return;
                }

                if (Graphite.is_mobile_device()) {
                    window.open('tel:' + Graphite.contact.contact_number, '_blank');
                } else {
                    Graphite.phone_dialog('tel:' + Graphite.contact.contact_number);
                }

                break;

            case 'line':
                if (Graphite.chat.line_id.startsWith('@')) {                    
                    text = 'ต้องการสอบถามข้อมูล ' + item.title + ' ' + item.url;

                    if (Graphite.config.default_language != 'th') {
                        text = 'Inquire for ' + item.title + ' ' + item.url;
                    }

                    v_url = 'http://line.me/R/oaMessage/' + encodeURIComponent(Graphite.chat.line_id) + '/?' + encodeURIComponent(text);

                    if (Graphite.is_mobile_device()) {
                        window.open(v_url, '_blank');    
                    } else {
                        Graphite.line_dialog(v_url, 'inquiry');
                    }
                } else {
                    v_url = 'http://line.me/ti/p/' + encodeURIComponent(Graphite.chat.line_id);
                    Graphite.line_dialog(v_url, 'inquiry');    
                }

                break;

                break;

            case 'facebook':
                v_url = 'https://m.me/' + Graphite.chat.facebook_username;

                if (Graphite.is_mobile_device()) {
                    window.open(v_url, '_blank');   
                } else {
                    Graphite.popup(v_url, '', '880', '640');
                }

                break;

            case 'email':
                email_dialog('jacky_chan@mailinator.com', $.default(item.title, ''), $.default(item.url, '')).then(res => {
                    if (res.cmd == 'send') {
                        text = 'ขอบคุณสำหรับข้อมูล ระหว่างนี้ กรุณารอการติดต่อกลับ จากทีมงานของเรา' + Graphite.get_ending();

                        if (Graphite.config.default_language != 'th') {
                            text = 'Thank you for your information. We will contact you very soon';
                        }

                        Graphite.show_message(Graphite.admin.profile_image, text);
                    }
                })

                break;
        }
    },

    is_reserved_url: (url) => {
        let result = false;

        Graphite.reserved_urls.every(v_url => {
            if (url.startsWith(v_url)) {
                result = true;
                return false;
            }

            return true;
        })

        return result;
    },

    get_page_url: () => {
        let url = '';

        if (Graphite.dialog_is_open()) {
            let modals = Graphite.ModalDialog.get_modals();

            modals.every(modal => {
                let page = modal.popup.querySelector('.wx-page');

                if (page) {
                    url = Graphite.config.base_url + 'page/' + page.getAttribute('data-id');
                    return false;  
                }

                return true;
            })
        }

        if (!url) {
            url = document.head.querySelector('link[rel="canonical"]').getAttribute('href');
        }

        return url;
    },

    get_ending: () => {
        let ending = 'ครับ';

        if (Graphite.admin.gender == 'female') {
            ending = 'ค่ะ';
        }

        return ending;
    },

    friendly_file_size(bytes, si=true, dp=1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    },

    preload_page(id) {
        $.get_json('data/' + id + '01.json').then(node => {
            if (node.items) {
                let widgets = new Set();

                node.items.forEach(item => {
                    widgets.add(item.widget);
                })

                widgets.forEach(widget => {
                    console.log('inject-style:' + widget);
                    Graphite.inject_style(widget);
                })
            }
        })
    },

    track_event(event, data) {
        if ((typeof(gtag) === 'function') && Graphite.user_permissions.includes('#google-analyticts#')) {
            gtag('event', event, data);            
        }    

        if ((typeof(fbq) === 'function') && Graphite.user_permissions.includes('#facebook-pixel#')) {
            if (event === 'page_view') {
                let ts = Date.now();
                let it = ts - 861648;
                let pixel = fbq.getState().pixels[0];

                $('body').append('<img width="1" height="1" style="display:none" src="https://www.facebook.com/tr?id=' + pixel.id + '&ev=PageView&dl=' + encodeURIComponent(data.page_location) + '&sw=' + screen.width + '&sh=' + screen.height + '&ts=' + ts +'&it=' + it + '&v=' + fbq.version + '&r=stable&ec=0&o=30&cs_est=true&coo=false&rqm=GET&noscript=1" />');
            } else {
                fbq.push('trackCustom', event, data);                
            }
        }
    },

    debounce(callback, delay) {
        let timer;
        
        return (...args) => {
            clearTimeout(timer);
            
            timer = setTimeout(() => {
                callback(...args);
            }, delay)
        }
    },

    make_phone_input: (el) => {
        el.addEventListener('keydown', evt => {
            if (!['0','1','2','3','4','5','6','7','8','9','Backspace','Tab','-','+','Ctrl','x','v','c'].includes(evt.key)) {
                evt.preventDefault();
                return;
            }

            if (['x','c','v'].includes(evt.key) && (!evt.ctrlKey)) {
                evt.preventDefault();
                return;
            }
        })
    },

    is_admin: () => {
        return Graphite.user_level >= 5;
    },

    capitalize: (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },

    remove_all_events: (el) => {
        let new_node = el.cloneNode(true);

        el.parentNode.replaceChild(new_node, el);
        return new_node;
    },

    get_date_time: () => {
        const dt = new Date();
        return dt.getFullYear() + '-' + dt.getMonth().toString().padStart(2,'0') + '-' + dt.getDate().toString().padStart('2','0') + ' ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
    },

    get_main_contact_number: () => {
        let main_contact_number = '';

        Graphite.contact.contact_numbers.every(item => {
            if (item.text) {
                main_contact_number = item.text;
                return false;
            }

            return true;
        })

        return main_contact_number;
    },

    get_browser: () => {
        let user_agent = navigator.userAgent;

        if (user_agent.indexOf('Chrome') > -1) return 'chrome';
        if (user_agent.indexOf('Firefox') > -1) return 'firefox';
        if (user_agent.indexOf('Safari') > -1) return 'safari';
        if (user_agent.indexOf('Edg') > -1) return 'edge';
        if (user_agent.indexOf('OP') >  -1) return 'opera';

        return ''
    },

    get_time: () => {
        return (new Date()).getTime();
    },

    has_vertical_scrollbar: (el) => {
        return el.scrollHeight > el.clientHeight;    
    },

    wait_for: (key, callback) => {
        let node;

        try {
            node = eval(key);
        } catch(error) {

        }        

        if (typeof(node) != 'undefined') {
            callback();
        } else {
             setTimeout(() => {
                 Graphite.wait_for(key, callback);
            }, 300);
        }
    },

    get_element_height: (el) => {
        let rect = el.getBoundingClientRect();
        return rect.height;
    }
}

export default Graphite;
