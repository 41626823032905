import ModalDialog from "../../modal-dialog/index.js";

class WidgetDialog {
    constructor(filename, options = {}) {
        const _default = {
        }

        this.options = Object.assign(_default, options);

        let dialog_width = 1120;

        if (window.innerWidth <= 1120) {
            dialog_width = 980;
        }

        this.dialog = new ModalDialog({
            id: 'widget-dialog',
            width: dialog_width,
            padding: '2em'
        })

        this.dialog.popup.addEventListener('click', evt => {
            if (evt.target.matches('[data-widget]')) {
                this.dialog.set_cmd('ok');
                
                this.dialog.set_value({
                    "widget": evt.target.getAttribute('data-widget'),
                    "preset": $.default(evt.target.getAttribute('data-preset'), ''),
                    "options": $.default(evt.target.getAttribute('data-options'), '')
                })

                this.dialog.close();
            } else if (evt.target.matches('.ux-menu > a')) {
                evt.preventDefault();

                let tag = evt.target.getAttribute('data-tag');

                this.dialog.popup.querySelectorAll('.ux-menu > a').forEach(el => {
                    el.classList.remove('active');
                })

                evt.target.classList.add('active');

                this.dialog.popup.querySelectorAll('.ux-widgets > div').forEach(el => {
                    let tags = $.default(el.getAttribute('data-tags'), '');

                    if ((tag == 'all') || (tags.includes(tag))) {
                        el.classList.remove('ux-hidden');
                    } else {
                        el.classList.add('ux-hidden');
                    }

                    this.dialog.resize();
                })
            }
        })

        ModalDialog.get_json(Graphite.config.base_url + 'dialogs/data/' + filename + '?v=' + Graphite.config.version).then(node => {
            let menu = [];
            let widgets = [];

            node.menu.forEach(item => {
                menu.push('<a href="#" data-tag="' + $.default(item.tag, '') + '" class="' + ($.default(item.active, '')=='true'?'active':'') + '">' + item.name + '</a>');
            })

            node.widgets.forEach(widget => {
                let active = 'true';
                let required = $.default(widget.required, '').split(',');
                let valid = true;

                if (required.length > 0) {
                    required.every(permission => {
                        let v_permission = permission.trim();

                        if (v_permission) {
                            if (!Graphite.check_permission(v_permission)) {
                                valid = false;
                                return false;
                            }    
                        }

                        return true;
                    })

                    if (!valid) {
                        active = 'false';
                    }
                }

                widgets.push('<div data-active="' + active + '" data-widget="' + widget.widget +'" data-preset="' + $.default(widget.preset, '') + '" data-options="' + $.default(widget.options, '') + '" data-tags="' + widget.tags + '"><div data-role="placeholder"><img class="lazy" src="images/blank.png" data-src="' + $.default(widget.image, '') + '" /></div><p class="ux-title">' + $.default(widget.title, '') + '</p></div>');
            })

            this.dialog.set('<div class="ux-menu">' + menu.join('') + '</div><div class="ux-widgets">' + widgets.join('') + '</div><footer><a href="#" role="button" data-cmd="close"><i class="icon-close"></i> CLOSE</footer>');        
            this.dialog.popup.querySelector('.ux-menu > a.active').click();

            this.dialog.popup.querySelectorAll('img.lazy').forEach(img => {
                Graphite.lazy_load(img);
            })
        })
    }

    select_widget = () => {
        return this.dialog.open();
    }
}

export default WidgetDialog;