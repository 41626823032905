import ModalDialog from "../../modal-dialog/index.js";

let phone_dialog = (url) => {
    const dialog = new ModalDialog({
        id: 'phone-dialog',
        width: 440,
        padding: '2em',
        transition_duration: 200,
        delay_before_show: 0
    })

    const sb = [];

    let tel = '080-555-6666';
    let matches = url.match(/tel:(.*)/);

    if (matches) {
        tel = matches[1];
    }

    sb.push('<div class="flex-row gap-16px mt-20px ai-start">');
    sb.push('<div class="ux-image-container" data-role="placeholder"><img width="80" heihgt="80" src="' + Graphite.config.base_url + 'qr?url=' + encodeURIComponent(url) + '" class="mw-80px rounded-4px"/></div>');
    sb.push('<p class="flex-1">คุณสามารถสแกน QR Code ในภาพ แล้วกดโทรออก หรือ โทรหาเราได้ที่หมายเลข ' + tel + '</p>');
    sb.push('</div>');
    sb.push('<footer class="flex-row center mt-30px">');
    sb.push('<a draggable="false" href="#" role="button" data-cmd="close"><i class="icon-close"></i> Close</a>');
    sb.push('</footer>');

    dialog.set(sb.join(''));

    Graphite.preload_images(dialog.popup).then(() => {
        dialog.open();        
    })
} 

export default phone_dialog;