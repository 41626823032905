import $ from '../../doriyaki/index.js';
import _ from './helper.js';
// import buttons from './buttons.js';

class HTMLEditor {
    constructor(el, _options = {}) {
        const _default = {
            height: 380,
            buttons: ['bold','italic','strikethrough','unordered-list','ordered-list','divider','color','hilight','icon','link','remove-styles']
        }

        this.ctrl = false;
        this.options = Object.assign(_default, _options);
        this.editor = el;
        this.editor.style.setProperty('--height', this.options.height + 'px');
        
        let html = this.editor.innerHTML;

        this.editor.innerHTML = '';
        this.toolbar = $('<div class="ux-toolbar"></div>').get(0);

        this.options.buttons = this.options.buttons.map(name => _.find_button(name));

        this.options.buttons.forEach(btn => {
            let button = $('<a href="#" role="action-button" data-name="' + btn.name + '" title="' + btn.tooltip  + '">' + btn.icon + '</a>').get(0);
            button.get_state = btn.get_state;
            
            button.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                btn.action(this);
                this.update_state();
            })

            this.toolbar.append(button);
        })

        this.editor.append(this.toolbar);
        this.content = $('<div class="ux-editor" contenteditable="true" spellcheck="false"></div>').get(0);
        this.content.innerHTML = html;
        this.editor.append(this.content);

        this.content.addEventListener('focus', event => {
            this.update_state();
        })

        this.content.addEventListener('blur', event => {
            this.update_state();
        })

        this.editor.addEventListener('keydown', event => {
            if (event.key === 'Control') {
                this.ctrl = true;
            } else if ((event.key === 'b') && (this.ctrl === true)) {
                event.preventDefault();
                _.exec_command('bold');
            } else if ((event.key === 'i') && (this.ctrl === true)) {
                event.preventDefault();
                _.exec_command('italic');
            }
        })

        this.editor.addEventListener('keyup', event => {
            if (['ArrowRight','ArrowLeft','ArrowUp','ArrowDown','Enter'].includes(event.key)) {
                this.update_state();
            } else if (event.key === 'Control') {
                this.ctrl = false;
            } 

            let r = _.get_range();

            if (!r) return;

            if (r.startContainer.nodeName === 'LI') {
                if (r.startContainer.textContent.trim() === '') {
                    if (r.startContainer.previousSibling) {
                        if (r.startContainer.previousSibling.nodeName === 'LI') {
                            if (r.startContainer.previousSibling.textContent.trim() === '') {
                                r.startContainer.classList.add('x-removed');
                                r.startContainer.previousSibling.classList.add('x-removed');

                                let p = document.createElement('p');
                                let ul = _.find_parent(r.startContainer, ':is(ul,ol)');

                                ul.querySelectorAll('.x-removed').forEach(el => {
                                    el.remove();
                                })

                                ul.after(p);
                                _.create_selection(p);
                            }
                        }
                    }
                }

                return;
            } else {
                if (r.startContainer.nodeName !== '#text') return;

                if (['P','DIV'].includes(r.startContainer.parentNode.nodeName)) {
                    if (r.startContainer !== r.endContainer) return;
                    if (r.startOffset < 2) return;
                    if (r.startOffset !== r.endOffset) return;
                } 
            }

            let preg;

            html = this.content.innerHTML;
            preg = /\*\*(.*)\*\*/g;

            if (html.match(preg)) {
                let result = html.replace(preg, '<b>$1</b> <span id="target"></span>');
                let scroll_y = this.content.scrollTop;

                this.content.innerHTML = result;

                let el = this.content.querySelector('span#target');

                if (el) {
                    let new_node = $('&nbsp;').get(0);
                    
                    el.after(new_node);                    
                    _.create_selection(new_node);
                    el.remove();
                
                    this.content.scrollTo(0, scroll_y);
                }

                return;
            }

            preg = /==(.*)==/g;

            if (html.match(preg)) {
                let result = html.replace(preg, '<em>$1</em> <span id="target"></span>');
                let scroll_y = this.content.scrollTop;

                this.content.innerHTML = result;

                let el = this.content.querySelector('span#target');

                if (el) {
                    let new_node = $('&nbsp;').get(0);
                    
                    el.after(new_node);                    
                    _.create_selection(new_node);
                    el.remove();
                
                    this.content.scrollTo(0, scroll_y);
                }

                return;
            }

            preg = /(<p>|<div>)---/g;

            if (html.match(preg)) {
                let result = html.replace(preg, '<hr /><p><span id="target"></span></p>');
                let scroll_y = this.content.scrollTop;

                this.content.innerHTML = result;

                let el = this.content.querySelector('span#target');

                if (el) {
                    let new_node = $('&nbsp;').get(0);
                    
                    el.after(new_node);                    
                    _.create_selection(new_node);
                    el.remove();
                
                    this.content.scrollTo(0, scroll_y);
                }

                return;
            }

            preg = /(<p>|<div>)-\s+/g;

            if (html.match(preg)) {
                let result = html.replace(preg, '$1<ul><li><span id="target"></span></li></ul><p><br/></p>');

                this.content.innerHTML = result;

                let el = this.content.querySelector('span#target');

                if (el) {
                    let new_node = $('<br />').get(0);
                    
                    el.after(new_node);                    
                    _.create_selection_ex(new_node);
                    el.remove();
                }

                return;
            }

            preg = /(<p>|<div>)\d+\.\s+/g;

            if (html.match(preg)) {
                let result = html.replace(preg, '$1<ol><li><span id="target"></span></li></ol><p><br/></p>');
                let scroll_y = this.content.scrollTop;

                this.content.innerHTML = result;

                let el = this.content.querySelector('span#target');

                if (el) {
                    let new_node = $('&nbsp;').get(0);
                    
                    el.after(new_node);                    
                    _.create_selection(new_node);
                    el.remove();
                
                    this.content.scrollTo(0, scroll_y);
                }

                return;
            }
        })

        this.editor.addEventListener('mousedown', event => {
            this.update_state();           
        })

        this.update_state();

        return this;
    }

    update_state = () => {
        setTimeout(() => {
            const is_focused = document.activeElement == this.content;

            this.toolbar.querySelectorAll('a[role="action-button"]').forEach(btn => {
                if (is_focused) {
                    btn.get_state().then(state => {
                        btn.setAttribute('class', state);
                    })
                } else {
                    btn.setAttribute('class', 'disabled');                
                }
            })            
        }, 100)
    }

    get_html() {
        return this.editor.innerHTML;
    }
}

export { HTMLEditor, _ }